import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react';
import { SingleTextInput, UrlLink, checkValidation　} from '../atoms/TextInput';
import { BasicButton, WhiteButton} from '../atoms/Button';
import { SelectInput } from '../atoms/SelectInput';
import { couponPeriodList } from '../../static/data';
import { periodTargets }  from '../../static/checkData';
import { SimpleCheckInput } from '../atoms/CheckInput';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import { useHistory } from 'react-router-dom';
import { firestoreProviders } from '../../firebase/firestore/firestoreProviders';
import { useSelector } from 'react-redux';
import { liffUrl } from '../../config';
import { QRcodeModule } from '../atoms/QRcodeModule';
import functionsProviders from '../../firebase/functions/functionsProviders';
import CouponDialog from '../molecules/CouponDialog';
import { DELETE_COUPON_MESSAGE, EDIT_COUPON_MESSAGE, COUPON_VALIDATION_MESSAGE } from '../../static/messages';

const CouponDetail = () => {
  const history = useHistory();
  const couponId = history.location.pathname.replace('/coupon-detail/','');
  const providerData = useSelector(state => state.providerData);
  const providerId = providerData ? providerData.uid : '';
  const [couponName, setCouponName] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [discountRate, setDiscountRate] = useState(0);
  const [couponPeriod, setCouponPeriod] = useState('');
  const [discountTarget, setDiscountTarget] = useState(periodTargets);
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    sunccess: true,
  });
  const [actionType, setActionType] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getCouponData = async() => {
    const response = await firestoreProviders.getCouponData(providerId, couponId).catch((err) => {
        console.log(err);
        history.push('/error')
      });
      if(response){
        setCouponName(response?.couponName);
        setDiscountRate(response?.discountRate);
        setCouponPeriod(response?.period);
        handleCheck(response?.discountTarget);
      }
    }
    if(couponId && providerId){
      getCouponData();
    }
  }, [providerId]);


  const handleCheck = (target) =>{
   if(!target) return;
  let newValue = discountTarget.map((doc) =>doc.name === 'singleItem' ? {
    ...doc,
    checked: target.singleItem
  } : doc.name === 'subscription' ? {
    ...doc,
    checked:target.subscription
  } : doc.name === 'eventItem' ? {
    ...doc,
    checked: target.eventItem
  }: doc);
  setDiscountTarget(newValue);
  }

  const onhandleCheck = (e) =>{
    let newValue = discountTarget.map((doc) =>doc.name === e.target.name ? {
      ...doc,
      checked: !doc.checked
    } : doc);
    setDiscountTarget(newValue)
    }

  const moveToCouponList = useCallback(()=>{
    history.push('/coupon-list');
  }, []);

  const onClipBoardCopy = async () => {
    navigator.clipboard.writeText(`${liffUrl}/register-coupon/?couponId=${couponId}`);
    setSnackState({
      open: true,
      message: `クーポンのリンクをコピーしました`,
      success: true,
    });
    return;
  }

  const checkValidations = () => {
    if (!couponName) {
      return COUPON_VALIDATION_MESSAGE.NO_COUPON_NAME;
    }
    if (!discountRate) {
      return COUPON_VALIDATION_MESSAGE.NO_DISCOUNT_RATE;
    } else {
      if (checkValidation('percent', discountRate)) {
        return COUPON_VALIDATION_MESSAGE.NOT_APPROPRIATE_DISCOUNT_RATE_VALUE;
      }
    }
    if (!couponPeriod) {
      return COUPON_VALIDATION_MESSAGE.NO_PERIOD;
    }
     if(!discountTarget.find((doc)=>doc.checked === true)){
        return COUPON_VALIDATION_MESSAGE.NO_TARGET
    }
    return null;
  }

  const onChangeCoupon = async() => {
    let response;
    if(actionType === 'change'){
      const validationRes = checkValidations();
      if (validationRes) {
        setSnackState({
          open: true,
          message: validationRes,
          success: false,
        });
        return;
      }
    setDisabled(true);
    response = await functionsProviders.updateCoupon({
      couponId: couponId,
      couponName: couponName,
      period: couponPeriod,
      discountRate: discountRate,
      subscription: discountTarget.find((doc) => doc.name === 'subscription').checked,
      singleItem: discountTarget.find((doc) => doc.name === 'singleItem').checked,
      eventItem: discountTarget.find((doc) => doc.name === 'eventItem').checked,
    }).catch((err) => {
      setSnackState({
        open: true,
        message: EDIT_COUPON_MESSAGE.FAILED,
        success: false
      });
      setDisabled(false);
    });
   } else if(actionType === 'delete'){
    setDisabled(true);
     response = await functionsProviders.inActiveCoupon({
       couponId:couponId
      }).catch((err) => {
      setSnackState({
        open: true,
        message: DELETE_COUPON_MESSAGE.FAILED,
        success: false
      });
      setDisabled(false);
    });
   }
   if(!response){
    setSnackState({
      open: true,
      message: `クーポンの${actionType === 'delete' ? '削除' : '変更'}に失敗しました`,
      success: false
    });
    setDisabled(false);
   } else if(response.data){
    setSnackState({
      open: true,
      message: `クーポンの${actionType === 'delete' ? '削除' : '変更'}が成功しました`,
      success: true
    });
    setTimeout(() => {
      history.push('/coupon-list')
    }, 2000);
   }
  }
  const onClickChange =()=>{
    setActionType('change');
    setOpen(true);
  };
  const onClickDelete =()=>{
    setActionType('delete');
    setOpen(true);
  };
  const onChangeCouponName = (data) => {
    if (String(data).length < 21) {
      setCouponName(data);
    } else {
      setSnackState({
        open: true,
        message: `${20}文字以内にしてください`,
        success: false,
      });
    }
  }
  return(
    <>
     <SingleTextInput
        title={"クーポン名(20文字以内)"}
        value={couponName}
        name={'nickName'}
        validation={true}
        multiline={false}
        setFunction={onChangeCouponName}
        required={true}
        placeholder={'オフライン生徒向けクーポン'}
        propsHelperMessage={'使用できない特殊文字を含んでいます'}
      />
     <SingleTextInput
        title={"割引率（%)"}
        value={discountRate}
        name={'percent'}
        validation={true}
        multiline={false}
        setFunction={setDiscountRate}
        required={true}
        placeholder={'30'}
        propsHelperMessage={'1から100の値を入力してください'}
      />
      <SelectInput
        label="クーポンの期間を設定してください"
        title="クーポン適用期間"
        required={true}
        items={couponPeriodList}
        value={couponPeriod}
        onChange={(e) => {
          setCouponPeriod(e.target.value)
        }}
        helperText={''}
      />
      <SimpleCheckInput
          title="クーポン適用範囲"
          required={true}
          items={discountTarget}
          onChange={(index) => onhandleCheck(index)}
          helperText={'「月額会員」は会費へ割引を適用します。「単品購入」はイベントやバックナンバーなどの単品商品へ割引を適用します'}
        />
       <UrlLink　
        title={"クーポンリンク"}
        value={`${liffUrl}/register-coupon/?couponId=${couponId}`}
        validation={true}
        disabled={true}
        multiline={false}
        setFunction={setDiscountRate}
        onClick={onClipBoardCopy}
        placeholder={'30'}
        propsHelperMessage={'1から100の値を入力してください'}
      />
      <QRcodeModule url={`${liffUrl}/register-coupon/?couponId=${couponId}`} title={'QRコード'}/>
      <BasicButton
        onClick={()=>onClickChange()}
      >
        {'クーポン内容の変更'}
      </BasicButton>
      <BasicButton
        onClick={moveToCouponList}
      >
        {'トップに戻る'}
      </BasicButton>
      <div>
      <WhiteButton
        disabled={disabled}
        onClick={()=>onClickDelete()}
      >
        {'クーポンを削除する'}
      </WhiteButton>
      </div>
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
      <CouponDialog open={open} setOpen={setOpen} actionType={actionType}  onClick={()=>onChangeCoupon()} disabled={disabled}/>
    </>
  )
}

export default CouponDetail;