import { auth } from '../index.js';

export const firebaseAuthLogout = async () => {
  auth
    .signOut()
    .then(function () {
      console.log('logout');
    })
    .catch(function (error) {
      throw error;
    });
};
