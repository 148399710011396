const isNumber = (str) => {
  str = str == null ? '' : str;
  if (str.match(/^[0-9ー　]+$/)) {
    //"ー"の後ろの文字は全角スペースです。
    return true;
  } else {
    return false;
  }
};
export default isNumber;
