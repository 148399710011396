import styled from "styled-components";
import React from 'react';
import Typography from '@material-ui/core/Typography';

export const Img = styled.img`
    display:block;
    margin:${props => props.theme.spacing(4)}px;
    max-width:80%;
    height:auto;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        max-width:327px;
    }
`;

export const StyledImage  = (props) => {
  const title = props.title;
  const required = props.required ? props.required : false;
  const src = props.src
  return (
    <Div>
      {title ?
        <Title data-required={required} variant="body1" gutterBottom>
          {title}
        </Title> :
        null}
      {src && <Img src={src}/>}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
  }
`;
const HelperWrap = styled.div`
`;

const Title = styled(Typography)`
  margin: ${(props) => props.theme.spacing(3)}px
    ${(props) => props.theme.spacing(2)}px 0;
  font-weight: 700;
  width: calc(240px - ${(props) => props.theme.spacing(2)}px * 3);
  &[data-required='true'] {
    &::after {
      content: '*';
      margin-left: ${(props) => props.theme.spacing(2)}px;
      font-size: 14px;
      color: red;
    }
  }
`;