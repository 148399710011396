import React from "react";
import styled from "styled-components";
import ContentCard from "../molecules/ContentCard";
import AddContentCard from "../molecules/AddContentCard";
import { StyledFlexWrap } from "../atoms/Paper";

const list = [1, 2, 3, 4, 5, 6, 7, 8];
const SeminorList = (props) => {
  const { singleItems } = props;
  const sortedSingleItems = singleItems
    ? singleItems.sort((a, b) => {
        const aUpdateAt = a.data.creatAt ? a.data.creatAt.seconds : null;
        const bUpdateAt = b.data.creatAt ? b.data.creatAt.seconds : null;
        if (aUpdateAt > bUpdateAt) return -1;
        else return 1;
      })
    : singleItems;
  return (
    <>
      <CustomizeFlexWrap>
        {!singleItems ? (
          <>
            {list.map((doc, index) => (
              <ContentCard
                index={index}
                key={index}
                contentData={null}
                type={"video"}
              />
            ))}
          </>
        ) : (
          <>
            <AddContentCard type={"content"} tab={"イベント"} />
            {sortedSingleItems.length > 0 &&
              sortedSingleItems.map((doc, index) => (
                <ContentCard index={index} key={index} contentData={doc} />
              ))}
          </>
        )}
      </CustomizeFlexWrap>
    </>
  );
};

export default SeminorList;

const CustomizeFlexWrap = styled(StyledFlexWrap)`
  min-height: 60vh;
`;
