// 登録可能な金融機関一覧
export const bankList = [
    {
      label: 'みずほ銀行',
      value: '0001',
    },
    {
      label: '三菱UFJ銀行',
      value: '0005',
    },
    {
      label: '三井住友銀行',
      value: '0009',
    },
    {
      label: 'りそな銀行',
      value: '0010',
    },
    {
      label: '埼玉りそな銀行',
      value: '0017',
    },
    {
      label: 'ジャパンネット銀行',
      value: '0033',
    },
    {
      label: 'セブン銀行',
      value: '0034',
    },
    {
      label: 'ソニー銀行',
      value: '0035',
    },
    {
      label: '楽天銀行',
      value: '0036',
    },
    {
      label: '住信ＳＢＩネット銀行',
      value: '0038',
    },
    {
      label: 'じぶん銀行',
      value: '0039',
    },
    {
      label: 'イオン銀行',
      value: '0040',
    },
    {
      label: 'ゆうちょ',
      value: '9900',
    },
  ];

export const identificationList = [
    {
      label: '運転免許証(表裏両面)',
      value: '1',
    },
    {
      label: 'パスポート',
      value: '2',
    },
    {
      label: '住基カード',
      value: '3',
    },
  ];

/**
 * stripe 用性別
 */


export const genderList = [
  {
    label: '男性',
    value: 'male',
  },
  {
    label: '女性',
    value: 'female',
  },
];

export const areaList = [
  '恵比寿',
  '六本木',
  '西麻布',
  '麻布十番',
  '渋谷',
  '赤坂',
  '銀座',
  '中目黒',
  '池袋',
  '新宿',
  '五反田',
  '表参道',
  '日本橋',
  '東京駅',
  '新橋',
  '品川',
  '三軒茶屋',
  '白金',
  'その他',
];

export const castTypeList = [
  '細身',
  '筋肉質',
  '歌うま',
  'ぽっちゃり',
  '妻子持ち',
  '盛り上げ上手',
  '運動神経抜群',
  '方言',
  'オラオラ',
  '服好き',
  '高学歴',
  '映画好き',
  '音楽好き',
  'アニメ好き',
  '飲める人',
  '聞き上手',
  '車持ち',
  'マラデカ',
  'ビジュアル系',
  'ジャニーズ系',
  'EXILE系'
];

export const sexList = [
  "男性",
  "女性",
  "その他",
  "答えたくない",
];

/**
 * stripe account verified status
 */
export const accountStatus = [
  { status: 'loading', text: '' },
  { status: 'unverified', text: '本人確認を行ってください' },
  { status: 'pending', text: '本人確認実施中' },
  { status: 'verified', text: '本人確認完了済み' },
]

export const bankStatusArray = [
  { status: 'verified', text: '口座登録済み' },
  { status: '', text: '口座を登録してください' },
]

export const registerCardStatusArray = [
  { status: 'verified', text: '決済方法登録済み' },
  { status: '', text: '決済方法を登録してください' },
]

export const subscribeDate = [
  {label: '7日', value: 7},
  {label: '14日', value:14},
  {label: '30日', value:30},
  {label: '90日', value:90},
  // {label: '0日(即時バックナンバー化)', value: 0},
 // {label: '無制限', value: 999999},
]

export const limitCommentCharacters = 50;

export const contentStausToLabel = [
  {value: 'uploaded', label:'アップロード中'},
  {value: 'transCoding', label:'映像処理中'},
  {value: 'completed', label:'公開準備完了'},
  {value: 'unpublished', label:'非公開'},
  {value: 'published', label:'公開中'},
  {value: 'failed', label:'処理失敗'},
]

export const deliveryType = [
  {value: 'mail', label:'メール'},
  {value: 'line', label:'LINE'},
]


export const couponPeriodList = [
  {
    label: '1ヶ月',
    value: 1,
  },
  {
    label: '3ヶ月',
    value: 3,
  },
  {
    label: '6ヶ月',
    value: 6,
  },
  {
    label: '1年',
    value: 12,
  },
  {
    label: '無期限',
    value: 9999,
  },
];


export const privacyPolicyUrl = 'https://cookon-lp.web.app/privacy';
export const termsUrl = 'https://cookon-lp.web.app/terms';
export const manualUrl = 'https://blog.cookon.app/';


export const couponStatus = [
  {status: 'active', label:'使用中'},
  {status: 'inActive', label:'使用済'},
  {status: 'expired', label:'期限切れ'}
]

export const  commentStatus  = [
  {id: 1, value: 'all', label: '全体公開'},
  {id: 2, value: 'purchaser', label: '購入者限定公開'},
  {id: 3, value: null, label: '非公開'},
]

export const contentCategories = {
  free: '無料',
  backNumber: 'バックナンバー',
  membersOnly:  '会員限定',
  limitedTime: '期間限定',
  eventItem: '単品'
}