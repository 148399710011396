import React from 'react';
import styled from 'styled-components';
import { SubmitButton } from '../atoms/Button'
import Typography from '@material-ui/core/Typography';
import { SmallChip } from '../atoms/Chip';

export const CardForm = (props) => {
  const stripeInfo = props.stripeInfo;
  const brand = stripeInfo ? stripeInfo.brand ? stripeInfo.brand : null : null;
  const cardLast4 = stripeInfo ? stripeInfo.cardLast4 ? stripeInfo.cardLast4 : null : null;
  return (
    <div>
      <Div>
        <Title data-required={true} variant="body1" gutterBottom>{'カード登録フォーム'}</Title>
        <ElementWrap>
          {props.element}
        </ElementWrap>
      </Div>
      {(brand && cardLast4) && <Div>
        <Title variant="body1" gutterBottom>{'登録されているカード'}</Title>
        <StyledChip color={'secondary'} label={`${brand}  ********${cardLast4}`} />
      </Div>}
      <SubmitButton
        disabled={props.disabled}
        submit={true}
      > {props.disabled ? 'カード登録中...' : '新しいカードを登録する'}
      </SubmitButton>
    </div>
  )
};

const ElementWrap = styled.div`
  margin:${props => props.theme.spacing(2)}px;
  padding: 4vw;
  border-style: none;
  background-color: white ;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
  inset 0 1px 0 rgba(0, 0, 0, 0.08) ;
  border-radius: 4px;
  width:calc(100% - ${props => props.theme.spacing(2)}px * 2);
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
   width: 375px;
   padding: 25px;
    }
`
const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    }
`

const Title = styled(Typography)`
    margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left:${props => props.theme.spacing(2)}px;
          font-size:14px;
          color:red;
        }
    }
`

const StyledChip = styled(SmallChip)`
  font-weight:700;
  margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;

`
