import React from 'react';
import Page from '../templates/Page'

const HistoryPage = () => {
  return (
    <Page title="履歴ページ">
        履歴ページ
    </Page>
  )
}
export default HistoryPage;