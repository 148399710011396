export const publishOption = [
  {checked:true,label:"公開中"},
  {checked:false,label:"非公開"},
]

export const priceOption = [
  {checked:true,label:"無料で公開する"},
  {checked:false,label:"無料で公開しない"},
]

export const backNumberOption = [
  {checked:true,label:"期間限定"},
  {checked:false,label:"無期限"},
]
export const salesOption = [
  {checked:true,label:"会員限定"},
  {checked:false,label:"個別販売"},
]

export const periodTargets = [
  {checked: false, label: "月額会員", name: 'subscription'},
  {checked: false, label: "単品購入", name: 'singleItem' },
  {checked: false, label: "イベント", name: 'eventItem' }
]

export const commentFunctionStatus = [
  {checked:true,label:"はい"},
  {checked:false,label:"いいえ"},
]