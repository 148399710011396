import firebase from 'firebase';
import { storage } from '../index';

const uploadFileShowProgress = async (uid, e, path, setProgress, setVideoUrl, currentPlanCapa, currentUsage) => {
	let storageRef = storage.ref();
  const file = e.target.files[0];
  let uploadTask = storageRef.child(`images/${uid}/${path}/${file.name.replace('_','')}`).put(file);
	// Listen for state changes, errors, and completion of the upload.
	uploadTask.on(
		firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
		function(snapshot) {
      var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
      setProgress(progress)
			switch (snapshot.state) {
				case firebase.storage.TaskState.PAUSED: // or 'paused'
					break;
				case firebase.storage.TaskState.RUNNING: // or 'running'
					break;
			}
		},
		function(error) {
			switch (error.code) {
				case 'storage/unauthorized':
					// User doesn't have permission to access the object
					break;
				case 'storage/canceled':
					// User canceled the upload
					break;
				case 'storage/unknown':
					// Unknown error occurred, inspect error.serverResponse
					break;
			}
		},
		async function() {
      const videoUrl = await	uploadTask.snapshot.ref.getDownloadURL();
      setVideoUrl(videoUrl);
		}
	);
};

export default uploadFileShowProgress;
