import { functions } from '../index';
const functionsCommon = {
  updateProfile: async(userType, data) => {
   return await functions.httpsCallable('onCall-updateProfile')({
      userType: userType,
      data: data
    }).catch((err) => {
      console.log('err functionsCommon update')
      throw err;
    });
  },
  getAddressFromPostalCodeAPI: async (postalCode) => {
		try {
			const getAddressFromPostalCode = functions.httpsCallable('onCall-getAddressFromPostalCode');
			const result = await getAddressFromPostalCode(postalCode);
			return result.data;
		} catch (err) {
			throw err;
		}
  },
};

export default functionsCommon;
