import React from 'react'
import { SmallProviderCard} from './ProviderCard';
const ProviderItem = () => {
  return (
    <>
      <SmallProviderCard
        avatar={null}
        title={'プロバイダー名'}
        subTitle1={'https://xxx.com'}
        subTitle2={'東京都港区'}
      />
    </>
  )
}

export default ProviderItem;
