import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import moment from 'moment-timezone';
import { firestoreConsumers } from '../../firebase/firestore/firestoreConsumers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { StyledFullPaper } from '../atoms/Paper';
import { SelectSmallInput } from '../atoms/SelectInput';
import { FlexWrap } from '../atoms/Paper';
import { Modal } from "../molecules/Modal";
import { HeadlessTable } from "../atoms/Table";


let initMonthList = [
  { label: '全て', value: '全て' },
];

const PurchaserManagement = (props) => {
  const rowPerPage = 20;
  const { orderList } = props;
  const [orders, setOrders] = useState(null);
  const [targetList, setTargetList] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [monthList, setMonthList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(initMonthList[0].value);
  const [open, setOpen] = useState(false);
  const [viewData, setViewData] = useState([]);
  const contract = useSelector(state => state.contract);
  const plan = contract ? contract.plan : null;

  const makeMonthList = async (orderList) => {
    const tmp_monthList = [];
    let matchFlag = false;
    for (let i = 0; i < orderList.length; i++) {
      matchFlag = false;
      const targetOrderMonth = moment(orderList[i].data.createAt.seconds * 1000).format('YYYY/MM');
      if (i === 0) {
        tmp_monthList.push({ label: targetOrderMonth, value: targetOrderMonth });
        continue;
      }
      for (let i = 0; i < tmp_monthList.length; i++) {
        if (tmp_monthList[i].label === targetOrderMonth) {
          matchFlag = true;
          break;
        }
      }
      if (!matchFlag) {
        // 新しい月を追加
        tmp_monthList.push({ label: targetOrderMonth, value: targetOrderMonth });
      }
    }
    // 最新月で並び替え
    tmp_monthList.sort(function(a, b) {
      if (b.value > a.value) {
        return 1;
      } else {
        return -1;
      }
    })
    // 既存配列と結合し、order内に存在する月（YYYY/MM）のlistを生成
    setMonthList(initMonthList.concat(tmp_monthList));
  }

  useEffect(() => {
    if (orderList) {
      makeMonthList(orderList);
    }
  }, [])

  useEffect(() => {
    if (orderList) {
      setOrder(orderList)
    }
  }, [orderList, selectedMonth])

  useEffect(() => {
    if (orders) {
      const first = (page - 1) * rowPerPage + 1;
      const last = page * rowPerPage;
      setTargetList(null)
      const list = [];
      orders.map((order, index) => {
        if (!(index < first - 1 || index > last - 1)) {
          list.push(order);
        }
      });
      setTargetList(targetList => list);
    }
  }, [page, orders])

  const setOrder = async (orderList) => {
    const list = [];
    await Promise.all(orderList.map(async (order) => {
      const consumerData = await firestoreConsumers.getData(order.data.consumerRef.id);
      const profile = consumerData ? consumerData.profile : null;
      const nickName = profile ? profile.nickName : null;
      const email = profile ? profile.email : '';
      const contentRef = order.data?.contentRef;
      let contentTitle = null;
      if (contentRef) {
        const content = await contentRef.get();
        contentTitle = content.data()?.title ? content.data()?.title : '';
      }
      if (selectedMonth === '全て') {
        list.push({
          date: moment(order.data.createAt.seconds * 1000).tz("Asia/Tokyo").format('YYYY/MM/DD'),
          product: order.data.orderType === "subscription" ? '月会費' : '個別',
          userName: nickName,
          email: email,
          price: Math.ceil(Number(order.data.amount)).toLocaleString(),
          timestamp: moment(order.data.createAt.seconds * 1000),
          id: order.id,
          contentTitle: contentTitle
        })
      } else if (selectedMonth === moment(order.data.createAt.seconds * 1000).format('YYYY/MM')) {
        list.push({
          date: moment(order.data.createAt.seconds * 1000).tz("Asia/Tokyo").format('YYYY/MM/DD'),
          product: order.data.orderType === "subscription" ? '月会費' : '個別',
          userName: nickName,
          email: email,
          price: Math.ceil(Number(order.data.amount)).toLocaleString(),
          timestamp: moment(order.data.createAt.seconds * 1000),
          id: order.id,
          contentTitle: contentTitle
        })
      }
    }));
    setCount(Math.ceil(list.length > 0 ? list.length / rowPerPage : 0))
    const sortedList = list.length > 0 ? list.sort((a, b) => {
      return a.timestamp > b.timestamp ? -1 : 1
    }) : list;
    setOrders(sortedList)
  }
  const handleChange = (event, value) => {
    setPage(value);
  };

  const onOpenModal = useCallback(
    (row) => {
      setViewData([
        { title: "日付", value: row.date },
        { title: "名前", value: row.userName },
        { title: "連絡先", value: row.email },
        { title: "商品", value: row.product },
        { title: "商品名", value: row.contentTitle ? row.contentTitle : "--" },
        { title: "価格", value: `${row.price}円` },
      ]);
      setOpen(true);
    },
    [open]
  );

  const onCloseModal = useCallback(() => {
    setViewData([
     　 { title: "日付", value: '' },
        { title: "名前", value: '' },
        { title: "連絡先", value: '' },
        { title: "商品", value: '' },
        { title: "商品名", value: '' },
        { title: "価格", value: '' },
    ]);
    setOpen(false);
  }, [open]);

  return (
    <>
      <CustomizeFullPaper>
        <CustomSelectSmallInput>
          <FlexWrap>
            <SmallTitle>{'表示時期（月別）'}</SmallTitle>
            <SelectSmallInput
              items={monthList}
              onChange={(e) => { setSelectedMonth(e.target.value) }}
              value={selectedMonth}
            />
          </FlexWrap>
        </CustomSelectSmallInput>
        {targetList ? targetList.length > 0 ?
          <StyledContainer component={Paper}>
            <StyledTable aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell component="th" scope="row">{"日付"}</StyledTableHeadCell>
                  <StyledTableHeadCell align="left"  >{"名前"}</StyledTableHeadCell>
                  <StyledTableHeadCell align="left">{"連絡先"}</StyledTableHeadCell>
                  <StyledTableHeadCell component="th" scope="row">{"商品"}</StyledTableHeadCell>
                  <StyledTableHeadCell component="th" scope="row">{"商品名"}</StyledTableHeadCell>
                  <StyledTableHeadCell align="left">{"価格"}</StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {targetList.map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    style={{ cursor: "pointer" }}
                    onClick={() => onOpenModal(row)}
                    >
                    <StyledTableDataCell style={{overflow: 'hidden'}} component="th" scope="row" align="left">{row.date}</StyledTableDataCell>
                    <StyledTableDataCell style={{overflow: 'hidden'}} align="left" >{row.userName}</StyledTableDataCell>
                    <StyledTableDataCell style={{overflow: 'hidden'}} align="left" >{row.email}</StyledTableDataCell>
                    <StyledTableDataCell style={{overflow: 'hidden'}} align="left" >{row.product}</StyledTableDataCell>
                    <StyledTableDataCell style={{overflow: 'hidden'}} align="left" >{row.contentTitle ? row.contentTitle : "--"}</StyledTableDataCell>
                    <StyledTableDataCell style={{overflow: 'hidden'}} align="left" >{row.price}円</StyledTableDataCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
            <StyledTableFooter>
              <StyledPagination count={count} page={page} onChange={handleChange} size="small" />
            </StyledTableFooter>
          </StyledContainer> : <ProgressWrap>購入履歴はありません</ProgressWrap> : <ProgressWrap ><CircularProgress /></ProgressWrap>}
      </CustomizeFullPaper>
      {open ? (
        <Modal title={"購入履歴詳細"} onClose={onCloseModal}>
          <HeadlessTable rows={viewData} />
        </Modal>
      ) : null}
    </>
  )
}

export default PurchaserManagement;

const StyledContainer = styled(TableContainer)`
 border: 1px solid #ECECEC;
 box-sizing: border-box;
 border-radius: 8px;
 box-shadow: none;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
 border:none;
 box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
`;

const StyledTable = styled(Table)`
  width:  calc( 100% - ${props => props.theme.spacing(4)}px * 2);
  margin:${props => props.theme.spacing(3)}px auto 0;
  @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
    margin:0 auto;
    width :100%
  }
`

const CustomizeFullPaper = styled(StyledFullPaper)`
text-align: center;
min-height: 80vh;
margin-top:${props => props.theme.spacing(4)}px;
padding: 0;
box-shadow: none;
width: calc( 100% - ${props => props.theme.spacing(2)}px * 2);
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin-top:${props => props.theme.spacing(2)}px;
}
`;

const StyledTableFooter = styled(TableFooter)`
display: flex;
`;

const StyledPagination = styled(Pagination)`
  margin: ${props => props.theme.spacing(2)}px auto;
`
const ProgressWrap = styled.div`
margin:  ${props => props.theme.spacing(4)}px auto;
`;

const StyledTableHeadCell = styled(TableCell).attrs({align: 'left'})`
color: ${props => props.theme.palette.primary.light};
white-space: nowrap;
font-weight: 700;
font-size: 12px;
min-width: 50px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  font-size: 16px;
}
`

const StyledTableDataCell = styled(TableCell)`
font-size: 12px;
width: 200px;
max-width: 200px;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  text-overflow: ellipsis;
  overflow: hidden;
white-space: nowrap;
  font-size: 16px;
  width: 150px;
  max-width: 150px;
}
`

const SmallTitle = styled.div`
//font-family: Noto Sans JP;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 23px;
letter-spacing: -0.333333px;
color: #2B2B2B;
margin: 27px 10px;
vertical-align: top;
`;

const CustomSelectSmallInput = styled.div`
text-align: left;
`;