import React, { useRef } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { checkValidation } from '../molecules/CTextField';
import { KYCInput } from '../molecules/KYCInput';
import { BasicButton } from '../atoms/Button';
import { identificationList } from '../../static/data';
import styled from 'styled-components';
import functionsCommon from '../../firebase/functions/functionsCommon';
import { genderList } from '../../static/data';
import { getImage } from '../../utils/getImage';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import { isZenKatakana } from '../../utils/isZenkakuKana';
import moment from 'moment-timezone';
import functionsProviders from '../../firebase/functions/functionsProviders';
import { useHistory } from 'react-router-dom';
import { SingleTextInput } from '../atoms/TextInput';
import { StyledFullPaper } from '../atoms/Paper';
import { SelectInput } from '../atoms/SelectInput';
import { DatePicker } from '../atoms/DatePicker';
import { RadioInput } from '../atoms/RadioInput';
import { CheckInput } from '../atoms/CheckInput';
import { ErrorAlert } from '../atoms/Alert';
import { termsUrl } from '../../static/data';

const KYCForm = () => {
  const frontPhotoRef = useRef();
  const backPhotoRef = useRef();
  const history = useHistory();
  const [postalCode, setPostalCode] = useState("");
  const [address_1, setAddress_1] = useState("");
  const [address_2, setAddress_2] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [identificationType, setIdentificationType] = useState(identificationList[0].value);
  const [addressKanji, setAddressKanji] = useState({});
  const [addressKana, setAddressKana] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState({
    day: 1,
    month: 1,
    year: 2000
  });
  const [firstNameKanji, setFirstNameKanji] = useState();
  const [firstNameKana, setFirstNameKana] = useState();
  const [lastNameKanji, setLastNameKanji] = useState();
  const [lastNameKana, setLastNameKana] = useState();
  const [gender, setGender] = useState(genderList[0].value)
  const [images, setImages] = useState([]);
  // line1登録用の退避
  const [townKanjiTaihi, setTownKanjiTaihi] = React.useState('');
  const [dobInputValue, setDobInputValue] = React.useState('2000-01-01');
  const [termStatus, setTermStatus] = useState({
    checked: false,
    label: '同意する',
  })
  const [disabled, setDisabled] = useState(false);
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });

  useEffect(() => {
    if (postalCode && (String(postalCode.replace('-')).length === 7 || String(postalCode.replace('-')).length === 8)) {
      getAddressFromPostCode();
    }
  }, [postalCode]);
  const getAddressFromPostCode = useCallback(async () => {
    const response = await functionsCommon.getAddressFromPostalCodeAPI(postalCode).catch((err) => {
      throw err;
    })
    const address = response.results ? response.results[0] : null;
    if (!address) {
      setSnackState({
        open: true,
        message: '該当する郵便番号が見つかりませんでした。再度確認してからご入力ください',
        success: false,
      });
      return;
    }
    const stateKanji = address.address1;
    const cityKanji = address.address2;
    const townKanji = address.address3;
    const stateKana = address.kana1;
    const cityKana = address.kana2;
    const townKana = address.kana3;
    setAddressKanji(
      Object.assign({}, addressKanji, {
        postal_code: postalCode,
        state: stateKanji,
        city: cityKanji,
        town: townKanji,
      }),
    );
    setAddressKana(
      Object.assign({}, addressKana, {
        postal_code: postalCode,
        state: stateKana,
        city: cityKana,
        town: townKana,
      }),
    );
    setTownKanjiTaihi(townKanji);
    setAddress_1(address ? `${address.address1}${address.address2}${address.address3}` : "");
  }, [postalCode])

  const onChangeDob = (data) => {
    setDobInputValue(data);
    const numBlock = moment(data)
      .format('YYYY-MM-DD')
      .match(/([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})/);
    if (numBlock) {
      const year = parseInt(numBlock[1]);
      const month = parseInt(numBlock[2]);
      const day = parseInt(numBlock[3]);
      setDateOfBirth({
        day,
        month,
        year,
      });
    }
  };
  /**
  * 文字列から数字のブロックを抽出してハイフンつなぎに編集する
  */
  const formatAddressKanaLine1 = (str) => {
    const numberList = str.match(/[0-9]{1,}/g);
    return numberList && numberList.length ? numberList.join('-') : '';
  };
  const onChangeAddress2 = (data) => {
    setAddress_2(data)
    setAddressKanji(
      Object.assign({}, addressKanji, {
        // Stripe20190814の仕様変更 漢字のtownに丁目がないとエラーになるため、
        // 漢字のtownにはline1の内容を付け足して登録
        town: `${townKanjiTaihi}${data}`,
        line1: data,
      }),
    );
    setAddressKana(
      Object.assign({}, addressKana, {
        // address_kana[line1]は数字とハイフン(-)のみ許容
        line1: formatAddressKanaLine1(data),
      }),
    );
  }
  const onChangeImage = async (data, type) => {
    const fileList = data;
    if (fileList && /image/.test(fileList[0].type)) {
      if (fileList[0].size > 3000000) {
        setSnackState({
          open: true,
          message: '画像は3MB以下のサイズのものを指定してください。',
          success: false
        });
        return;
      }
      const base64 = await getImage(fileList[0]);
      if (type === 'front') {
        setImages([base64, images[1]]);
      } else if (type === 'back') {
        setImages([images[0], base64]);
      }
    }
  };
  const checkValidations = () => {
    if (!postalCode) {
      return '郵便番号を入力してください';
    } else {
      if (checkValidation('postalCode', postalCode)) {
        return "郵便番号は7桁または8桁の数字で入力してください";
      }
    }
    if (!address_1) {
      return '住所(郵便番号入力で自動入力）が入力されていません';
    }
    if (!address_2 && !addressKanji.line1 && !addressKana.line1) {
      return '住所(番地以降 半角数字ハイフン)を入力してください';
    } else {
      if (checkValidation('address_2', address_2)) {
        return "住所(番地以降 半角数字ハイフン)はを半角英数字とハイフンのみで入力してください";
      }
    }
    if (!lastNameKanji) {
      return '氏名（姓）を入力してください';
    } else {
      if (checkValidation('lastNameKanji', lastNameKanji)) {
        return "氏名（姓）に使用できない特殊文字を含んでいます";
      }
    }
    if (!firstNameKanji) {
      return '氏名（名）を入力してください';
    } else {
      if (checkValidation('firstNameKanji', firstNameKanji)) {
        return "氏名（名）に使用できない特殊文字を含んでいます";
      }
    }
    if (!lastNameKana) {
      return '氏名（セイ）を入力してください';
    } else {
      if (checkValidation('lastNameKana', lastNameKana)) {
        return "氏名（セイ）を全てカタカナで入力してください";
      }
    }
    if (!firstNameKana) {
      return '氏名（メイ）を入力してください';
    } else {
      if (checkValidation('firstNameKana', firstNameKana)) {
        return "氏名（メイ）を全てカタカナで入力してください";
      }
    }
    if (!gender) {
      return '性別を選択してください';
    }
    if (!dobInputValue) {
      return '生年月日を入力してください';
    }
    if (!email) {
      return 'メールアドレスを入力してください';
    } else {
      if (checkValidation('email', email)) {
        return "無効なメールアドレスです。正しい形式で入力してください";
      }
    }
    if (!phoneNumber) {
      return '電話番号を入力してください';
    } else {
      if (checkValidation('phoneNumber', phoneNumber)) {
        return "電話番号は11桁以内の数値で入力してください";
      }
    }
    if (!images[0]) {
      return '身分証をアップロードしてください（表）';
    }
    if (identificationType === identificationList[0].value) {
      if (!images[1]) {
        return '身分証をアップロードしてください（裏）';
      }
    }
    if (!termStatus.checked) {
      return '利用規約に同意してください';
    }
    return null;
  }
  const onConfirm = async () => {
    const validationRes = checkValidations();
    if (validationRes) {
      setSnackState({
        open: true,
        message: validationRes,
        success: false,
      });
      return;
    }
    if (moment().diff(moment(dobInputValue), 'years') < 13) {
      setSnackState({
        open: true,
        message: '13歳未満は登録できません',
        success: false
      });
      return;
    }
    const person = {};
    const numBlock = /0([0-9]{9,})/.exec(phoneNumber.replace('-').slice(0, 11));
    if (addressKanji) person.address_kanji = addressKanji;
    if (addressKana) person.address_kana = addressKana;
    if (dateOfBirth) person.dob = dateOfBirth;
    if (firstNameKanji) person.first_name_kanji = firstNameKanji;
    if (firstNameKana) person.first_name_kana = firstNameKana;
    if (lastNameKanji) person.last_name_kanji = lastNameKanji;
    if (lastNameKana) person.last_name_kana = lastNameKana;
    if (phoneNumber) person.phone = `+81${numBlock[1]}`;
    if (email) person.email = email;
    if (gender) person.gender = gender;
    const data = {
      person,
      images: images.length ? images : undefined,
    };
    setDisabled(true);
    const response = await functionsProviders.confirmIdentify(data).catch((err) => {
      setDisabled(false);
      setSnackState({
        open: true,
        message: '登録に失敗しました。入力内容を見直し再度ご登録ください',
        success: false
      })
      return
    })
    if (!response) {
      setDisabled(false);
      setSnackState({
        open: true,
        message: '登録に失敗しました。入力内容を見直し再度ご登録ください',
        success: false
      })
      return
    } else if (response.success) {
      setDisabled(false);
      setSnackState({
        open: true,
        message: '本人認証登録を完了しました',
        success: true
      });
      setTimeout(() => {
        history.push('/config');
      }, 2000);
    }
  };
  const handleCheck = () => {
    setTermStatus({
      label: '同意する',
      checked: !termStatus.checked,
    })
  }
  const handleFrontPhotoClick = () => frontPhotoRef.current.click();
  const handleBackPhotoClick = () => backPhotoRef.current.click();
  return (
    <StyledFullPaper>
      <ErrorAlert>{'本人確認申請から審査完了まで1~5営業日ほどかかります。本サービスの決済は、Stripe社の決済サービスを使用しているため本人確認登録が必須となります。'}</ErrorAlert>
      <SingleTextInput
        title={"郵便番号(半角数字のみ)"}
        value={postalCode}
        name={'postalCode'}
        validation={true}
        multiline={false}
        setFunction={setPostalCode}
        placeholder={'1050011'}
        required={true}
        propsHelperMessage={'7桁または8桁の数字を入力してください'}
        maxNumber={'8'}
      />
      <SingleTextInput
        title={"住所(郵便番号入力で自動入力）"}
        disabled={true}
        value={address_1}
        setFunction={setAddress_1}
        readOnly={true}
        placeholder={'東京都港区芝公園'}
      />
      <SingleTextInput
        title={"住所(番地以降 半角数字ハイフン)"}
        name={'address_2'}
        value={address_2}
        setFunction={onChangeAddress2}
        validation={true}
        multiline={false}
        placeholder={'6-10-202'}
        required={true}
        value={address_2}
        propsHelperMessage={'半角数字ハイフンで入力してください'}
        /*helperText1={'※ビル名等は記入せずハイフンで繋げて記入してください'}*/
        helperText2={'※例）6丁目10 ABCビル202 => 記入内容）6-10-202'}
      />
      <SingleTextInput
        name={'lastNameKanji'}
        title="氏名（姓）"
        label="氏名（姓）を入力してください"
        value={lastNameKanji}
        setFunction={setLastNameKanji}
        placeholder={'田中'}
        required={true}
        propsHelperMessage={'使用できない記号が含まれています'}
      />
      <SingleTextInput
        name={'firstNameKanji'}
        validation={true}
        title="氏名（名）"
        label="氏名（名）を入力してください"
        value={firstNameKanji}
        setFunction={setFirstNameKanji}
        placeholder={'太郎'}
        required={true}
        propsHelperMessage={'使用できない記号が含まれています'}
      />
      <SingleTextInput
        name={'lastNameKana'}
        validation={true}
        title="氏名（セイ）"
        label="氏名（セイ）を入力してください"
        placeholder={'タナカ'}
        required={true}
        value={lastNameKana}
        setFunction={setLastNameKana}
        propsHelperMessage={'全てカタカナで入力してください'}
      />
      <SingleTextInput
        name={'firstNameKana'}
        validation={true}
        title="氏名（メイ）"
        label="氏名（メイ）を入力してください"
        placeholder={'タロウ'}
        required={true}
        value={firstNameKana}
        setFunction={setFirstNameKana}
        propsHelperMessage={'全てカタカナで入力してください'}
      />
      <RadioInput
        title="性別"
        required={true}
        items={genderList}
        onChange={(e) => setGender(e.target.value)}
        value={gender}
      />
      <SingleTextInput
        name={'email'}
        validation={true}
        title="メールアドレス"
        label="メールアドレスを入力してください"
        placeholder={'testAccount@xxxx'}
        required={true}
        value={email}
        setFunction={setEmail}
        propsHelperMessage={'正しい形式のメールアドレスを入力してください'}
      />
      <SingleTextInput
        name={'phoneNumber'}
        validation={true}
        title="電話番号"
        label="半角数字、ハイフンなし"
        placeholder={'080xxxxxxxx'}
        required={true}
        value={phoneNumber}
        setFunction={setPhoneNumber}
        propsHelperMessage={'11桁以内の数値を入力してください'}
      />
      <DatePicker
        title="生年月日"
        required={true}
        disablePast={false}
        setFunction={(date) => {
          onChangeDob(date);
        }}
        value={dobInputValue}
      />
      <SelectInput
        label="選択してください"
        title="身分証明書の種類"
        required={true}
        items={identificationList}
        onChange={(e) => {
          setIdentificationType(identificationList[Number(e.target.value) - 1].value);
        }}
        helperText={'*"jpeg","jpg","png"のファイル形式でアップロードしてください'}
      />
      <KYCInput
        Ref={frontPhotoRef}
        onClick={handleFrontPhotoClick}
        type={'front'}
        title={'身分証をアップロードする(表)'}
        file={images.length > 0 ? images[0] : null}
        setFunction={onChangeImage}
      />
      {identificationType === identificationList[0].value && (
        <KYCInput
          Ref={backPhotoRef}
          onClick={handleBackPhotoClick}
          type={'back'}
          title={'身分証をアップロードする(裏)'}
          file={images.length > 0 ? images[1] : null}
          setFunction={onChangeImage}
        />
      )}
      <CheckInput
        title="利用規約"
        label={
          <>
            <a onClick={() => window.open(termsUrl, '_blank')}
              style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
              利用規約</a>に同意する
          </>
        }
        required={true}
        items={[termStatus]}
        onChange={(index) => { handleCheck(index) }}
      />
      <BasicButton
        onClick={() => onConfirm()}
        disabled={disabled}
      >{disabled ? '登録中...' : '登録する'}
      </BasicButton>
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
    </StyledFullPaper>
  )
}
export default KYCForm;

