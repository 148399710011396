import React from 'react';
import {PageTitle} from '../atoms/Title';
import CommentList from '../organisms/CommentList';

const CommentListPage = () => {
  return (
    <>
      <PageTitle>{'コメント一覧'}</PageTitle>
      <CommentList/>
    </>
  )
}
export default CommentListPage;
