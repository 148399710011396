import { functions } from '../index';

const functionsProviderOrders = {
  makeProviderOrder: async (plan) => {
		return await functions.httpsCallable('onCall-makeProviderOrder')({
      plan: plan,
    }).catch((err) => {
			console.log('err updateContract');
			throw err;
		});
  },
};

export default functionsProviderOrders;
