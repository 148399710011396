import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { GoogleIcon, LineIcon } from '../../../icons/icon';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loginLabel: {
    justifyContent: 'space-between'
  },
}));

export const EditButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <IconButton variant="contained" disabled={disabled} onClick={onClick}>
      {props.children}
      <EditIcon />
    </IconButton>
  );
};

export const BasicButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const color = props.color ? props.color : 'primary';
  return (
    <StyledButton
      variant="contained"
      color={color}
      disabled={disabled}
      onClick={onClick}
    >
      {props.children}
    </StyledButton>
  );
};

export const BasicSubmitButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <StyledButton
      variant="contained"
      color="primary"
      type="submit"
      disabled={disabled}
      onClick={onClick}
    >
      {props.children}
    </StyledButton>
  );
};
export const UploadButton = (props) => {
  const title = props.title;
  const required = props.required ? props.required : false;
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  const onChange = props.onChange ? props.onChange : () => {};
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>
        {title}
      </Title>
      <UpButton
        Button
        startIcon={<CloudUploadIcon />}
        variant="outlined"
        color="primary"
        disabled={disabled}
        onClick={onClick}
      >
        {props.children}
      </UpButton>
    </Div>
  );
};

const StyledButton = styled(Button)`
  width: calc(100% - ${(props) => props.theme.spacing(4)}px * 2);
  margin: ${(props) => props.theme.spacing(4)}px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 327px;
  }
`;

const UpButton = styled(StyledButton)`
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    margin: ${(props) => props.theme.spacing(4)}px
      ${(props) => props.theme.spacing(2)}px;
  }
`;

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
  }
`;

const Title = styled(Typography)`
  margin: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(2)}px 0;
  font-weight: 700;
  width: calc(240px - ${(props) => props.theme.spacing(2)}px * 3);
  &[data-required='true'] {
    &::after {
      content: '*';
      margin-left: ${(props) => props.theme.spacing(2)}px;
      font-size: 14px;
      color: red;
    }
  }
`;

const IconTextButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={props.icon}
      disabled={disabled}
      onClick={onClick}
    >
      {props.children}
    </Button>
  );
};

export const TextButtonWithAddIcon = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <div>
      <IconTextButton
        color="primary"
        icon={<AddIcon />}
        disabled={disabled}
        onClick={onClick}
      >
        {props.children}
      </IconTextButton>
    </div>
  );
};

export const TextButtonWithCalendarIcon = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <div>
      <IconTextButton
        color="primary"
        icon={<DateRangeIcon />}
        disabled={disabled}
        onClick={onClick}
      >
        {props.children}
      </IconTextButton>
    </div>
  );
};

export const TextButtonWithGroupAddIcon = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <div>
      <IconTextButton
        color="primary"
        icon={<GroupAddIcon />}
        disabled={disabled}
        onClick={onClick}
      >
        {props.children}
      </IconTextButton>
    </div>
  );
};

export const WithGoogleIconButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const classes = useStyles();

  return(
    <GoogleButton
      classes={{ label: classes.loginLabel }}
      startIcon={<StyledGoogleIcon />}
      endIcon={<span/>}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      disableElevation
      >
        {props.children}
      </GoogleButton>
  )
}

export const WithLineIconButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const classes = useStyles();

  return(
    <LineButton
        classes={{ label: classes.loginLabel }}
        startIcon={<StyledLineIcon />}
        endIcon={<span/>}
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        disableElevation
          >
          {props.children}
      </LineButton>
  )
}

export const SmallButton = styled(Button).attrs({
  variant: 'outlined',
  color: 'primary',
})`
  margin: ${(props) => props.theme.spacing(0.5)}px;
`;
const StyledLineIcon = styled(LineIcon)`
    width: 3.1vw;
    height: 3.1vw;
    @media (min-width: 1600px){
      width: 50px;
      height: 50px;
    }
`
const StyledGoogleIcon = styled(GoogleIcon)`
    width: 3.1vw;
    height: 3.1vw;
    @media (min-width: 1600px){
      width: 50px;
      height: 50px;
    }
`

const GoogleButton = styled(Button)`
    border:1px solid #D9D9D9;
    color:#8D8D8D;
    background-color: #FFFFFF;
    font-weight:bold;
    width: 26.25vw;
    font-size:1.4vw;
    font-family: Arial;
    margin-bottom:2.5vw;
    &:hover {
      background-color: #FFFFFF;
      opacity: 0.7;
  }
  @media (min-width: 1600px){
    width: 420px;
    margin-bottom:40px;
    font-size:1.4rem;
  }
`
const LineButton = styled(Button)`
    color:#FFFFFF;
    background-color: #00B900;
    font-weight:bold;
    width: 26.25vw;
    font-size:1.4vw;
    font-family: Arial;
    margin-bottom:15.3vw;
    &:hover {
      background-color: #00B900;
      opacity: 0.7;
  }
  @media (min-width: 1600px){
    width: 420px;

    font-size:1.4rem;

  }
`

const CheckLabel = styled.label`
  display: inline-block;
  height: auto;
  width: auto;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    border-radius: 16px;
  }
  position: relative;
`;
const Input = styled.input.attrs({ type: 'file' })`
  display: none;
`;
