import React, { useEffect, useState } from 'react'
import AccountingManagement from '../molecules/AccountingManagement';
import PurchaserManagement from '../molecules/PurchaserManagement';
import { firestoreConsumers } from '../../firebase/firestore/firestoreConsumers';
import { useSelector } from 'react-redux';
import { firestoreOrders } from '../../firebase/firestore/firestoreOrders';
import { firestoreProviders } from '../../firebase/firestore/firestoreProviders';
import { checkMonth } from '../../utils/momentUtils';
import { CustomizedTabs as Tabs } from '../molecules/Tabs';
import { initializeApp } from 'firebase';
const moment = require('moment-timezone');

const Accounting = (props) => {
  const providerData = useSelector(state => state.providerData);
  const contents = useSelector(state => state.contents);
  const providerId = providerData ? providerData.uid : null;
  const [subscriber, setSubscriber] = useState(null);
  const [singleItemBuyer, setSingleItemBuyer] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [monthlyAmount, setMonthlyAmount] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalViews, setTotalViews] = useState(0);

  const init = async() => {
    await getSubscriber();
    await getOrders();
    await getTotalViews();
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [providerId]);

  const getSubscriber = async() => {
    const customers = await firestoreConsumers.getAllData().catch((err) => { throw err});
    const subscriberList = [];
    if(customers && providerId) {
      await Promise.all(customers.map(async(customer) => {
       const res =  await firestoreConsumers.getSubscribeData(customer.id, providerId).catch((err) => { throw err});
       if(res && res.status === "active"){
         subscriberList.push(customer)
       }
      }))
      setSubscriber(subscriberList);
    }
  }

  const getOrders = async() => {
    if(!providerId) return;
    const singleItemBuyerList = [];
    const list = [];
    let amount = 0;
    let amountOfThisMonth = 0
    checkMonth()
    const orders = await firestoreOrders.getCollectionRef().where('providerRef', '==', firestoreProviders.getRef(providerId)).get().catch((err) => { throw err });
    if (!orders.empty) {
      orders.docs.map((order) => {
        if (order.data().status === 'done') {
        if (order.data().orderType === 'normal') {
          singleItemBuyerList.push(order.data());
        }
          amount = amount + Math.floor(Number(order.data().amount));
          if (moment(order.data().createAt.seconds * 1000).locale('ja').diff(moment(moment().startOf('month').locale('ja'), 'day')) > 0) {
            amountOfThisMonth = amountOfThisMonth + Math.floor(Number(order.data().amount));
          };
        list.push({
          orderId: order.id,
          data: order.data()
        })
      }
      })
      setSingleItemBuyer(singleItemBuyerList);
      setTotalAmount(amount);
      setMonthlyAmount(amountOfThisMonth);
      setOrderList(list);
    } else {
      setSingleItemBuyer(singleItemBuyerList);
      setTotalAmount(amount);
      setMonthlyAmount(amountOfThisMonth);
      setOrderList(list);
    }
  }

  const getTotalViews = async () => {
    let totalViews = 0;
    if (contents && contents.length > 0) {
      for (let i = 0; i < contents.length; i++) {
        const resposne = await firestoreProviders.getViewsRef(providerId, contents[i].id).get();
        totalViews += totalViews + resposne ? resposne.size : 0;
      }
      setTotalViews(totalViews);
    }
  }

  const tabList = [
    {
      label: "売上管理",
      content: <AccountingManagement
        loading={loading}
        totalViews={totalViews}
        subscriber={subscriber}
        singleItemBuyer={singleItemBuyer}
        totalAmount={totalAmount}
        monthlyAmount={monthlyAmount}
        orderList={orderList} />
    },
    {
      label: "購入顧客履歴",
      content: <PurchaserManagement
        orderList={orderList} />
    },
  ]

  return (
    <>
      <Tabs contents={tabList} />
    </>
  )
}

export default Accounting;
