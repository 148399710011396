const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Tokyo');

const checkBackNumberStatus = (subscribeDate, createAt) => {
  if(createAt && (subscribeDate !== null)){
  const diff = moment().diff(moment(createAt * 1000),'days')
  return subscribeDate - diff;
  }
}

export default checkBackNumberStatus;