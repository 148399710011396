import { firestore } from './firestore';

const definitions = 'definitions';
const plans = 'plans';
const stripeInfo = 'stripeInfo';

const ref = {
  collection: () => firestore.collection(definitions),
  doc: () => ref.collection().doc(plans),
}

export const firestoreDefinitions = {
  getCollectionRef: () => ref.collection(),
  getNewDocRef: () => ref.newDoc(),
  getRef: () => ref.doc(),
  getAllData: async () => {
   const userDatas = await firestore.getAllDatas(ref.collection()).catch((err) => {
     console.log('FirestoreOrders getAllData err');
     throw err
   })
   return userDatas;
  },
  getData: async () => {
   const data = await firestore.get(ref.doc()).catch((err) => {
     console.log('FirestoreOrders getRef err');
     throw err
   })
    return data;
  },
}
