/** action-creator */
export const setProviderData= (value) => {
  return { type: 'SET_PROVIDERDATA', value: value };
};
export const resetProviderData = (value) => {
  return { type: 'RESET_PROVIDERDATA', value: value };
};

/** reducer */
const providerData = (state = null, action) => {
    switch (action.type) {
      case 'SET_PROVIDERDATA':
          var userProfile = Object.assign({},action.value);
          return userProfile;
      case 'RESET_PROVIDERDATA':
            return {};
      default:
        return state
    }
  }

  export default providerData;