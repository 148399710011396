import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import styled from "styled-components"
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  helperText: {
    color: 'red',
  }
}));

export const DatePicker = (props) => {
  const classes = useStyles();
  const title = props.title;
  const value = props.value;
  const setFunction = props.setFunction;
  const required = props.required ? props.required : false;
  const error = props.error || false;
  const errorMessage = props.errorMessage || '';
  const onChange = (date) => {
    setFunction(date);
  };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledPicker
          margin="normal"
          id="date-picker-dialog"
          /*label={title}*/
          format="yyyy/MM/dd"
          variant="outlined"
          value={value}
          onChange={onChange}
          required={required}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={error}
          helperText={errorMessage}
          FormHelperTextProps={{
            className: classes.helperText
          }}
        />
      </MuiPickersUtilsProvider>
    </Div>
  );
}

const StyledPicker = styled(KeyboardDatePicker)`
    margin:${props => props.theme.spacing(2)}px;
`

const AjustWidth = styled.div`
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        width: 375px;
    }
`

const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        // SelectInputとの幅調整のため
        margin-right: 110px;
    }
`

const Title = styled(Typography)`
    margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    line-height: 20px;
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left:${props => props.theme.spacing(1)}px;
          font-size:14px;
          color:red;
        }
    }
    //font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2B2B2B;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px 0;
        font-weight:700;
        width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
        line-height: 20px;
        &[data-required="true"] {
            &::after{
              content:'*';
              margin-left:${props => props.theme.spacing(1)}px;
              font-size:14px;
              color:red;
            }
        }
        //font-family: Noto Sans JP;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 30px;
        color: #2B2B2B;

    }
`