/** action-creator */
export const setComments= (value) => {
  return { type: 'SET_COMMENTS', value: value };
};
export const resetComments = (value) => {
  return { type: 'RESET_COMMENTS', value: value };
};

/** reducer */
const comments = (state = null, action) => {
    switch (action.type) {
      case 'SET_COMMENTS':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_COMMENTS':
            return [];
      default:
        return state
    }
  }
  export default comments;
