import React, { useState, useCallback, useEffect } from "react";
import { Comment } from "../atoms/Text";
import moment from "moment-timezone";
import { AvatarText } from "../atoms/TextInput";
import Collapse from "@material-ui/core/Collapse";
import SimplePopover from "../molecules/PopOver";
import ReplyList from "../molecules/ReplyList";
import { CommentStatusPopover } from "../molecules/PopOver";
import functionsProviders from "../../firebase/functions/functionsProviders";
import CustomizedSnackbar from "../atoms/CustomizedSnackbar";
import { setComments } from "../../redux/action_reducers/comments";
import { firestoreProviders } from "../../firebase/firestore/firestoreProviders";
import { useDispatch } from "react-redux";
import {
  DELETE_COMMENT_MESSAGE,
  UPDATE_COMMENT_STATUS_MESSAGE,
  GET_DATA_FROM_FIRESTORE_MESSAGE,
  REPLY_COMMENT_MESSAGE,
  DELETE_REPLY_COMMENT_MESSAGE,
  UPDATE_REPLY_COMMENT_MESSAGE
} from "../../static/messages";

const CommentComponent = (props) => {
  const dispatch = useDispatch();
  const [isOpenReplyForm, setIsOpenReplyForm] = useState(false);
  const [isOpenReply, setIsOpenReply] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [replyMessage, setReplyMessage] = useState("");
  const [messageType, setMessageType] = useState(null);
  const [tmpReplyMessage, setTmpReplyMessage] = useState("");
  const [replyPublished, setReplyPublished] = useState(false);
  const [sendFromReply, setSendFromReply] = useState("");
  const [inEdit, setInEdit] = useState({
    replyId: null,
    status: false,
  });
  const [isMyPost, setIsMyPost] = useState(false);
  const [snackState, setSnackState] = useState({
    open: false,
    message: "message",
    success: true,
  });
  const [disabled, setDisabled] = useState(false);
  const [replyDisabled, setReplyDisabled] = useState(false);
  const [editReplyDisabled, setEditReplyDisabled] = useState(false);
  const [isDeleteReply, setIsDeleteReply] = useState(false);
  const [anchorDisabled, setAnchorDisabled] = useState(false);
  const [replyTarget, setReplyTarget] = useState("all");

  const comment = props.comment;
  const commentId = comment.commentId;
  const contentId = comment.contentId;
  const providerData = props.providerData;
  const providerId = providerData?.uid;
  const userData = comment?.userData;
  const avatar = userData?.avatar;
  const nickName = userData?.nickName;
  const commentData = comment?.commentData;
  const message = commentData?.message;
  const createAt = commentData?.createAt
    ? moment(commentData?.createAt.seconds * 1000).format("YYYY.MM.DD HH:mm")
    : "";
  const contentData = comment?.contentData;
  const mediaSrc = contentData?.thumbUrl;
  const title = contentData?.title ? contentData?.title : "";
  const target = commentData?.target;
  const published = commentData?.published;
  const providerAvatar = providerData?.shopInfo?.avatar;

  const handleEliipsis = (
    e,
    type,
    replyId,
    replyMessage,
    senderFrom,
    replyPublished
  ) => {
    if (anchorDisabled) return;
    setIsMyPost(false);
    setAnchorEl(e.currentTarget);
    setInEdit({
      replyId: replyId,
      status: false,
    });
    setMessageType(type);
    setTmpReplyMessage(replyMessage);
    setReplyPublished(replyPublished);
    setSendFromReply(senderFrom?.parent.path);
    console.log(senderFrom?.parent.path);
    // popoverのメニューの出しわけ
    if (
      senderFrom?.id === providerData.uid &&
      senderFrom?.parent.path === "providers"
    ) {
      setIsMyPost(true);
    }
  };
  const handleCloseEliipsis = () => {
    setAnchorEl(null);
    setMessageType(null);
    setInEdit({
      replyId: null,
      status: false,
    });
    setTmpReplyMessage("");
    setIsMyPost(false);
  };

  const onEdit = () => {
    if (anchorDisabled) return;
    setInEdit({
      replyId: inEdit.replyId,
      status: true,
    });
    setAnchorEl(null);
  };
  const onCancel = () => {
    setInEdit({
      replyId: null,
      status: true,
    });
    setTmpReplyMessage("");
    setIsMyPost(false);
  };

  const openStatusPopover = (e) => {
    if (anchorDisabled) return;
    setStatusAnchorEl(e.currentTarget);
  };
  /**
   * commentの削除
   */
  const onDeletComment = useCallback(async () => {
    handleCloseEliipsis();
    setAnchorDisabled(true);
    const response = await functionsProviders
      .deleteComment({
        providerId: providerId,
        contentId: contentId,
        commentId: commentId,
      })
      .catch((err) => {
        setSnackState({
          open: true,
          message: DELETE_COMMENT_MESSAGE.FAILED,
          success: false,
        });
        setAnchorDisabled(false);
        return;
      });
    if (!response) {
      setSnackState({
        open: true,
        message: DELETE_COMMENT_MESSAGE.FAILED,
        success: false,
      });
      setAnchorDisabled(false);
      return;
    } else {
      setSnackState({
        open: true,
        message: DELETE_COMMENT_MESSAGE.SUCCESS,
        success: true,
      });
      //　ここでデータをgetしてreduxに入れ直す
      const getCommentData = await firestoreProviders
        .getCommentsData(providerId)
        .catch((err) => {
          setSnackState({
            open: true,
            message: GET_DATA_FROM_FIRESTORE_MESSAGE.FAILED,
            success: false,
          });
          setAnchorDisabled(false);
          return;
        });
      dispatch(setComments(getCommentData));
      setAnchorDisabled(false);
    }
  }, [providerId, contentId, commentId]);

  /**
   * commentのstatusの変更はここで行う
   * @param {*} data
   */
  const onEditCommentStatus = useCallback(
    async (data) => {
      if (!(data && providerId && contentId && commentId)) {
        return;
      }
      if (anchorDisabled) return;
      setDisabled(true);
      const response = await functionsProviders
        .updateComment({
          providerId: providerId,
          contentId: contentId,
          commentId: commentId,
          message: message,
          published: data.published,
          target: data.target,
        })
        .catch((err) => {
          setSnackState({
            open: true,
            message: UPDATE_COMMENT_STATUS_MESSAGE.FAILED,
            success: false,
          });
          setDisabled(false);
          return;
        });
      if (!response) {
        setSnackState({
          open: true,
          message: UPDATE_COMMENT_STATUS_MESSAGE.FAILED,
          success: false,
        });
        setDisabled(false);
        return;
      } else {
        setSnackState({
          open: true,
          message: UPDATE_COMMENT_STATUS_MESSAGE.SUCCESS,
          success: true,
        });
      }
      // ここでデータをgetしてreduxに入れ直す
      const getCommentData = await firestoreProviders
        .getCommentsData(providerId)
        .catch((err) => {
          setSnackState({
            open: true,
            message: GET_DATA_FROM_FIRESTORE_MESSAGE.FAILED,
            success: false,
          });
          return;
        });
      setDisabled(false);
      dispatch(setComments(getCommentData));
    },
    [providerId, contentId, commentId, anchorDisabled]
  );

  /**
   * replyメッセージのPOST
   */
  const postReplyMessage = useCallback(
    async (data) => {
      // consumer replyに対するPOSTかどうかチェック
      if (!data?.isConsumerReply) {
        // replyMessage: 初回リプライコメントメッセージ
        // data.editReplyMsg: リプライの編集コメントメッセージ
        if (!replyMessage) {
          if (!data?.editReplyMsg) {
            return;
          }
        }
        if (data?.replyId) {
          // 既存リプライの編集の場合
          setEditReplyDisabled(true);
        } else {
          // 新規リプライの場合
          setReplyDisabled(true);
        }
        if (!comment?.repliedFlag) {
          await functionsProviders
            .updateComment({
              providerId: providerId,
              contentId: contentId,
              message: message,
              commentId: commentId,
              published: true,
              target: data.replyTarget,
            })
            .catch((err) => {
              setSnackState({
                open: true,
                message: REPLY_COMMENT_MESSAGE.FAILED,
                success: false,
              });
              setReplyDisabled(false);
              setEditReplyDisabled(false);
              return;
            });
        }
      } else {
        setEditReplyDisabled(true);
        setAnchorEl(null);
      }
      const response = await functionsProviders
        .replyComment({
          replyId: data?.replyId ? data?.replyId : null,
          providerId: providerId,
          contentId: contentId,
          message: data?.editReplyMsg
            ? data.editReplyMsg
            : data?.isConsumerReply
            ? data?.consumerReplyMsg
            : replyMessage,
          commentId: commentId,
          published: data?.isConsumerReply
            ? data?.replyPublished
              ? true
              : false
            : published,
          userType: data?.isConsumerReply ? "consumer" : "provider",
        })
        .catch((err) => {
          setSnackState({
            open: true,
            message: data?.replyId
              ? UPDATE_REPLY_COMMENT_MESSAGE.FAILED
              : REPLY_COMMENT_MESSAGE.FAILED,
            success: false,
          });
          setReplyDisabled(false);
          setEditReplyDisabled(false);
        });
      if (!response) {
        setSnackState({
          open: true,
          message: data?.replyId
            ? UPDATE_REPLY_COMMENT_MESSAGE.FAILED
            : REPLY_COMMENT_MESSAGE.FAILED,
          success: false,
        });
        setReplyDisabled(false);
        setEditReplyDisabled(false);
      } else {
        setSnackState({
          open: true,
          message: data?.replyId
            ? UPDATE_REPLY_COMMENT_MESSAGE.SUCCESS
            : REPLY_COMMENT_MESSAGE.SUCCESS,
          success: true,
        });
        setReplyDisabled(false);
        setEditReplyDisabled(false);
        setReplyMessage("");
        setIsOpenReply(true);
        onCancel();
      }
      if (data?.isConsumerReply) {
        onCancel();
        setEditReplyDisabled(false);
        setIsDeleteReply(false);
        setAnchorEl(null);
      }
    },
    [replyMessage, replyTarget]
  );
  /**
   * replyメッセージのDELETE
   */
  const deleteReplyMessage = async () => {
    // Chipを表示するフラグ
    setEditReplyDisabled(true);
    // 削除中と表示するフラグ
    setIsDeleteReply(true);
    setAnchorEl(null);
    const response = await functionsProviders
      .deleteReplyComment({
        replyId: inEdit.replyId,
        providerId: providerId,
        contentId: contentId,
        commentId: commentId,
      })
      .catch((err) => {
        setSnackState({
          open: true,
          message: DELETE_REPLY_COMMENT_MESSAGE.FAILED,
          success: false,
        });
        setEditReplyDisabled(false);
        setIsDeleteReply(false);
      });
    if (!response) {
      setSnackState({
        open: true,
        message: DELETE_REPLY_COMMENT_MESSAGE.FAILED,
        success: false,
      });
      setEditReplyDisabled(false);
      setIsDeleteReply(false);
    } else {
      setSnackState({
        open: true,
        message: DELETE_REPLY_COMMENT_MESSAGE.SUCCESS,
        success: true,
      });
      setReplyMessage("");
      onCancel();
      setEditReplyDisabled(false);
      setIsDeleteReply(false);
      setAnchorEl(null);
    }
  };
  return (
    <>
      <Comment
        avatar={avatar}
        nickName={nickName}
        message={message}
        createAt={createAt}
        activeEclipse={true}
        mediaSrc={mediaSrc}
        title={title}
        published={published}
        target={target}
        isOpenReplyForm={isOpenReplyForm}
        setIsOpenReplyForm={() => setIsOpenReplyForm(!isOpenReplyForm)}
        isOpenReply={isOpenReply}
        setIsOpenReply={() => setIsOpenReply(!isOpenReply)}
        numberOfReply={comment?.numberOfReply}
        onClick={(e) => handleEliipsis(e, "comment")}
        openStatusPopover={openStatusPopover}
        disabled={disabled}
      >
        <Collapse in={isOpenReplyForm}>
          <AvatarText
            avatar={providerAvatar}
            value={replyMessage}
            onChange={(e) => setReplyMessage(e.target.value)}
            onCancel={() => setIsOpenReplyForm(false)}
            onPost={postReplyMessage}
            setReplyTarget={(e) => setReplyTarget(e.target.value)}
            replyTarget={replyTarget}
            replyDisabled={replyDisabled}
            editReplyDisabled={false}
            numberOfReply={comment?.numberOfReply}
            repliedFlag={comment?.repliedFlag}
            published={published}
          />
        </Collapse>
        <Collapse in={isOpenReply}>
          <ReplyList
            comment={comment}
            isOpenReply={isOpenReply}
            providerData={providerData}
            handleEliipsis={handleEliipsis}
            isMyPost={isMyPost}
            inEdit={inEdit}
            onCancel={onCancel}
            tmpReplyMessage={tmpReplyMessage}
            postReplyMessage={postReplyMessage}
            uid={providerData.uid}
            replyDisabled={false}
            editReplyDisabled={editReplyDisabled}
            isDeleteReply={isDeleteReply}
            numberOfReply={comment?.numberOfReply}
            commentPublished={published}
            commentRepliedFlag={comment?.repliedFlag}
          />
        </Collapse>
        <SimplePopover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onEdit={onEdit}
          inEdit={inEdit}
          tmpReplyMessage={tmpReplyMessage}
          handleCloseEliipsis={handleCloseEliipsis}
          isMyPost={isMyPost}
          messageType={messageType}
          sendFromReply={sendFromReply}
          replyPublished={replyPublished}
          onDelete={
            messageType === "reply" ? deleteReplyMessage : onDeletComment
          }
          onDisclose={postReplyMessage}
        />
        <CommentStatusPopover
          anchorEl={statusAnchorEl}
          setAnchorEl={setStatusAnchorEl}
          target={target}
          published={published}
          onEdit={onEditCommentStatus}
        />
      </Comment>
      <CustomizedSnackbar
        snackState={snackState}
        setSnackState={setSnackState}
      />
    </>
  );
};
export default CommentComponent;
