import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from "styled-components";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const BasicTabs = (props) => {
  const [value, setValue] = useState(0);
  const contents = props.contents ? props.contents : [];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <StyledTabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        {contents.map((item, index) => (
          <Tab key={index} label={item.label} />
        ))}

      </StyledTabs>
      {contents[value].content}
    </>
  )
}


export const CustomizedTabs = (props) => {
  const matches = useMediaQuery('(min-width:600px)');
  const [value, setValue] = useState(0);
  const sourceTab = props.sourceTab;

  const contents = props.contents ? props.contents : [];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (sourceTab && contents) {
      const tabNumber = contents.findIndex((doc) => doc.label === sourceTab);
      if (tabNumber !== -1) {
        setValue(tabNumber);
      }
    }
  }, []);
  return (
    <>
      <CustomizedTab
        value={value}
        indicatorColor="primary"
        textColor="primary"
        variant={!matches ? 'fullWidth' : 'standard'}
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        {contents && contents.map((item, index) => (
          <StyledTabWrap key={index} label={item.label} textColorPrimary='#252B43' />
        ))}
      </CustomizedTab>
      {contents[value].content}
    </>
  )
}

const StyledTabs = styled(Tabs)`
    margin-left:${props => props.theme.spacing(2)}px;
`
const CustomizedTab = styled(Tabs)`
.MuiTab-textColorPrimary.Mui-selected {
  color: #2E7DF6;
};
.PrivateTabIndicator-colorPrimary-2 {
  background-color: #2E7DF6;
}
.PrivateTabIndicator-root-1 {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #252B43;
}
font-style: Noto Sans JP;
margin-left:${props => props.theme.spacing(2)}px;
margin-right:${props => props.theme.spacing(2)}px;
margin-bottom: ${props => props.theme.spacing(3)}px;
border-bottom: 1px solid ${props => props.theme.palette.gray.gray02};
 @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
    margin:0;
  }
`

const StyledTabWrap = styled(Tab)`
font-weight: 700;
.MuiTab-textColorPrimary.Mui-selected {
  color:#252B43;;
}
`