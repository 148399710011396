import React, { useCallback, useEffect, useState } from 'react';
import { Comment, Reply } from '../atoms/Text';
import moment from 'moment-timezone';
import { AvatarText } from '../atoms/TextInput';
import Collapse from '@material-ui/core/Collapse';
import { firestoreProviders } from '../../firebase/firestore/firestoreProviders';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setComments } from '../../redux/action_reducers/comments';
import { useDispatch } from "react-redux";
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';

const ReplyList = (props) => {
  const dispatch = useDispatch();
  const {
    providerData,
    comment,
    isOpenReply,
    handleEliipsis,
    tmpReplyMessage,
    inEdit,
    onCancel,
    postReplyMessage,
    uid,
    replyDisabled,
    editReplyDisabled,
    isDeleteReply,
    numberOfReply,
    commentPublished,
    commentRepliedFlag
  } = props;
  const [replies, setReplies] = useState(null)
  const [tmpMessage, setTmpMessage] = useState(tmpReplyMessage);
  const [tmpReplyTarget, setTmpReplyTarget] = useState('all');
  const contentId = comment?.contentId;
  const commentId = comment?.commentId;
  const providerId = providerData?.uid;
  const providerAvatar = providerData?.shopInfo?.avatar;
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });

  useEffect(() => {
    if (tmpReplyMessage) {
      setTmpMessage(tmpReplyMessage);
    }
  }, [tmpReplyMessage]);
  useEffect(() => {
    postReplyMessage()
  }, [tmpReplyMessage]);
  // isOpenReply をトリガーに読み込みを走らせる onSnapShotを使いすぎたくない
  // replyをリッスン（開いたときだけ）
  useEffect(() => {
    if (isOpenReply) {
      const query = firestoreProviders.getRepliesRef(providerId, contentId, commentId)
      let list = [];
      query.onSnapshot(async (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            // 追加
            list.push({
              id: change.doc.id,
              data: change.doc.data(),
            });
          }
          if (change.type === "removed") {
            // 削除
            list = list.filter((doc) => doc.id !== change.doc.id);
          }
          if (change.type === "modified") {
            //　修正
            const index = list.findIndex((doc) => doc.id === change.doc.id);
            if (index !== -1) {
              list[index] = {
                id: change.doc.id,
                data: change.doc.data()
              }
            }
          }
        });
        if (list) {
          let res = await Promise.all(list.map(async (comment) => {
            const sendFrom = comment.data.sendFrom;
            const userData = await sendFrom.get();
            return {
              ...comment,
              userData: sendFrom.parent.id === 'providers' ? userData.data() : userData.data().profile,
            }
          }));
          const sorted = res.sort((a, b) => a.data.createAt.seconds > b.data.createAt.seconds ? -1 : 1);
          setReplies(sorted);
          // ここでデータをgetしてreduxに入れ直す
          const getCommentData = await firestoreProviders.getCommentsData(providerId).catch((err) => {
            setSnackState({
              open: true,
              message: '読み込み処理に失敗しました。ブラウザを更新してください',
              success: false
            });
            return;
          });
          //setDisabled(false);
          dispatch(setComments(getCommentData));
        } else {
          setReplies([]);
        }
      });
    }
  }, [isOpenReply]);

  /**
   * すでにあるreplyの編集結果をpost
   */
  //const postReplyMessage = useCallback(() => {
  //  // tmpMessage,  tmpReplyTarget
  //  window.alert('reply')
  //}, [])

  return (
    <>
      {replies ? replies.length > 0 ? replies.map((reply) => {
        const userData = reply.userData;
        const avatar = userData?.avatar ? userData?.avatar : '';
        const nickName = userData?.nickName ? userData?.nickName : '';
        const message = reply.data.message;
        const updateAt = moment(reply.data.updateAt.seconds * 1000).format('YYYY.MM.DD HH:mm');
        const sendFrom = reply.data.sendFrom;
        const published = reply.data.published;
        return (
          <>
            {(inEdit.status && inEdit.replyId === reply.id) ?
              <AvatarText
                avatar={providerAvatar}
                value={tmpMessage}
                onChange={(e) => setTmpMessage(e.target.value)}
                onCancel={onCancel}
                replyTarget={tmpReplyTarget}
                setReplyTarget={(e) => setTmpReplyTarget(e.target.value)}
                replyId={reply.id}
                onPost={postReplyMessage}
                replyDisabled={replyDisabled}
                editReplyDisabled={editReplyDisabled}
                isDeleteReply={isDeleteReply}
                numberOfReply={numberOfReply}
                repliedFlag={commentRepliedFlag}
                published={commentPublished}
              /> :
              <Reply
                avatar={avatar}
                nickName={nickName}
                message={message}
                updateAt={updateAt}
                activeEclipse={true}
                uid={uid}
                inEdit={inEdit}
                replyId={reply.id}
                sendFrom={sendFrom}
                published={published}
                editReplyDisabled={editReplyDisabled}
                isDeleteReply={isDeleteReply}
                onClick={(e) => handleEliipsis(e, 'reply', reply.id, message, sendFrom, published)}
              />}
          </>
        )
      }) : <></> : <p><CircularProgress size={20} style={{ marginLeft: 8 }} /></p>}
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
    </>
  )
}

export default ReplyList;