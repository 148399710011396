import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { BasicButton } from './Button';
import Typography from '@material-ui/core/Typography';
import { BodyText, BodyTitle, Caption } from '../../atoms/web_kit/Text';
import PhotoIcon from '@material-ui/icons/Photo';

export const EditableAvatar = (props) => {
  const title = props.title;
  const label = props.label;
  const value = props.value;
  const avatar = props.avatar;
  const required = props.required ? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : () => {};
  const onChange = props.onChange ? props.onChange : () => {};
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>
        {title}
      </Title>
      <Input id="id_upload_file" onChange={onChange}></Input>
      <CheckLabel htmlFor={'id_upload_file'}>
        <StyledAvatar variant="rounded" src={avatar} onClick={onClick}>
          {loading ? <CircularProgress /> : <AddAPhotoIcon />}
        </StyledAvatar>
      </CheckLabel>
    </Div>
  );
};
export const NamedAvatar = (props) => {
  const name = props.name;
  const caption = props.caption;
  const avatar = props.avatar;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <RowWrap>
      <CircledAvatar src={avatar} onClick={onClick} />
      <NameWrap>
        <Name>{name}</Name>
        <Caption>{caption}</Caption>
      </NameWrap>
    </RowWrap>
  );
};

export const MenuAvatar = (props) => {
  const name = props.name;
  const caption = props.caption;
  const avatar = props.avatar;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <RowWrap>
      <StyledAvatar src={avatar} onClick={onClick} />
      <NameWrap>
        <Name>{name}</Name>
        <Caption>{caption}</Caption>
      </NameWrap>
    </RowWrap>
  );
};

export const CustomerAvatar = (props) => {
  const name = props.name;
  const caption = props.caption;
  const avatar = props.avatar;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <RowWrap>
      <StyledAvatarSmall src={avatar} onClick={onClick} />
      <NameWrap>
        <Name>{name}</Name>
        <Caption>{caption}</Caption>
      </NameWrap>
    </RowWrap>
  );
};

export const CouponAvatar= (props) => {
  const title = props.title;
  const avatar = props.avatar;
  return(
      <CustomizeDiv>
          <CouponRoundAvatar variant="rounded" src={avatar} >
              <PhotoIcon style={{width: '15px'}}/>
          </CouponRoundAvatar>
          <div>
          <CouponTitle variant="body1" gutterBottom>{title}</CouponTitle>
        </div>
      </CustomizeDiv>
  );
}

export const CouponMiddleAvatar= (props) => {
  const title = props.title;
  const avatar = props.avatar;
  return(
      <CouponMiddleDiv >
          <CouponMiddleRoundAvatar variant="rounded" src={avatar} >
              <PhotoIcon style={{width: '20px'}}/>
          </CouponMiddleRoundAvatar>
          <div>
          <CouponMiddleTitle variant="body1" gutterBottom>{title}</CouponMiddleTitle>
        </div>
      </CouponMiddleDiv>
  );
}




const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    flex-direction: row;
  }
`;

const Title = styled(Typography)`
  margin: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(2)}px 0;
  font-weight: 700;
  width: calc(240px - ${(props) => props.theme.spacing(2)}px * 3);
  &[data-required='true'] {
    &::after {
      content: '*';
      margin-left: ${(props) => props.theme.spacing(2)}px;
      font-size: 14px;
      color: red;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  margin: ${(props) => props.theme.spacing(2)}px;
  height: 80px;
  width: 80px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    height: 120px;
    width: 120px;
    border-radius: 16px;
  }
`;

const StyledAvatarSmall = styled(Avatar)`
  margin: ${(props) => props.theme.spacing(0)}px;
  height: 40px;
  width: 40px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    height: 80px;
    width: 80px;
    border-radius: 16px;
  }
`;

const RowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

const NameWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const Name = styled(Typography).attrs({
  variant: 'body1',
})`
  margin: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(2)}px 0;
  font-weight: 700;
`;

export const CircledAvatar = styled(Avatar)`
  margin: ${(props) => props.theme.spacing(1)}px;
`;

export const SmallAvatar = styled(Avatar)`
    width: ${props => props.theme.spacing(3)}px;
    height: ${props => props.theme.spacing(3)}px;
`
export const StyledMenuAvatar= styled(MenuAvatar)`
width: ${props => props.theme.spacing(3)}px;
height: ${props => props.theme.spacing(3)}px;
`

export const StyledCustomerAvatar= styled(CustomerAvatar)`
width: ${props => props.theme.spacing(3)}px;
height: ${props => props.theme.spacing(3)}px;
`

const RoundedAvatar = styled(Avatar)`
  margin: ${(props) => props.theme.spacing(2)}px;
  height: 80px;
  width: 80px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    height: 120px;
    width: 120px;
    border-radius: 16px;
  }
`;

const CheckLabel = styled.label`
  display: inline-block;
  height: auto;
  width: auto;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    border-radius: 16px;
  }
  position: relative;
`;
const Input = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const CouponRoundAvatar = styled(Avatar)`
margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1)}px  ${props => props.theme.spacing(2)}px;
background: #E1E2E5;
height: 30px;
width: 30px;
border-radius:50%;
`;

const CouponMiddleRoundAvatar = styled(CouponRoundAvatar)`
width: 50px;
height: 50px;
margin:8px;
`;

const CustomizeDiv = styled.div`
display:flex;
flex-wrap: wrap;
flex-direction:row;
`;

const CouponMiddleDiv = styled(CustomizeDiv)`
align-items: center;
margin-left: ${props => props.theme.spacing(2)}px;
margin-top: ${props => props.theme.spacing(1.5)}px;
`;

const Maintitle= styled(Typography)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px 0;
font-weight:700;
width:calc(240px - ${props => props.theme.spacing(2)}px * 4);
&[data-required="true"] {
    &::after{
      content:'*';
      margin-left:${props => props.theme.spacing(2)}px;
      font-size:14px;
      color:red;
    }
}
`;

const CouponTitle = styled(Maintitle)`
margin:${props => props.theme.spacing(1.5)}px ${props => props.theme.spacing(0.5)}px 0;
font-size: 13px;
width: 140px;
`;


const CouponMiddleTitle = styled(CouponTitle)`
margin:0;
`;





