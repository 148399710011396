import React from 'react';
import Content from '../organisms/Content';
import {PageTitle} from '../atoms/Title';

const EditContentPage = () => {
  return (
    <div>
        <PageTitle>コンテンツ詳細</PageTitle>
        <Content />
    </div>
  )
}
export default EditContentPage;