const moment = require('moment-timezone');

/**
 * @description 月の比較
 * @param {*} timestamp
 */
export const checkMonth = (timestamp) => {
  const today = moment();
  const check = moment(timestamp * 1000).isSame(today, 'month');
  return check;
}

export const checkYear = (timestamp, year) => {
  const check = moment(timestamp * 1000).isSame(`${year}-01-01`, 'year');
  return check;
}

export const getMonth = (timestamp) => {
  const month = moment(timestamp * 1000).month();
  return month;
}
