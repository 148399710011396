import React from 'react';
import SingleItem from '../organisms/SingleItem';
import {PageTitle} from '../atoms/Title';

const EditSingleItemPage = () => {
  return (
    <div>
       <PageTitle>イベント詳細</PageTitle>
      <SingleItem/>
    </div>
  )
}
export default EditSingleItemPage;