/** action-creator */
export const setFollowers= (value) => {
  return { type: 'SET_FOLLOWERS', value: value };
};
export const resetFollowers = (value) => {
  return { type: 'RESET_FOLLOWERS', value: value };
};

/** reducer */
const followers = (state = null, action) => {
    switch (action.type) {
      case 'SET_FOLLOWERS':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_FOLLOWERS':
            return [];
      default:
        return state
    }
  }
  export default followers;
