import React, { useEffect} from 'react';
import MyPageAvatar from '../molecules/MyPageAvatar';
import { useSelector } from 'react-redux';
import PremiumList from './PremiumList';
import SeminorList from './SeminorList';
import BackNumberList from './BackNumberList';
import FreeList from './FreeList';
import {CustomizedTabs as Tabs} from '../molecules/Tabs';
import { useHistory } from 'react-router-dom';
import { CONTENTS_CATEGORIES } from '../../static/constants';

const MyPageInfo = () => {
  const providerData = useSelector(state => state.providerData);
  const contents = useSelector(state => state.contents);
  const singleItems = useSelector(state => state.singleItems);
  const followers = useSelector(state => state.followers);
  const history = useHistory();
  const sourceTab = history.location.state?.tab

  const tabList = [
    { label: CONTENTS_CATEGORIES.FREE, content: <FreeList contents={contents}/> },
    { label: CONTENTS_CATEGORIES.MEMBERONLY, content: <PremiumList contents={contents}/>},
    { label: CONTENTS_CATEGORIES.BACKNUMBER, content: <BackNumberList contents={contents}/>},
    { label: CONTENTS_CATEGORIES.EVENTITEM, content: <SeminorList singleItems={singleItems} /> },
  ]
  return (
    <>
      <MyPageAvatar providerData={providerData} followers={followers}/>
      <Tabs contents={tabList} sourceTab={sourceTab}/>
    </>
  )
}
export default MyPageInfo;