import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CommentComponent from './CommentComponent';
import { CustomizedTabs as Tabs } from '../molecules/Tabs';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { StyledFullPaper } from '../atoms/Paper';
import { SimpleSwitch } from '../atoms/Switch';
import { commentFunctionStatus } from '../../static/checkData';
import Collapse from '@material-ui/core/Collapse';
import functionsCommon from '../../firebase/functions/functionsCommon';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import { UPDATE_COMMENT_STATUS_MESSAGE } from '../../static/messages';

const CommentList = () => {
  const comments = useSelector(state => state.comments);
  const providerData = useSelector(state => state.providerData);
  const shopInfo = providerData?.shopInfo;
  const profile = providerData?.profile;
  const currentCommentStatus = shopInfo?.commentStatus ? shopInfo?.commentStatus : false;
  const [commentStatus, setCommentStatus] = useState(currentCommentStatus);
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });
  const tabList = [
    { label: "未返信", content: <NotReplyList comments={comments} providerData={providerData} /> },
    { label: "返信済み", content: <RepliedList comments={comments} providerData={providerData} /> },
  ]

  useEffect(() => {
    if(currentCommentStatus){
      setCommentStatus(currentCommentStatus);
    }
  }, [currentCommentStatus]);

  const onChangeCommentStatus = useCallback(async(e)=>{
    setCommentStatus(e.target.checked);
    const  response = await functionsCommon.updateProfile('provider', {
      profile: profile,
      shopInfo: {
        ...shopInfo,
        commentStatus: e.target.checked
      }
    }).catch((err) => {
      setSnackState({
        open: true,
        message: UPDATE_COMMENT_STATUS_MESSAGE.FAILED,
        success: false
      });
    });
    if(!response){
      setSnackState({
        open: true,
        message: UPDATE_COMMENT_STATUS_MESSAGE.FAILED,
        success: false
      });
      return;
    } else {
      setSnackState({
        open: true,
        message: UPDATE_COMMENT_STATUS_MESSAGE.SUCCESS,
        success: true
      });
    }
  },[shopInfo]);

  return (
    <>
      <StyledFullPaper>
        <SimpleSwitch
          title='コメント機能を利用する'
          array={commentFunctionStatus}
          checked={commentStatus}
          onChange={onChangeCommentStatus}
          helperText={commentStatus ? '動画コンテンツへのコメントが可能な状態です' : '動画コンテンツへのコメントができない状態です'}
        />
      </StyledFullPaper>
      <Collapse in={commentStatus}>
        <CustomizedFullPaper>
          <Tabs contents={tabList} />
        </CustomizedFullPaper>
      </Collapse>
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
    </>
  )
}

export default CommentList;

// 未返信
const NotReplyList = (props) => {
  const { comments, providerData } = props;
  return (
    <>
      {comments ? comments.length > 0 ? comments.map((comment) => {
        return !comment?.repliedFlag ?
          <CommentComponent
            key={comment.commentId}
            comment={comment}
            providerData={providerData}
          /> :
          null
      }) : <p>コメントはありません</p> : <Wrap><CircularProgress size={20} /></Wrap>}
    </>
  )
}

// 返信済み
const RepliedList = (props) => {
  const { comments, providerData } = props;
  return (
    <>
      {comments ? comments.length > 0 ? comments.map((comment) => {
        // 自分がreplyを返しているかも追加
        return comment?.repliedFlag ?
          <CommentComponent
            key={comments.commentId}
            comment={comment}
            providerData={providerData}
          /> :
          null
      }) : <p>コメントはありません</p> : <Wrap><CircularProgress size={20} /></Wrap>}
    </>
  )
}

const Wrap = styled.div`
text-align: center
`;

const CustomizedFullPaper = styled(StyledFullPaper)`
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  padding-bottom: ${props => props.theme.spacing(5)}px;
}
`;