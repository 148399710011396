import React from "react";
import FollowerItem from "../molecules/FollowerItem";
import { StyledHalfFlexWrap } from "../atoms/Paper";
import { useSelector } from "react-redux";

const FollowerList = () => {
  const list = [1, 2, 3, 4, 5, 6];
  const followers = useSelector((state) => state.followers);
  return (
    <StyledHalfFlexWrap>
      {followers ? (
        followers.length > 0 ? (
          followers.map((follower, index) => (
            <FollowerItem consumerId={follower.consumerId} key={index} />
          ))
        ) : (
          <>現在フォローワーはいません</>
        )
      ) : (
        list.map((doc, index) => <FollowerItem consumerId={null} key={index} />)
      )}
    </StyledHalfFlexWrap>
  );
};
export default FollowerList;
