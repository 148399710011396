import React from "react";
import ContentCard from "../molecules/ContentCard";
import AddContentCard from "../molecules/AddContentCard";
import { StyledFlexWrap } from "../atoms/Paper";
import styled from "styled-components";
import checkBackNumberStatus from "../../utils/checkBackNumberStatus";

const list = [1, 2, 3, 4, 5, 6, 7, 8];

const PremiumList = (props) => {
  const { contents } = props;
  let preminumList = contents
    ? contents.filter(
        (doc) =>
          doc.data.free === false &&
          (doc.data?.backNumberStatus !== true ||
            (doc.data?.backNumberStatus === true &&
              checkBackNumberStatus(
                doc.data?.limitedPeriod,
                doc.data?.createAt.seconds
              ) > 0))
      )
    : [];
  preminumList = preminumList.sort((a, b) => {
    const aUpdateAt = a.data.creatAt ? a.data.creatAt.seconds : null;
    const bUpdateAt = b.data.creatAt ? b.data.creatAt.seconds : null;
    if (aUpdateAt > bUpdateAt) return -1;
    else return 1;
  });
  return (
    <CustomizeFlexWrap>
      {!contents ? (
        <>
          {list.map((doc, index) => (
            <ContentCard
              index={index}
              key={index}
              contentData={null}
              type={"video"}
            />
          ))}
        </>
      ) : (
        <>
          <AddContentCard type={"video"} tab={"会員限定"} />
          {preminumList.map((doc, index) => (
            <ContentCard
              index={index}
              key={index}
              contentData={doc}
              type={"video"}
            />
          ))}
        </>
      )}
    </CustomizeFlexWrap>
  );
};
export default PremiumList;

const CustomizeFlexWrap = styled(StyledFlexWrap)`
  min-height: 60vh;
`;
