import React from 'react'
import { Select, createMuiTheme } from '@material-ui/core';
import { SimpleGrid } from '../atoms/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

const formLabelsTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#c4c4c4"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});

export const CSelectBox = (props) => {

  const {
    setFunction,
    title,
    value,
    array
  } = props;

  const onChange = (e) => {
    var data = e.target.value
    setFunction(data);
  };

  return (
    <SimpleGrid item xs={props.size}>
      <FormControl variant="outlined">
        <InputLabel>{title}</InputLabel>
        <Select
          label={title}
          value={value}
          onChange={onChange}
          native
          variant="outlined"
          style={{ width: '45vw' }}
        >
          {array && array.map((data, index) => {
            return <option key={index} value={data}>{data}</option>
          })}
        </Select>
      </FormControl>
    </SimpleGrid>
  )
}

export const CObjectSelectBox = (props) => {
  const {
    setFunction,
    title,
    value,
    array,
    required
  } = props;
  const onChange = (e) => {
    var data = e.target.value
    setFunction(data);
  };
  return (
    <SimpleGrid item xs={props.size}>
      <MuiThemeProvider theme={formLabelsTheme}>
        <form noValidate autoComplete="off">
          <FormControl required={required} variant="outlined">
            <InputLabel style={{ fontSize: '18px', fontWeight: 'bold', color: '#000' }} >{title}</InputLabel>
            <Select
              label={title}
              value={value}
              onChange={onChange}
              native
              variant="outlined"
              style={{ width: '45vw' }}
            >
              {array && array.map((data, index) => {
                return <option key={index} value={data.value}>{data.label}</option>
              })}
            </Select>
          </FormControl>
        </form>
      </MuiThemeProvider>
    </SimpleGrid>
  )
}

export const CFullObjectSelectBox = (props) => {
  const {
    setFunction,
    title,
    value,
    array,
    required
  } = props;
  const onChange = (e) => {
    const data = e.target.value
    setFunction(data);
  };
  return (
    <SimpleGrid item xs={props.size}>
      <MuiThemeProvider theme={formLabelsTheme}>
        <form noValidate autoComplete="off">
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel required={required} style={{ fontSize: '18px', fontWeight: 'bold', color: '#000' }} >{title}</InputLabel>
            <Select
              label={title}
              value={value}
              onChange={onChange}
              native
              variant="outlined"
              style={{ width: '100%' }}
            >
              {array && array.map((data, index) => {
                return <option key={index} value={data.value}>{data.label}</option>
              })}
            </Select>
          </FormControl>
        </form>
      </MuiThemeProvider>
    </SimpleGrid>
  )
}

