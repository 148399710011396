import React from "react";
import styled from "styled-components";
import ContentCard from "../molecules/ContentCard";
import AddContentCard from "../molecules/AddContentCard";
import { StyledFlexWrap } from "../atoms/Paper";

const list = [1, 2, 3, 4, 5, 6, 7, 8];
const FreeList = (props) => {
  const { contents } = props;
  let freeList = contents
    ? contents.filter((doc) => doc.data.free === true)
    : [];
  freeList = freeList.sort((a, b) => {
    const aUpdateAt = a.data.creatAt ? a.data.creatAt.seconds : null;
    const bUpdateAt = b.data.creatAt ? b.data.creatAt.seconds : null;
    if (aUpdateAt > bUpdateAt) return -1;
    else return 1;
  });
  return (
    <>
      <CustomizeFlexWrap>
        {!contents ? (
          <>
            {list.map((doc, index) => (
              <ContentCard
                index={index}
                key={index}
                contentData={null}
                type={"video"}
              />
            ))}
          </>
        ) : (
          <>
            <AddContentCard type={"video"} />
            {freeList.map((doc, index) => (
              <ContentCard
                index={index}
                key={index}
                contentData={doc}
                type={"video"}
              />
            ))}
          </>
        )}
      </CustomizeFlexWrap>
    </>
  );
};

export default FreeList;

const CustomizeFlexWrap = styled(StyledFlexWrap)`
  min-height: 60vh;
`;
