import React from 'react';
import Page from '../templates/Page'

const ContentsPage = () => {
  return (
    <Page title="コンテンツページ">
        コンテンツページ
    </Page>
  )
}
export default ContentsPage;