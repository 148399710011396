import React, { useEffect, useState } from 'react';
import { lineLogin } from '../../firebase/auth/lineLogin'
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import AuthPage from './AuthPage';
import { WithLineIconButton } from '../atoms/Button';
import { StyledFullPaper } from '../atoms/Paper';
import { useSelector } from 'react-redux';
import { CenteredPaper } from '../atoms/Paper';
import { LogoTitle } from '../atoms/Title';
import { TermText } from '../atoms/Text';
import { privacyPolicyUrl, termsUrl } from '../../static/data';
import { CookonTitle } from '../../icons/icon';
import liff from '@line/liff';
import { liffId } from '../../config';

const LoginPage = () => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const logined = useSelector(state => state.logined);
  const liffUrlQueryParam = useSelector(state => state.liffUrlQueryParam);
  const [local_liffUrlQueryParam, local_setliffUrlQueryParam] = useState(null);

  useEffect(() => {
    if (logined) {
      history.push('/my')
    }
  }, [logined])
  useEffect(() => {
    liff.init({ liffId: liffId });
    local_setliffUrlQueryParam(liffUrlQueryParam)
  }, [liffUrlQueryParam])
  const redirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      {pathname === '/auth' ? <AuthPage /> :
        <CustomizeFullPaper>
          <CenteredPaper>
            <FlexDiv>
              <CookonTitle />
            </FlexDiv>
            <PageTitle>新規登録・ログイン</PageTitle>
            <WithLineIconButton
              onClick={() => lineLogin(window.location.pathname, local_liffUrlQueryParam)}
            >
              {'LINEでログイン'}
            </WithLineIconButton >
          </CenteredPaper>
          <TermText>
            <Atag onClick={() => redirect(termsUrl)}>{'利用規約'}</Atag>{`と`}<Atag onClick={() => redirect(privacyPolicyUrl)}>{'個人情報保護方針'}</Atag> {`に同意の上ご利用ください。`}
          </TermText>
        </CustomizeFullPaper>}
    </>
  )
}
export default LoginPage;

const CustomizeFullPaper = styled(StyledFullPaper)`
box-shadow: none;
text-align:center;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 100vw;
  min-height: 100vh;
  margin-left: -240px;
  text-align: center;
  margin-top: -16px;
  }
background: white;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:0;
  width:100%;
}
`
const PageTitle = styled(Typography).attrs({
  varient: 'h3',
})`
  font-weight:bold;
  font-family: Arial;
  margin-bottom: 22px;
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px){
    margin-bottom: 83px;
    margin-top: 31px;
    font-size:20px
  }
`;

const Atag = styled.a`
color: #2E7DF6;
cursor: pointer;
`;

const FlexDiv = styled.div`
margin:${props => props.theme.spacing(8)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(9)}px ${props => props.theme.spacing(2)}px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
    margin:${props => props.theme.spacing(8)}px ${props => props.theme.spacing(2)}px 0;
    margin-bottom: ${props => props.theme.spacing(15)}px;
  }
`;