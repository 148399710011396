import React, { useEffect } from 'react';
import Hls from 'hls.js';

const HLSSource = (props, context) => {
  const hls = new Hls();
  const { src, video, poster } = props;
  const typeHls = src? src.match('.m3u8') : null;
  const typeMp4 = src? src.match('.mp4') : null;

  useEffect(() => {
    if (Hls.isSupported()) {
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
      });
    }
  }, [])
  // `src` is the property get from this component
  // `video` is the property insert from `Video` component
  // `video` is the html5 video element

  // load hls video source base on hls.js

  /*
 componentWillUnmount() {
   // destroy hls video source
   if (this.hls) {
     this.hls.destroy();
   }
 }*/
  return (
    <>
    <source
      poster={poster}
      src={props.src}
      type={props.type || 'application/x-mpegURL'}
    />
    </>
  );
}

export default HLSSource;