import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { BasicButton, WhiteButton } from '../atoms/Button';
import { DeleteDialog } from '../atoms/Dialog';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import functionsProviders from '../../firebase/functions/functionsProviders';
import { useHistory } from 'react-router-dom';
import { ErrorAlert } from '../atoms/Alert';

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: '0 3pt 6pt #00000029',
    marginBottom: '3.2vw'
  },
}))

const DeleteDialogComponent = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { open, setOpen, free } = props;
  const contentId = props.contentId;
  const [disabled, setDisabled] = useState(false);
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });

  const onCloseModal = () => {
    setOpen(false);
  }
  const onDeleteContent = async () => {
    setDisabled(true)
    const res = await functionsProviders.deleteContent(contentId).catch((err) => {
      setSnackState({
        open: true,
        message: 'コンテンツの削除に失敗しました',
        success: false
      })
      setDisabled(false)
    })
    if (res) {
      setSnackState({
        open: true,
        message: 'コンテンツの削除に成功しました',
        success: true
      });
      setDisabled(false);
      setTimeout(() => {
        history.push('/my')
      }, 1000);
    } else {
      setSnackState({
        open: true,
        message: 'コンテンツの削除に失敗しました',
        success: false
      })
      setDisabled(false)
    }
  }
  return (
    <>
      <DeleteDialog open={open} setOpen={setOpen} disabled={disabled}>
        <Content>
          <Title>{disabled ? "削除中..." : "コンテツを削除しますか？"}</Title>
          {!free ? <ErrorAlert>削除すると本コンテンツを購入済みのユーザも閲覧できなくなります</ErrorAlert> : null}
          <BasicButton disabled={disabled} onClick={() => onDeleteContent()}>{"削除"}</BasicButton>
          <WhiteButton className={classes.button} disabled={disabled} onClick={() => onCloseModal()}>{"キャンセル"}</WhiteButton>
        </Content>
      </DeleteDialog>
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
    </>
  )
}
export default DeleteDialogComponent;


const Content = styled.div`
width: 100%;
height: 100%;
position: relative;
text-align:center;
`
export const Title = styled(Typography).attrs({
  varient: 'h3',
})`
  font-size: 1.4rem;
  font-weight:700;
  margin:${props => props.theme.spacing(3)}px ${props => props.theme.spacing(2)}px;
  vertical-align: top;
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
      font-size: 18px;
}
`