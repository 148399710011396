/** action-creator */
export const setHistory= (value) => {
  return { type: 'SET_HISTORY', value: value };
};
export const resetHistory = (value) => {
  return { type: 'RESET_HISTORY', value: value };
};

/** reducer */
const history = (state = null, action) => {
    switch (action.type) {
      case 'SET_HISTORY':
          var newArray = action.value.slice();
          return newArray;
      case 'RESET_HISTORY':
            return [];
      default:
        return state
    }
  }
  export default history;
