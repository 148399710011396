import React from 'react';
import { PageTitle } from '../atoms/Title';
import { StyledFullPaper } from '../atoms/Paper';
import  EditCoupon from '../organisms/EditCoupon';
import CouponDetail from '../organisms/CouponDetail';

const CouponDetailPage = () => {
  return (
    <>
      <PageTitle>クーポン情報詳細</PageTitle>
      <StyledFullPaper>
        <CouponDetail/>
      </StyledFullPaper>
    </>
  )
}
export default CouponDetailPage;