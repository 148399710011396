import React from 'react';
import Page from '../templates/Page';
import MyPageInfo from '../organisms/MyPageInfo';
import {StyledFullPaper} from '../atoms/Paper';
import {PageTitle} from '../atoms/Title';

const MyPage = () => {
  return (
    <div>
     <PageTitle>マイメニュー</PageTitle>
      <StyledFullPaper>
        <MyPageInfo/>
      </StyledFullPaper>
    </div>
  )
}
export default MyPage;