import { useCallback, useEffect } from 'react';
import * as firebase from 'firebase';
import { useDispatch } from "react-redux";
import { login, logout } from '../redux/action_reducers/logined';
import { firestoreProviders } from './firestore/firestoreProviders';
import { setProviderData, resetProviderData } from '../redux/action_reducers/providerData';
import { setContents, resetContents } from '../redux/action_reducers/contents';
import { setCustomerId, resetCustomerId} from '../redux/action_reducers/customerId';
import { setSingleItems, resetSingleItems } from '../redux/action_reducers/singleItems';
import { setContract,resetContract } from '../redux/action_reducers/contract';
import { firestoreDefinitions } from '../firebase/firestore/firestoreDefinitions';
import { setPlanList, resetPlanList } from '../redux/action_reducers/planList';
import { firestoreOrders } from './firestore/firestoreOrders';
import { setHistory, resetHistory } from '../redux/action_reducers/history';
import { setStripeInfo } from '../redux/action_reducers/stripeInfo';
import { setFollowers, resetFollowers } from '../redux/action_reducers/followers';
import { resetCoupons, setCoupons } from '../redux/action_reducers/coupons';
import { setComments } from '../redux/action_reducers/comments';


const FirebaseToRedux = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    observeAuth();
  }, []);

  const observeProfile = useCallback((uid) => {
    const profileDocumentRef = firestoreProviders.getRef(uid);
    const hiddenProfileDocumentRef = firestoreProviders.getHiddenProfileRef(uid);
    profileDocumentRef.onSnapshot((doc) => {
      hiddenProfileDocumentRef.onSnapshot((hiddenDoc)=>{
        dispatch(setProviderData({
          uid: uid,
          shopInfo: doc.data() ? doc.data() : null,
          profile: hiddenDoc.data() ? hiddenDoc.data() : null
        }))
      })
    })
  }, []);

  const observeStripeInfo = useCallback((uid) => {
    const stripeId_ref = firestoreProviders.getRef(uid).collection('hidden').doc('stripeInfo');
    stripeId_ref.onSnapshot(async (doc) => {
      if (doc.exists) {
        if (doc.data()) {
          dispatch(setStripeInfo({
            identificationStatus: doc.data()?.identificationStatus,
            last4: doc.data()?.last4 ? doc.data()?.last4 : null,
            customerId: doc.data()?.customerId ? true : false,
            cardLast4: doc.data()?.cardLast4 ? doc.data()?.cardLast4  : null,
            brand: doc.data()?.brand ? doc.data()?.brand  : null,
          }));
        } else {
          dispatch(resetCustomerId(false));
        }
      } else {
        dispatch(resetCustomerId(false));
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    })
  }, []);

  const setContentsData = async(uid) => {
    const contentRef = firestoreProviders.getContentRef(uid).orderBy("createAt", "desc");
    contentRef.onSnapshot((querySnapshot) => {
      const list = []
      if (querySnapshot.empty) {
        dispatch(resetContents())
      }
      querySnapshot.forEach(async(doc)=>{
          // 追加する場合 (すでにある場合ものは追加しない)
          list.push({
          id: doc.id,
          data: doc.data()
        })
      dispatch(setContents(list))
      });
    })
  }

  const setSingleItemData = async(uid) => {
    if(!uid) return;
    const singleItemRef = firestoreProviders.getSingleItemRef(uid);
    singleItemRef.onSnapshot((querySnapshot) => {
      const list = []
      if (querySnapshot.empty) {
        dispatch(resetSingleItems())
      }
      querySnapshot.forEach(async(doc)=>{
          // 追加する場合 (すでにある場合ものは追加しない)
          list.push({
          id: doc.id,
          data: doc.data()
        })
      dispatch(setSingleItems(list))
      });
    })
  }
  const observeContract = useCallback((uid) =>{
    const query = firestoreProviders.getContractRef(uid);
    query.onSnapshot(async (doc) => {
      if (doc.exists) {
        dispatch(setContract(doc.data()))
      } else {
        dispatch(resetContract(false));
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    })
  }, []);

  const observeDefinitions = useCallback(()=>{
    const query = firestoreDefinitions.getRef();
    query.onSnapshot(async (doc) => {
      if (doc.exists) {
        dispatch(setPlanList(doc.data()))
      } else {
        dispatch(resetPlanList());
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    })
  }, []);

  const observeHistory = useCallback((uid) => {
    const query = firestoreOrders.getCollectionRef().where("providerRef", "==", firestoreProviders.getRef(uid));
    query.onSnapshot(async (querySnapshot) => {
      const historyList = []
      if (querySnapshot.empty) {
        dispatch(resetHistory())
      }
      querySnapshot.forEach(async(doc)=>{
          // 追加する場合 (すでにある場合ものは追加しない)
          historyList.push({
          orderId: doc.id,
          data: doc.data()
        })
      });
      dispatch(setHistory(historyList))
    }, err => {
      console.log(`Encountered error: ${err}`);
    })
  }, []);

  const observeFollowers =  useCallback((uid) => {
    const query = firestoreProviders.getFollwersRef(uid)
    query.onSnapshot(async (querySnapshot) => {
      const followersList = []
      if (querySnapshot.empty) {
        dispatch(resetFollowers())
      }
      querySnapshot.forEach(async(doc)=>{
          // 追加する場合 (すでにある場合ものは追加しない)
          followersList.push({
          consumerId: doc.id,
          data: doc.data()
        })
      });
      dispatch(setFollowers(followersList))
    }, err => {
      console.log(`Encountered error: ${err}`);
    })
  }, []);

  const observeCoupons = useCallback((uid) => {
    const query =  firestoreProviders.getCouponsRef(uid);
    query.onSnapshot(async (querySnapshot) => {
      const couponList = []
      if (querySnapshot.empty) {
        dispatch(resetCoupons())
      }
      querySnapshot.forEach(async(doc)=>{
          // 追加する場合 (すでにある場合ものは追加しない)
          couponList.push({
          couponId: doc.id,
          data: doc.data()
        })
      });
      dispatch(setCoupons(couponList))
    }, err => {
      console.log(`Encountered error: ${err}`);
    })
  }, []);

  const observeComments = useCallback(async(uid)=>{
    const sortedCommentList = await firestoreProviders.getCommentsData(uid);
    dispatch(setComments(sortedCommentList));
  },[]);


  const observeAuth = useCallback(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        dispatch(login(null));
        observeProfile(user.uid);
        setContentsData(user.uid);
        setSingleItemData(user.uid);
        observeStripeInfo(user.uid);
        observeContract(user.uid);
        observeHistory(user.uid);
        observeFollowers(user.uid);
        observeCoupons(user.uid);
        observeDefinitions();
        observeComments(user.uid);
      } else {
        dispatch(logout());
        resetProviderData();
        dispatch(resetCustomerId());
      }
    });
  }, []);


  return (null);
};

export default FirebaseToRedux;

