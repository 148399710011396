import * as firebase from 'firebase';
import { functions } from '../index'
import { redirect_uri } from '../../config';

export const customeTokenLogin = async (code) => {
  const getLoginToken = functions.httpsCallable('onCall-getLoginToken');
  const response = await getLoginToken({code:code,type:'provider',redirect_uri: redirect_uri}).catch(err => {
    console.log("tokenが取得できませんでした");
    throw err;
  });
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  await firebase.auth().signInWithCustomToken(response.data).catch(err => {
    console.log("ログインに失敗しました");
    throw err;
  });
}
