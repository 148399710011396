import React, { useEffect, useState } from 'react';
import { BasicButton } from '../atoms/Button';
import styled from 'styled-components';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import { useSelector } from 'react-redux';
import functionsProviderOrders from '../../firebase/functions/functionsProviderOrders';
import { firestoreDefinitions } from '../../firebase/firestore/firestoreDefinitions';
import { useHistory } from 'react-router-dom';
import { ErrorAlert } from '../atoms/Alert';
import { SelectInput } from '../atoms/SelectInput';
import { StyledFullPaper } from '../atoms/Paper';
import { UPDATE_CONTRACT_MESSAGE, ALERT_FOR_CONTRACT } from '../../static/messages';

const list = [
  { value: 'free', price: 0, label: 'フリー 容量5GB ¥0/月' },
  { value: 'light', price: 1000, label: 'ライト 20GB ¥1,000/月' },
  { value: 'basic', price: 2500, label: 'ベーシック 50GB ¥2,500/月' },
  { value: 'premium', price: 5000, label: 'プレミアム 100GB ¥5,000/月' },
  { value: 'premium plus', price: 10000, label: 'プレミアムプラス 200GB ¥10,000/月' }
]

const Contract = () => {
  const history = useHistory();
  const contract = useSelector(state => state.contract);
  const stripeInfo = useSelector(state => state.stripeInfo);
  const customerId = stripeInfo ? stripeInfo.customerId : null;
  const currentPlan = contract ? contract.plan : null;
  const [plan, setPlan] = useState(list[0].value);
  const [disabled, setDisabled] = useState(false);
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });

  useEffect(() => {
    if (currentPlan) {
      setPlan(currentPlan)
    }
  }, [currentPlan])

  useEffect(() => {
    const getDefinition = async () => {
      const res = await firestoreDefinitions.getData();
    }
    getDefinition();
  }, [])

  const onChangeContract = async () => {
    if(!customerId){
      setSnackState({
        open: true,
        message: ALERT_FOR_CONTRACT.PAYMENT,
        success: false
      })
      setTimeout(() => {
        history.push('/config')
      }, 2000);
      return
    }
    if (!plan) {
      setSnackState({
        open: true,
        message: ALERT_FOR_CONTRACT.PLAN,
        success: false
      })
      return;
    }
    if (currentPlan === plan) {
      setSnackState({
        open: true,
        message: ALERT_FOR_CONTRACT.NOT_CURRENT_PLAN,
        success: false
      })
      return;
    }
    setDisabled(true);
    const res = await functionsProviderOrders.makeProviderOrder(plan).catch((err) => {
      setDisabled(false);
      setSnackState({
        open: true,
        message: UPDATE_CONTRACT_MESSAGE.FAILED,
        success: false
      })
    })
    if (res) {
      setDisabled(false);
      setSnackState({
        open: true,
        message: UPDATE_CONTRACT_MESSAGE.SUCCESS,
        success: true
      });
      setTimeout(() => {
        history.push('/config')
      }, 2000);
    } else {
      setDisabled(false);
      setSnackState({
        open: true,
        message: UPDATE_CONTRACT_MESSAGE.FAILED,
        success: false
      })
    }
  }

  return (
    <StyledFullPaper>
      <ErrorAlert>{'有料契約に変更した場合、初月は契約申込日からの日割り換算で請求されます。'}</ErrorAlert>
           <SelectInput
            items={list}
            title={'ご契約中のプラン'}
            required={true}
            value={plan}
            onChange={(e)=>setPlan(e.target.value)}
            helperText={'ランクのグレードを月途中で落とす場合は、追加料金は発生しません。月末まで既存のランクが保持され、月末にランクが切り替わります'}
          />
        <BasicButton
          disabled={disabled}
          onClick={() => onChangeContract()}
        >
        {disabled ? '変更中...' : '契約を変更する'}
      </BasicButton>
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
    </StyledFullPaper>
  )
}

export default Contract;

const ButtonWrap = styled.div`

width:100%;
text-align: center;
margin-top:70px;
margin-bottom: 180px;
@media (max-width: 1200px){
  margin-top:0vw;
  margin-bottom: 25vw;
}
`;