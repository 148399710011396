import React,{useState , useEffect} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import styled from 'styled-components';
import MovieIcon from '@material-ui/icons/Movie';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import TextsmsIcon from '@material-ui/icons/Textsms';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router-dom';
import { formRunUrl } from '../../config';

const FooterMenu = (props) =>{
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const pathname = history.location.pathname;
  const path = pathname.replace('/', '');
  useEffect(()=>{
    if(path)
    setValue(path)
  }, [path])

  const handleChange = (event, newValue) => {
      switch (newValue) {
        case 'my':
          history.push('/my');
          setValue(newValue);
          break;
        case 'accounting':
          history.push('/accounting');
          setValue(newValue);
          break;
        case 'contact':
          window.alert('外部のサイトが起動します');
          window.open(formRunUrl, '_blank');
          break;
        case 'config':
          history.push('/config');
          setValue(newValue);
          break;
      }
  };
  return (
    <StyledBottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
    >
      <StyledBottomNavigationAction label="メニュー"  value="my" icon={<MovieIcon />} />
      <StyledBottomNavigationAction label="売上管理"  value="accounting" icon={<InsertChartIcon />} />
      <StyledBottomNavigationAction label="お問合せ" value="contact" icon={<TextsmsIcon />} />
      <StyledBottomNavigationAction label="設定" value="config" icon={<AccountCircleIcon />} />
    </StyledBottomNavigation>
  )
}

export default FooterMenu;

const StyledBottomNavigation = styled(BottomNavigation)`
width: 100vw;
font-size: 8px;
height: 60px;
padding-bottom: env(safe-area-inset-bottom);
position: fixed;
z-index: 999;
bottom: 0;
touch-action: none;
`;

const  StyledBottomNavigationAction = styled(BottomNavigationAction)`
font-weight: 700;
font-size: 8px;
touch-action: none;
&:action {
  background-color: #00B900;
  opacity: 0.7;

};
.MuiBottomNavigationAction-label{
  font-size: 8px;
}
`