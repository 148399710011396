import React from 'react';
import styled from 'styled-components';
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import HLSSource from '../molecules/hlsComponent';
import Skeleton from '@material-ui/lab/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const VideoComponent = ({ contentData }) => {
  const videoUrl = contentData ? contentData.videoUrl : null;
  const thumbUrl = contentData ? contentData.thumbUrl : null;
  const typeHls = videoUrl ? videoUrl.match('.m3u8') : null;
  const typeMp4 = videoUrl ? videoUrl.match('.mp4') : null;
  const typeMp3 = videoUrl ? videoUrl.match('.mp3') : null;
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <Div>
      {contentData ? typeHls ? <ContentImg>
        <StyledPlayer
          playsInline
          fluid={false}
          width={matches ?  '100%' : '100vw'}
          height={matches ? 426.38: 260}
          poster={thumbUrl}
          controls
        >
          <BigPlayButton position="center" />
          <HLSSource
            isVideoChild
            poster={thumbUrl}
            src={videoUrl}
          />
        </StyledPlayer></ContentImg> :
        <ContentImg>
        <StyledPlayer
          playsInline
          fluid={false}
          width={matches ? '100%' : '100vw'}
          height={matches ? 426.38: 260}
          poster={thumbUrl}
          controls
        >
          <BigPlayButton position="center" />
          {typeMp4 && <source src={videoUrl} type="video/mp4" />}
          {typeMp3 && <source src={videoUrl} type="video/mp3" /> }
        </StyledPlayer></ContentImg>:
        <Skeleton/>}
    </Div>
  )
}

export default VideoComponent;

const ContentImg = styled.div`
width: 100%;
height: 100%;
`;

const Div = styled.div`
background: ${props => props.theme.palette.gray.gray01};
color: #BABAB9;
text-align: center;
width: 100vw;
height: 260px;
box-shadow: none;
*:focus {
  outline: none;
}
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:0  ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
  width: calc(100% - ${props => props.theme.spacing(2)}px *2);
  height: 100%;
}
`;
const StyledPlayer = styled(Player)`
width: 100%;
height: 260px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 650px;
  height: 426.38px;
}

`
