import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import styled from 'styled-components';
import { checkValidation } from '../molecules/CTextField';
import { BasicButton } from '../atoms/Button';
import uploadFile from '../../firebase/storage/uploadFile';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import functionsProviders from '../../firebase/functions/functionsProviders';
import { firestoreProviders } from '../../firebase/firestore/firestoreProviders';
import DeleteSingleItemDialog from '../molecules/DeleteSingleItemDialog';
import { EditableThumbnail } from '../atoms/Avatar';
import { SimpleSwitch } from '../atoms/Switch';
import { publishOption, priceOption } from '../../static/checkData';
import { UploadDottedButton, DeleteContentButton, WhiteButton } from '../atoms/Button';
import { SingleTextInput, MultiLineTextInput, ChipTextInput } from '../atoms/TextInput';
import { StyledFullPaper } from '../atoms/Paper';
import Collapse from '@material-ui/core/Collapse';
import { UPLOADE_CONTENT, STORAGE_UPLOAD_MESSAGE } from '../../static/messages';

const SingleItem = () => {
  const history = useHistory();
  const thumbnailRef = useRef();
  const materialRef = useRef();
  const path = history.location.pathname.replace('/edit-singleItem', '');
  const [contentId, setContentId] = useState(null)
  const providerData = useSelector(state => state.providerData);
  const shopInfo = providerData ? providerData.shopInfo : null;
  const providerStatus = shopInfo ? shopInfo.status : null;
  const currentUsage = shopInfo ? shopInfo.currentUsage : 0;
  const contract = useSelector(state => state.contract);
  const planList = useSelector(state => state.planList);
  const plan = contract ? contract.plan : '';
  const currentPlanInfo = planList ? planList[plan] : null;
  const currentPlanCapa = currentPlanInfo ? Number(currentPlanInfo.capacity) * 1000000000 : 0;
  const userId = providerData ? providerData.uid : null;
  const [title, setTitle] = useState('');
  const [forFreeDescription, setForFreeDescription] = useState('');
  const [thumbUrl, setThumbUrl] = useState(null);
  const [disabledThumb, setDisabledThumb] = useState(false);
  const [tag, setTag] = useState([]);
  const [forFreeUserPrice, setForFreeUserPrice] = useState(0);
  const [forSubscriberPrice, setForSubscriberPrice] = useState(0);
  const [tmpForFreeUserPrice, setTmpForFreeUserPrice] = useState(0);
  const [tmpForSubscriberPrice, setTmpForSubscriberPrice] = useState(0);
  const [forSubscriptionDescription, setForSubscriptionDescription] = useState('');
  const [published, setPublished] = useState(false);
  const [free, setFree] = useState(true);
  const [disabledFile, setDisabledFile] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileDescription, setFileDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [contentExist, setContentExist] = useState(false);
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });
  /**contentIdをセットする */
  useEffect(() => {
    if (path) {
      setContentId(path.replace('/', ''))
      setContentExist(true); // 削除ボタンの有効化
    } else {
      if (userId) {
        setContentId(userId + moment())
      }
    }
  }, [userId]);

  useEffect(() => {
    getContentData(userId, contentId);
  }, [userId, contentId]);

  useEffect(() => {
    if (free) {
      setForFreeUserPrice(0);
      setForSubscriberPrice(0);
    } else {
      setForFreeUserPrice(tmpForFreeUserPrice);
      setForSubscriberPrice(tmpForSubscriberPrice);
    }
  }, [free]);

  /** コンテンツの読み込み */
  const getContentData = async (userId, contentId) => {
    if (!(userId && contentId)) return;
    const contentData = await firestoreProviders.getSingleItemData(userId, contentId).catch((err) => {
      throw err;
    });
    if (contentData) {
      setTitle(contentData ? contentData.title : null);
      setThumbUrl(contentData ? contentData.thumbUrl ? contentData.thumbUrl : null : null);
      setDisabledThumb(contentData ? contentData.disabledThumb : null);
      setForFreeUserPrice(contentData ? contentData.price ? contentData.price.forFreeUserPrice ? contentData.price.forFreeUserPrice : 0 : 0 : 0);
      setTmpForFreeUserPrice(contentData ? contentData.price ? contentData.price.forFreeUserPrice ? contentData.price.forFreeUserPrice : 0 : 0 : 0);
      setForSubscriberPrice(contentData ? contentData.price ? contentData.price.forSubscriberPrice ? contentData.price.forSubscriberPrice : 0 : 0 : 0);
      setTmpForSubscriberPrice(contentData ? contentData.price ? contentData.price.forSubscriberPrice ? contentData.price.forSubscriberPrice : 0 : 0 : 0);
      setForFreeDescription(contentData.description.forFreeDescription);
      setForSubscriptionDescription(contentData ? contentData.description.forSubscriptionDescription ? contentData.description.forSubscriptionDescription : null : null);
      setPublished(contentData.published);
      setFree(contentData.free);
      setTag(contentData ? contentData.tag ? contentData.tag : [] : []);
      setFileDescription(contentData ? contentData.fileDescription : null);
      setFileName(contentData ? contentData.fileName : null);
      setFileUrl(contentData ? contentData.fileUrl : null);
    }
  }

  /**サムネイルアップロード用 */
  const onUploadThumbnail = async (e) => {
    let imageUrl = '';
    let file = e.target.files[0];
    const fileType = file ? file.type : null;
    if (!(fileType.endsWith('jpg') || fileType.endsWith('jpeg') || fileType.endsWith('png')) || !fileType) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.NOT_APPROPRIATE_FILE_TYPE,
        success: false
      })
      return;
    }
    const size = file ? file.size : 0;
    if (size + currentUsage > currentPlanCapa) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.ALEADY_FULL,
        success: false
      })
      return;
    }
    setDisabledThumb(true);
    imageUrl = await uploadFile(userId, e, `singleItem/${contentId}`).catch((err) => {
      setDisabledThumb(false);
    });
    setDisabledThumb(false);
    setThumbUrl(imageUrl)
  }

  /**添付ファイルアップロード用 */
  const onUploadFile = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    const size = file ? file.size : 0;
    if (size + currentUsage > currentPlanCapa) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.ALEADY_FULL,
        success: false
      })
      return;
    }
    const type = file.type;
    if (!(type.endsWith('pdf'))) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.NOT_PDF,
        success: false
      })
      return;
    }
    setDisabledFile(true);
    const response = await uploadFile(userId, e, `singleItem/${contentId}`).catch((err) => {
      setDisabledFile(false);
    });
    setDisabledFile(false);
    setFileUrl(response);
    setFileName(file.name)
  }
  const checkValidations = () => {
    const list = [];
    list.push(checkValidation('title', title));
    list.push(checkValidation('forFreeUserPrice', forFreeUserPrice));
    list.push(checkValidation('forSubscriberPrice', forSubscriberPrice));
    return list.includes(true);
  }
  /**コンテンツアップロード用 */
  const onUploadContent = async () => {
    if (!(thumbUrl && title && forFreeDescription && forFreeUserPrice >= 0 && forSubscriberPrice >= 0)) {
      setSnackState({
        open: true,
        message: '必須項目が漏れています',
        success: false
      })
      return;
    }
    if (checkValidations()) {
      setSnackState({
        open: true,
        message: '全ての項目を正しい値で入力してください',
        success: false,
      });
      return;
    }
    if (fileUrl && !fileDescription) {
      setSnackState({
        open: true,
        message: '添付資料の説明を記載してください',
        success: false
      })
      return;
    }
    if (!fileUrl && fileDescription) {
      setSnackState({
        open: true,
        message: '資料を添付するか、添付資料説明を削除してください',
        success: false
      })
      return;
    }
    if(forFreeUserPrice === '' || forSubscriberPrice === ''){
      setSnackState({
        open: true,
        message: '販売価格を入力してください',
        success: false
      })
      return;
    }
    setDisabled(true);
    const content = {
      thumbUrl: thumbUrl,
      title: title,
      description: {
        forFreeDescription: forFreeDescription,
        forSubscriptionDescription: forSubscriptionDescription
      },
      price: {
        forFreeUserPrice: Number(forFreeUserPrice),
        forSubscriberPrice: Number(forSubscriberPrice)
      },
      fileUrl: fileUrl,
      fileDescription: fileDescription,
      fileName: fileName,
      free: free,
      published: published,
      tag: tag
    }
    const response = await functionsProviders.uploadSingleItem(contentId, content).catch((err) => {
      setSnackState({
        open: true,
        message: UPLOADE_CONTENT.FAILED,
        success: false
      })
      setDisabled(false);
      return;
    })
    if (response) {
      setSnackState({
        open: true,
        message: UPLOADE_CONTENT.SUCCESS,
        success: true
      })
      setDisabled(false);
      setTimeout(() => {
        history.push({
          pathname: '/my',
          state: { tab: 'イベント' }
        })
      }, 2000);
    } else {
      setSnackState({
        open: true,
        message: UPLOADE_CONTENT.FAILED,
        success: false
      })
      setDisabled(false);
    }
  }
  const setOpenAction = () => {
    if (!contentExist) return;
    setOpen(true)
  }

  const handleDeleteChip = (chip, index) => {
    setTag(tag => tag.filter((doc, key) => key !== index))
  }

  const handleAddChip = (chip) => {
    setTag(tag => {
      return [...tag, chip[chip.length - 1]]
    })
  }
  const handleFree = (data) => {
    if (providerStatus !== 'active') {
      setSnackState({
        open: true,
        message: '有料での販売には、口座登録・本人確認の実施が必要になります。',
        success: false
      })
      return;
    }
    setFree(data);
  }
  const handleClickThumbnail = () => thumbnailRef.current.click();
  const handleClickMaterial = () => materialRef.current.click();

  return (
    <StyledFullPaper>
      <Input
        ref={thumbnailRef}
        id="id_upload_thumbnail"
        onChange={(e) => onUploadThumbnail(e)}
      />
      <EditableThumbnail
        title="サムネイル"
        avatar={thumbUrl}
        loading={disabledThumb}
        onClick={() => handleClickThumbnail()}
        helperText={'jpg,jpeg,pngのファイル形式でアップロードしてください'}
      />
      <SimpleSwitch
        title='公開状態'
        required={true}
        array={publishOption}
        checked={published}
        onChange={(e) => setPublished(e.target.checked)}
      />
      <SingleTextInput
        title={'タイトル'}
        label="タイトルを入力してください"
        value={title}
        name={'title'}
        validation={true}
        multiline={false}
        setFunction={setTitle}
        required={true}
        propsHelperMessage={'使用できない特殊文字を含んでいます'}
      />
      <MultiLineTextInput
        title={'イベント説明'}
        label="コンテンツ内容を入力してください"
        multiline={true}
        required={true}
        value={forFreeDescription}
        setFunction={setForFreeDescription}
        rows={10}
      />
      <ChipTextInput
        fullWidth={true}
        defaultValue={[]}
        variant={"outlined"}
        title={"タグ"}
        label="関連するタグを入力してください"
        value={tag}
        onChange={(chip) => handleAddChip(chip)}
        onDelete={(chip, index) => handleDeleteChip(chip, index)}
      />
      <SimpleSwitch
        title='イベント情報の無料公開'
        array={priceOption}
        checked={free}
        onChange={(e) => handleFree(e.target.checked)}
      />
      <Collapse in={!free ? true : false}>
        <SingleTextInput
          title={'販売価格(非会員）'}
          label="販売価格(非会員)を入力してください"
          value={forFreeUserPrice}
          name={'forFreeUserPrice'}
          placeholder={free ? '0' : '3000'}
          validation={true}
          setFunction={setForFreeUserPrice}
          required={true}
          propsHelperMessage={'正しい数値を入力してください'}
          disabled={free ? true : false}
          shrink={true}
        />
        <SingleTextInput
          title={'販売価格(会員）'}
          label="販売価格(会員)を入力してください"
          value={forSubscriberPrice}
          name={'forFreeUserPrice'}
          placeholder={free ? '0' : '2000'}
          validation={true}
          setFunction={setForSubscriberPrice}
          required={true}
          propsHelperMessage={'正しい数値を入力してください'}
          disabled={free ? true : false}
          shrink={true}
        />
        <MultiLineTextInput
          title={'購入者限定のイベント説明'}
          label="追加説明を入力してください"
          multiline={true}
          value={forSubscriptionDescription}
          setFunction={setForSubscriptionDescription}
          rows={10}
        />
        <Input
          id="id_upload_file"
          onChange={(e) => onUploadFile(e)}
          ref={materialRef}
        ></Input>
        {!fileName ? <UploadDottedButton
          onClick={() => handleClickMaterial()}
          title={'購入限定資料'}
          disabled={disabledFile}
        >{disabledFile ? 'アップロード中' : '資料をアップロードしてください'}</UploadDottedButton>
          : <DeleteContentButton
            title={'登録中の資料'}
            fileName={fileName}
            onClick={() => {
              setFileUrl(null)
              setFileName(null)
            }}
          />}
        <MultiLineTextInput
          title={'購入者限定資料の説明'}
          label="購入者限定資料の説明を入力してください"
          multiline={true}
          value={fileDescription}
          setFunction={setFileDescription}
          rows={10}
        />
      </Collapse>
      <BasicButton
        onClick={() => onUploadContent()}
        disabled={disabled} >
        {disabled ? '登録中...' : '登録する'}
      </BasicButton>
      <WhiteButton
        onClick={() => setOpenAction()}
      >
        {"削除する"}
      </WhiteButton>
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
      <DeleteSingleItemDialog open={open} setOpen={setOpen} contentId={contentId} />
    </StyledFullPaper>
  )
}

export default SingleItem;

const Input = styled.input.attrs({ type: 'file' })`
display:none;
`;
