import firebase from 'firebase';
import { storage } from '../index';

/**
 * Upload an image file to firestorage and add the image url to firestore
 *
 * @param {*} uid
 * @param {*} e
 * @returns {String}
 */
export const uploadFile = async (uid, e, path) => {
	let storageRef = storage.ref();
	const file = e.target.files[0];
	let uploadImagesRef = storageRef.child(`images/${uid}/${path}/${file.name}`);
	if (uid) {
		await uploadImagesRef.put(file).catch((error) => {
			throw new Error(error);
		});
		const imageUrl = await uploadImagesRef.getDownloadURL().catch((error) => {
			throw new Error(error);
    });
    return imageUrl;
	} else {
		console.log('uid is empty');
		return null;
	}
};
export default uploadFile;

export const uploadFileShowProgress = async (uid, e, path, setProgress) => {
  let storageRef = storage.ref();
	const file = e.target.files[0];
	let uploadImagesRef = storageRef.child(`images/${uid}/${path}/${file.name}`);
	if (uid) {
    await uploadImagesRef.put(file).on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    function(snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      setProgress(progress)
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
      }
    })
		const imageUrl = await uploadImagesRef.getDownloadURL().catch((error) => {
			throw new Error(error);
    });
    return imageUrl;
	} else {
		console.log('uid is empty');
		return null;
	}
}
