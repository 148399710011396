export const CONTENTS_CATEGORIES = {
  FREE: '無料',
  BACKNUMBER: '個別販売',
  MEMBERONLY:  '会員限定',
  LIMITEDTIME: '期間限定',
  EVENTITEM: 'イベント'
}

export const  MYPAGE_CATEGORIES = {
  LIKES: 'いいね済み',
  FOLLOWS: 'フォロー',
  MEMBERONLY: '会員限定'
}