import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { BasicButton, WhiteButton } from '../atoms/Button';
import { DeleteDialog } from '../atoms/Dialog';
import { ErrorAlert } from '../atoms/Alert';

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: '0 3pt 6pt #00000029',
    marginBottom: '3.2vw'
  },
}))

const CouponDialog = (props) => {
  const classes = useStyles();
  const { open, setOpen, actionType, disabled } = props;
  const onClick = props.onClick ? props.onClick : () =>{};
  const onCloseModal = () => {
    setOpen(false);
  }
  return (
    <>
      <DeleteDialog open={open} setOpen={setOpen} disabled={disabled}>
        <Content>
          <Title>{disabled ? `${actionType === 'delete' ? '削除中' : '変更中' }...` :
          `${actionType === 'delete' ? '削除' : '変更' }しますか？`}</Title>
           <ErrorAlert>{'クーポン内容を変更すると既にクーポン利用中のお客様にも即時変更が適用されます'}</ErrorAlert>
          <BasicButton disabled={disabled} onClick={onClick}>{actionType === 'delete' ? "削除" : '変更'}</BasicButton>
          <WhiteButton className={classes.button} disabled={disabled} onClick={() => onCloseModal()}>{"キャンセル"}</WhiteButton>
        </Content>
      </DeleteDialog>
    </>
  )
}
export default CouponDialog;


const Content = styled.div`
width: 100%;
height: 100%;
position: relative;
text-align:center;
`
export const Title = styled(Typography).attrs({
  varient: 'h3',
})`
  font-size: 1.4rem;
  font-weight:700;
  margin:${props => props.theme.spacing(3)}px ${props => props.theme.spacing(2)}px;
  vertical-align: top;
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
      font-size: 18px;
}
`