import React from 'react';
import StripeElementsForm from '../../stripe/StripeElementsForm';
import BankCardForm from '../organisms/BankCardForm';
import {PageTitle} from '../atoms/Title';

const RegisterBankPage = () => {
  return (
    <div>
      <PageTitle>口座登録</PageTitle>
      <StripeElementsForm>
        <BankCardForm />
      </StripeElementsForm>
    </div>
  )
}
export default RegisterBankPage;