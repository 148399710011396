import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Chart from './Chart';
import { FlexWrap } from '../atoms/Paper';
import { AmountCard } from './AmountCard';
import { FullPaper } from '../atoms/Paper';
import { SelectSmallInput } from '../atoms/SelectInput';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment-timezone';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
});

const AccountingManagement = (props) => {
  const classes = useStyles();
  const { loading, totalViews, subscriber, singleItemBuyer, totalAmount, monthlyAmount, orderList } = props;
  const l_totalAmount = totalAmount ? Number(totalAmount).toLocaleString() : '-';
  const l_monthlyAmount = monthlyAmount ? Number(monthlyAmount).toLocaleString() : '-';
  const numberOfSingleItemBuyer = singleItemBuyer ? Number(singleItemBuyer.length).toLocaleString() : 0;
  const numberOfSubscriber = subscriber ? Number(subscriber.length).toLocaleString() : 0;
  const l_totalViews = totalViews ? Number(totalViews).toLocaleString() : '-';

  // 一旦固定
  const yearList = [
    { label: '2020', value: 2020 },
    { label: '2021', value: 2021 },
    { label: '2022', value: 2022 },
    { label: '2023', value: 2023 },
    { label: '2024', value: 2024 },
    { label: '2025', value: 2025 },
  ];
  const [selectedYear, setSelectedYear] = useState(yearList[0].value);
  const init = async () => {
    const nowYear = moment().tz('Asia/Tokyo').format('YYYY');
    for (let i = 0; i < yearList.length; i++) {
      if (nowYear === yearList[i].label) {
        setSelectedYear(yearList[i].value);
        break;
      }
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <FlexWrap>
        {loading ?
          <CustomSkeleton /> :
          <AmountCard name={'売上累計額'} value={l_totalAmount} unit={'円'} />
        }
        {loading ?
          <CustomSkeleton /> :
          <AmountCard name={'今月の売上額'} value={l_monthlyAmount} unit={'円'} />
        }
        {loading ?
          <CustomSkeleton /> :
          <AmountCard name={'個別商品購入人数'} value={numberOfSingleItemBuyer} unit={'人'} />
        }
        {loading ?
          <CustomSkeleton /> :
          <AmountCard name={'会員人数'} value={numberOfSubscriber} unit={'人'} />
        }
        {loading ?
          <CustomSkeleton /> :
          <AmountCard name={'総再生回数'} value={l_totalViews} unit={'回'} />
        }
      </FlexWrap>
      <FullPaper>
        <FlexWrap>
          <SmallTitle>{'売上推移（年別）'}</SmallTitle>
          <SelectSmallInput
            items={yearList}
            onChange={(e) => { setSelectedYear(e.target.value) }}
            value={selectedYear}
          />
        </FlexWrap>
        <Chart loading={loading} selectedYear={selectedYear} orderList={orderList} />
      </FullPaper>
    </>
  );
};

export default AccountingManagement;

const Wrap = styled.div`
width: 100%;
margin-bottom: 20vw;
position: relative;
text-align: left;
@media (max-width: 600px) {
  margin-top: 10vw;
}
`;

const CustomSkeleton = styled(Skeleton)`
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
        margin: ${(props) => props.theme.spacing(2)}px;
        width: 246.03px;
        height: 125px;
        border: 1px solid #ECECEC;
        box-sizing: border-box;
        border-radius: 8px;
        flex-direction: row;
        padding: ${(props) => props.theme.spacing(3)}px;
  }
  margin: ${(props) => props.theme.spacing(2)}px 0
    ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  padding: ${(props) => props.theme.spacing(1)}px;
  width: calc(100% - ${(props) => props.theme.spacing(3)}px * 2);
  max-width: 340px;
  height: 65px;
  border-radius: 8px;
`;

const SmallTitle = styled.div`
//font-family: Noto Sans JP;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 23px;
letter-spacing: -0.333333px;
color: #2B2B2B;
margin: 27px 10px;
vertical-align: top;
`;