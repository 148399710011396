import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Coupon, AppliedBigCouponCard } from '../molecules/Coupon';
import { StyledFullPaper, StyledHalfFlexWrap } from '../atoms/Paper';
import { useHistory } from 'react-router-dom';
import { firestoreProviders } from '../../firebase/firestore/firestoreProviders';

const list = [1,2,3,4,5];
const CouponList = () => {
  const coupons = useSelector(state => state.coupons);
  const [ appliedCouponList, setAppliedCouponList] = useState(null);
  const history = useHistory();
  useEffect(()=>{
    const getDetailInfo = async() =>{
      const list = []
      coupons.map(async(coupon) => {
        const couponId = coupon.couponId;
        const expireData = coupon.data.expireData;
        const status = coupon.data?.status;
        const couponData = coupon.data
        if(status === 'active'){
        list.push({
          couponId: couponId,
          couponData: couponData,
          expireData: expireData,
          status: status
        })
       }
      });
      setAppliedCouponList(list);
    }
    if(coupons){
      getDetailInfo()
    }
  },[coupons]);
  return(
    <>
    {appliedCouponList ? appliedCouponList.length > 0 ? appliedCouponList.map((coupon)=>{
      const couponData = coupon.couponData;
      const expireData = coupon.expireData;
      const couponId = coupon.couponId;
      const status = coupon.status;
      return <AppliedBigCouponCard
        couponData={couponData}
        couponId={couponId}
        expireData={expireData}
        status={status}
        onClick={()=>history.push(`/coupon-detail/${couponId}`)}
        />
    }): <p>適用されているクーポンはありません</p> :
    list.map(()=>
     <AppliedBigCouponCard
        couponData={null}
        couponId={null}
        expireData={null}
        status={null}
        onClick={()=>{}}
        />)}
  </>
  )
}

export default CouponList;