import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import styled from "styled-components";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  defaultChip: {
    borderRadius: '6px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    background: theme.palette.primary.main,
    minWidth: '18.1vw',
    width: 'auto',
    height: '6.6vw',
    marginRight: '3.6vw',
    marginTop: '1.25vw',
    marginBottom: '1.25vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.9vw',
    }
  },
  inActiveChip: {
    borderRadius: '6px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    width: '18.1vw',
    height: '6.6vw',
    marginRight: '3.6vw',
    marginTop: '1vw',
    marginBottom: '1vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.9vw',
    }
  },
  labelChip:{
    borderRadius: '2px',
    fontSize: '1.6rem',
    height: '3.0rem',
    fontWeight: 'bold',
    width: 'auto',
    marginRight: '3.6vw',
    marginTop: '1vw',
    marginBottom: '1vw',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.9vw',
      height: '4.8vw',
    }
},
freelabelChip:{
  borderRadius: '2px',
  background: '#2699FB',
  color: 'white',
  fontSize: '1.6rem',
  height: '3.0rem',
  fontWeight: 'bold',
  width: 'auto',
  marginRight: '3.6vw',
  marginTop: '1vw',
  marginBottom: '1vw',
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.9vw',
    height: '4.8vw',
  }
}
}))

export const DefaultChip = (props) => {
  const classes = useStyles();
  const { onDelete } = props
  let icon;
  return (
      <Chip className={classes.defaultChip} label={props.label} />
  );
}

export const DefaultInActiveChip = (props) => {
  const classes = useStyles();
  return (
      <Chip className={classes.inActiveChip} label={props.label}/>
  );
}

export const DefaultLabel = (props) => {
  const classes = useStyles();
  return (
      <Chip className={props.label ==="公開中" ? classes.freelabelChip : classes.labelChip} label={props.label}/>
  );
}
export const SmallChip = styled(Chip).attrs({
  variant:"outlined",
  size:"small"
})`
`;

export const SmallContainedChip= styled(Chip).attrs({
  size:"small",
  variant:"outlined",
})`
font-weight:700;
`;

export const CustomizedSmallChip = styled(SmallChip)`
cursor: pointer;
`;


export const StyledSmallChip = (props) => {
  const label = props ? props.label ? props.label : null:null;
  return(
  <Div>
    {label ? <SmallContainedChip label={label} color={label ==='公開中' ? 'primary': 'default'}/> : <Skeleton height={24}/>}
  </Div>
  )
}


export const BasicChip = styled(Chip).attrs({
  variant:"outlined",
})`
margin: ${props => props.theme.spacing(0.5)}px;
`;

const  Div = styled.div`
margin: ${props => props.theme.spacing(1)}px 0;
//width: 100%;
`;
export const CouponChip = styled(Chip).attrs({
  variant:"outlined",
})`
margin: ${props => props.theme.spacing(0.5)}px;
width:73px;
height:24px;
font-weight:bold;
font-size: 12px;
color: ${props => props.color};
border: 1px solid ${props => props.color};
`;

export const CommentStatusChip = styled(Chip).attrs({
  size:"small"
})`
margin: 4px ${props => props.theme.spacing(2)}px 0 0;
font-size:10px;
font-weight:bold;
height: 18px;
cursor: pointer;
width: 66px;
line-height: 16px;
`;

