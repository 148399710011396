import React from 'react';
import KYCForm from '../organisms/KYCForm';
import {PageTitle} from '../atoms/Title';

const KYCPage = () => {
  return(
    <div>
        <PageTitle>本人確認</PageTitle>
        <KYCForm/>
    </div>
  )
}

export default KYCPage;
