import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { theme } from '../../config';
import { UploadButton } from '../atoms/Button';
import { Img, StyledImage } from '../atoms/Img';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';

export const KYCInput = (props) => {
  const { setFunction, file, type } = props;
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });

  const uploadFileInfo = (e) => {
    var fileName = e.target.files;
    const file = e.target.files[0];
    const fileType = file.type;
    if(!(fileType.endsWith('jpg')||fileType.endsWith('jpeg')||fileType.endsWith('png'))){
      setSnackState({
        open: true,
        message: 'アップロードするファイル形式をjpeg,jpg,pngに変更してください',
        success: false
      })
      return;
    }
    setFunction(fileName, type);
  };
  return (
    <>
      <Input
        ref={props.Ref}
        id={`id_upload_file_${type}`}
        onChange={(e) => uploadFileInfo(e)}
      />
      <UploadButton
        onClick={props.onClick}
      >{props.title}</UploadButton>
      {file &&
       <StyledImage
       title={'登録するファイル画像'}
       src={file} />}
      <CustomizedSnackbar
        snackState={snackState}
        setSnackState={setSnackState}
      />
    </>
  );
};

const Input = styled.input.attrs({ type: 'file' })`
  display: none;
`;
