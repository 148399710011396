/** action-creator */
export const setPlanList= (value) => {
  return { type: 'SET_PLANLIST', value: value };
};
export const resetPlanList = (value) => {
  return { type: 'RESET_PLANLIST', value: value };
};

/** reducer */
const planList = (state = null, action) => {
    switch (action.type) {
      case 'SET_PLANLIST':
          var newValue = Object.assign({},action.value);
          return newValue;
      case 'RESET_PLANLIST':
            return [];
      default:
        return state
    }
  }

  export default planList;
