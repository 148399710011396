export const VALIDATION_MESSAGE = {
  NO_NICKNAME: 'ニックネームを入力してください',
  NOT_APPROPRIATE_NICKNAME_FORMAT: 'ニックネームに使用できない特殊文字を含んでいます',
  NO_MEMBERSHIP_PRICE:'月額会員販売価格を入力してください',
  NOT_APPROPRIATE_MEMBERSHIP_PRICE_FORMAT: '月額会員販売価格は数値のみで入力してください',
  NOT_APPROPRIATE_WEBSITE_FORMAT: '無効なURL（紹介サイト/SNS）です。正しい形式で入力してください',
  NO_NAME: 'お名前を入力してください',
  NOT_APPROPRIATE_NAME_FORMAT: 'お名前に使用できない特殊文字を含んでいます',
  NO_BIRTHDAY: '生年月日を入力してください',
  NO_PHONENUMBER: '電話番号を入力してください',
  NOT_APPROPRIATE_PHONENUMBER_FORMAT: '電話番号は11桁以内の数値で入力してください',
  NO_EMAIL: 'emailを入力してください',
  NOT_APPROPRIATE_EMAIL_FORMAT: '無効なemailです。正しい形式で入力してください',
  NO_FORMURL: 'お問い合わせフォームのURLを入力してください',
  NOT_APPROPRIATE_FORMURL_FORMAT: 'お問い合わせフォームのURLが無効なURLです。正しい形式で入力してください',
}
export const STORAGE_UPLOAD_MESSAGE = {
  ALEADY_FULL: 'ストレージの容量がいっぱいです。プランの変更または、コンテンツを削除してください',
  NOT_APPROPRIATE_FILE_TYPE: 'アップロードするファイル形式をjpeg,jpg,pngに変更してください',
  NOT_PDF: 'アップロードするファイル形式をpdfに変更してください',
  NOT_MP4_OR_MOV: 'アップロードするファイル形式をmp4またはMOVに変更してください',
  REMOVE_SPACE: 'スペース及び記号を除いたファイル名にしてください'
}

export const GET_DATA_FROM_FIRESTORE_MESSAGE = {
  FAILED: '読み込み処理に失敗しました。ブラウザを更新してください',
}
export  const DELETE_COMMENT_MESSAGE = {
  SUCCESS: 'コメントの削除が完了しました',
  FAILED: 'コメントの削除に失敗しました'
}
export const UPDATE_COMMENT_STATUS_MESSAGE = {
  SUCCESS: 'コメントのステータス更新が完了しました',
  FAILED: 'コメントのステータス更新に失敗しました'
}
export const REPLY_COMMENT_MESSAGE = {
  SUCCESS: 'リプライコメントが完了しました',
  FAILED: 'リプライコメントに失敗しました'
}
export const UPDATE_REPLY_COMMENT_MESSAGE = {
  SUCCESS: 'リプライコメントを編集しました',
  FAILED: 'リプライコメントの編集に失敗しました'
}
export const DELETE_REPLY_COMMENT_MESSAGE = {
  SUCCESS: 'リプライコメントを削除しました',
  FAILED: 'リプライコメントの削除に失敗しました'
}
/**
 * contract
 */
export const UPDATE_CONTRACT_MESSAGE = {
  SUCCESS: '契約を更新いたしました',
  FAILED: '契約処理に失敗しました'
}
export const ALERT_FOR_CONTRACT = {
  PAYMENT: '決済方法を登録してください',
  PLAN: 'プランを選択してください',
  NOT_CURRENT_PLAN: '現在契約中以外のプランを選択してください'
}
/**
 * coupon
 */
export const CREATE_COUPON_MESSAGE = {
  SUCCESS: 'クーポンが作成されました',
  FAILED: 'クーポンの作成に失敗しました'
}
export const DELETE_COUPON_MESSAGE = {
  FAILED: 'クーポンの削除に失敗しました'
}
export const EDIT_COUPON_MESSAGE = {
  FAILED: 'クーポンの編集に失敗しましたs'
}
export const COUPON_VALIDATION_MESSAGE = {
  NO_COUPON_NAME: 'クーポン名を入力してください',
  NO_DISCOUNT_RATE: '割引率を入力してください',
  NOT_APPROPRIATE_DISCOUNT_RATE_VALUE: '割引率には1から100の値を入力してください',
  NO_PERIOD: 'クーポンの期間を選択してください',
  NO_TARGET: 'クーポン適用対象を選択してください'
}

export const SEND_MAIL_MESSAGE = {
  SUCCESS: 'メールマガジン配信登録が完了しました',
  FAILED: 'メールマガジン配信が失敗しました'
}
export const REGISTER_BANK_MESSAGE = {
  SUCCESS: '口座登録が完了しました',
  FAILED: '口座登録が失敗しました'
}
export const INPUT_BANK_MEESAGE = {
  NOT_ENOUGH: '入力漏れがあります',
  ERROR_BRANCH01: '支店名は3桁で入力してください',
  ERROR_BRANCH02: '支店名には数字を入力してください',
  ERROR_BANKNUMBER01: '口座番号は7桁で入力してください',
  ERROR_BANKNUMBER02: '口座番号には数字を入力してください',
  ERROR_BANKACCOUNT: '口座名義はカタカナでご入力ください',
}
export const EDIT_PROFILE = {
  SUCCESS: '登録が完了しました',
  FAILED: '登録が失敗しました'
}
export const UPLOADE_CONTENT = {
  SUCCESS: 'コンテンツのアップロードが完了しました',
  FAILED: 'コンテンツのアップロードを失敗しました'
}
