import React, { useState } from 'react';
import styled from "styled-components";
import { UploadRoundButton } from '../atoms/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton, ReplayControl, ForwardControl } from 'video-react';
import 'video-react/dist/video-react.css';
import HLSSource from '../molecules/hlsComponent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import MovieIcon from '@material-ui/icons/Movie';
import LinkIcon from '@material-ui/icons/Link';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import { useSelector } from 'react-redux';
import { liffConsumerId } from '../../config';

export const UploadContentButton = (props) => {
  const providerData = useSelector(state => state.providerData);
  const progress = props.progress;
  const onClick = props.onClick ? props.onClick : () => { };
  const videoUrl = props.videoUrl ? props.videoUrl : null;
  const matches = useMediaQuery('(min-width:600px)');
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });
  const onClipBoardCopy = async () => {
    const url = window.location.href;
    const urlRegEx = url.match(/^(https?:\/\/.+)\/(.+)\/(.+)$/);
    if (urlRegEx) {
      // ex:
      // urlRegEx[1] = https://cookon-provider-stg.web.app
      // urlRegEx[2] = edit-content
      // urlRegEx[3] = line:XXXXXXXX...
      navigator.clipboard.writeText('https://liff.line.me/' + liffConsumerId + '/?content/' + urlRegEx[3] + '?providerId=' + providerData?.uid);
      setSnackState({
        open: true,
        message: '公開用のリンクをコピーしました',
        success: true,
      });
      return;
    }
    setSnackState({
      open: true,
      message: '公開用のリンクコピーに失敗しました',
      success: false,
    });
  }
  return (
    <>
    <Div>
      {!videoUrl ? (!progress) ? <>
        <UploadRoundButton onClick={onClick}>動画をアップロード</UploadRoundButton>
        <div>動画をアップロードしてください</div>
      </>
        :
        <StyledBox position="relative" display="inline-flex">
         {progress < 100 && <CircularProgress variant="static" {...props} value={progress} size={100} />}
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StyledTypography variant="body1" component="div" color="primary" >{progress >= 100 ? 'アップロード完了' :`${Math.round(
              progress
            )}%`}</StyledTypography>
          </Box>
        </StyledBox>
        : (!progress) ?
          <ContentImg>
            <StyledPlayer
              fluid={false}
              width={matches ? 650 : '100vw'}
              height={matches ? 426.38 : 260}>
              <BigPlayButton position="center" />
              <HLSSource
                isVideoChild
                src={videoUrl}
              />
              <ControlBar>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={10} order={1.2} />
                <PlaybackRateMenuButton order={7} rates={[2, 1.5, 1.0, 0.5]} />
              </ControlBar>
            </StyledPlayer>
              <StyledIconButton
                variant="outlined"
                color="primary"
                onClick={onClick}>
                <StyledMovieIcon color='primary' />
              </StyledIconButton>
              <StyledLinkIconButton
                variant="outlined"
                color="primary"
                onClick={onClipBoardCopy}>
                <StyledLinkIcon color='primary' />
              </StyledLinkIconButton>
          </ContentImg> :
          <StyledBox position="relative" display="inline-flex">
           {progress <100 && <CircularProgress variant="static" {...props} value={progress} size={100} /> }
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StyledTypography variant="body1" component="div" color="primary" >{progress >= 100 ? 'アップロード完了' :`${Math.round(
              progress
            )}%`}</StyledTypography>
            </Box>
          </StyledBox>
      }
    </Div>
    <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
    </>
  )
}

const Div = styled.div`
background: ${props => props.theme.palette.gray.gray01};
color: #BABAB9;
text-align: center;
width: 100vw;
height: 260px;
margin-left: -${props => props.theme.spacing(1)}px;
box-shadow: none;
*:focus {
  outline: none;
}
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:${props => props.theme.spacing(5)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
  width: 650px;
  height: 426.38px;
}
`;

const StyledPlayer = styled(Player)`
width: 100%;
height: 260px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 650px;
  height: 426.38px;
}
`

const StyledBox = styled(Box)`
position:relative;
display: inline-flex;
margin-top: 80px;
min-width :100px;
min-height: 100px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin-top: 150px;
}
`;

const StyledTypography = styled(Typography)`
font-weight: 700;
`;

const ContentImg = styled.div`
 position:relative;
 width: 100%;
 height: 100%
`;

const StyledIconButton = styled(IconButton)`
position: absolute;
width: 38px;
height: 38px;
bottom: 32px;
right: 32px;
background: white;
box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
&:action{
  background: rgba(255,255,255, 0.8);
}
&:hover{
  background: rgba(255,255,255, 0.8);
}
`;

const StyledLinkIconButton = styled(IconButton)`
position: absolute;
width: 38px;
height: 38px;
bottom: 32px;
right: 80px;
background: white;
box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
&:action{
  background: rgba(255,255,255, 0.8);
}
&:hover{
  background: rgba(255,255,255, 0.8);
}
`;

const StyledLinkIcon = styled(LinkIcon)`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
`;

const StyledMovieIcon = styled(MovieIcon)`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
`;