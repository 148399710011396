import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import { checkValidation } from "../molecules/CTextField";
import { BasicButton } from "../atoms/Button";
import uploadFile from "../../firebase/storage/uploadFile";
import uploadFileShowProgress from "../../firebase/storage/uploadFileShowProgress";
import CustomizedSnackbar from "../atoms/CustomizedSnackbar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import functionsProviders from "../../firebase/functions/functionsProviders";
import { firestoreProviders } from "../../firebase/firestore/firestoreProviders";
import DeleteDialogComponent from "../molecules/DeleteDialog";
import PreviewModal from "../molecules/PreviewModal";
import "video-react/dist/video-react.css";
import { EditableThumbnail } from "../atoms/Avatar";
import { SimpleSwitch } from "../atoms/Switch";
import {
  publishOption,
  priceOption,
  backNumberOption,
  salesOption
} from "../../static/checkData";
import {
  UploadDottedButton,
  DeleteContentButton,
  WhiteButton,
} from "../atoms/Button";
import { UploadContentButton } from "../molecules/UploadContentButton";
import {
  SingleTextInput,
  MultiLineTextInput,
  ChipTextInput,
} from "../atoms/TextInput";
import { StyledFullPaper } from "../atoms/Paper";
import { subscribeDate as limitedPeriodData } from "../../static/data";
import { SelectInput } from "../atoms/SelectInput";
import Collapse from "@material-ui/core/Collapse";
import { CONTENTS_CATEGORIES  } from '../../static/constants';
import { STORAGE_UPLOAD_MESSAGE, UPLOADE_CONTENT } from '../../static/messages';

const Content = () => {
  const history = useHistory();
  const videoRef = useRef();
  const thumbnailRef = useRef();
  const materialRef = useRef();
  const path = history.location.pathname.replace("/edit-content", "");
  const sourceTab = history.location.state?.tab;
  const [contentId, setContentId] = useState(null);
  const providerData = useSelector((state) => state.providerData);
  const shopInfo = providerData ? providerData.shopInfo : null;
  const providerStatus = shopInfo ? shopInfo.status : null;
  const currentUsage = shopInfo ? shopInfo.currentUsage : 0;
  const contract = useSelector((state) => state.contract);
  const planList = useSelector((state) => state.planList);
  const plan = contract ? contract.plan : "";
  const currentPlanInfo = planList ? planList[plan] : null;
  const currentPlanCapa = currentPlanInfo
    ? Number(currentPlanInfo.capacity) * 1000000000
    : 0;
  const userId = providerData ? providerData.uid : null;
  const subscribeDate = shopInfo ? shopInfo.subscribeDate : null;
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoProgress, setVideoProgress] = useState(0);
  const [title, setTitle] = useState("");
  const [forFreeDescription, setForFreeDescription] = useState("");
  const [thumbUrl, setThumbUrl] = useState(null);
  const [disabledThumb, setDisabledThumb] = useState(false);
  const [tag, setTag] = useState([]);
  const [limitedPeriod, setLimitedPeriod] = useState(7);
  const [backNumberStatus, setBackNumberStatus] = useState(false);
  const [backNumberPrice, setBackNumberPrice] = useState(0);
  const [tmpBackNumberPrice, setTmpBackNumberPrice] = useState(0);
  const [forSubscriberBackNumberPrice, setForSubscriberBackNumberPrice] = useState(0);
  const [tmpforSubscriberBackNumberPrice, setTmpForSubscriberBackNumberPrice] = useState(0);
  const [forSubscriptionDescription, setForSubscriptionDescription] =
    useState("");
  const [published, setPublished] = useState(false);
  const [beforePublished, setBeforePublished] = useState(false);
  const [free, setFree] = useState(true);
  const [disabledFile, setDisabledFile] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileDescription, setFileDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [contentExist, setContentExist] = useState(false);
  const [previewFlag, setPreviewFlag] = useState(false);
  const [status, setStatus] = useState(null);
  const [snackState, setSnackState] = useState({
    open: false,
    message: "message",
    success: true,
  });
  const [isMemberOnly, setIsMemberOnly] = useState(false);
  /**contentIdをセットする */
  useEffect(() => {
    if (path) {
      setContentId(path.replace("/", ""));
      setContentExist(true); // 削除ボタンの有効化
    } else {
      if (userId) {
        setContentId(userId + moment());
      }
    }
  }, [userId]);

  useEffect(() => {
    getContentData(userId, contentId);
  }, [userId, contentId]);

  useEffect(() => {
    if (free) {
      setBackNumberPrice(0);
      setForSubscriberBackNumberPrice(0);
    } else {
      setBackNumberPrice(tmpBackNumberPrice);
      setTmpForSubscriberBackNumberPrice(tmpforSubscriberBackNumberPrice)
    }
  }, [free]);

  useEffect(() => {
    if (sourceTab === CONTENTS_CATEGORIES.MEMBERONLY) {
      setFree(false);
    } else if (sourceTab === CONTENTS_CATEGORIES.LIMITEDTIME) {
      setFree(false);
      setBackNumberStatus(true);
    }
  }, []);

  /**
   * isMemberOnlyがfalse(個別購入)が選択された時のトリガー
   * 個別販売 ===> 期間限定(backNumberStatus)がtrueかつ、バックナンバー化までの日数（limitedPeriod)が0
   * とみなす
   */
  useEffect(() => {
    if(!isMemberOnly){
      setBackNumberStatus(true);
      setLimitedPeriod(0);
    }
  },[isMemberOnly]);

  /** コンテンツの読み込み */
  const getContentData = async (userId, contentId) => {
    if (!(userId && contentId)) return;
    const contentData = await firestoreProviders
      .getContentData(userId, contentId)
      .catch((err) => {
        throw err;
      });
    if (contentData) {
      setVideoUrl(contentData ? contentData.videoUrl : null);
      setTitle(contentData ? contentData.title : null);
      setThumbUrl(
        contentData
          ? contentData.thumbUrl
            ? contentData.thumbUrl
            : null
          : null
      );
      setDisabledThumb(contentData ? contentData.disabledThumb : null);
      setBackNumberPrice(
        contentData
          ? contentData.backNumberPrice
            ? contentData.backNumberPrice
            : 0
          : 0
      );
      setTmpBackNumberPrice(
        contentData
          ? contentData.backNumberPrice
            ? contentData.backNumberPrice
            : 0
          : 0
      );
      setForSubscriberBackNumberPrice(
        contentData
          ? contentData.forSubscriberBackNumberPrice
            ? contentData.forSubscriberBackNumberPrice
            : 0
          : 0
      );
      setTmpForSubscriberBackNumberPrice(
        contentData
          ? contentData.forSubscriberBackNumberPrice
            ? contentData.forSubscriberBackNumberPrice
            : 0
          : 0
      );
      setForFreeDescription(contentData.description.forFreeDescription);
      setForSubscriptionDescription(
        contentData
          ? contentData.description.forSubscriptionDescription
            ? contentData.description.forSubscriptionDescription
            : null
          : null
      );
      setPublished(contentData.published);
      setBeforePublished(contentData.published);
      setFree(contentData.free);
      setTag(contentData ? (contentData.tag ? contentData.tag : []) : []);
      setStatus(contentData ? contentData.status : null);
      setFileDescription(contentData ? contentData.fileDescription : null);
      setFileName(contentData ? contentData.fileName : null);
      setFileUrl(contentData ? contentData.fileUrl : null);
      setBackNumberStatus(
        contentData
          ? contentData.backNumberStatus
            ? contentData.backNumberStatus
            : false
          : false
      );
      setLimitedPeriod(
        contentData
          ? contentData.limitedPeriod
            ? contentData.limitedPeriod
            : contentData.limitedPeriod === 0
            ? 0
            : 7
          : 7
      );
      setIsMemberOnly((contentData?.backNumberStatus && contentData?.limitedPeriod === 0) ? false : true);
    }
  };
  /**動画アップロード用 */
  const onUploadVideo = async (e) => {
    const file = e.target.files[0];
    const type = file.type;
    const size = file ? file.size : null;
    if (!(type.endsWith("mp4") || type.endsWith("quicktime"))) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.NOT_MP4_OR_MOV,
        success: false,
      });
      return;
    }
    var reg = new RegExp(
      /[\s+()（）!"#$%&'()☆＊※@☆+,\/:;<=>?@＠\[\\\]^`{|}~【】￥「」]/g
    );
    if (reg.test(file.name)) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.REMOVE_SPACE,
        success: false,
      });
      return true;
    }
    if (size + currentUsage > currentPlanCapa) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.ALEADY_FULL,
        success: false,
      });
      return;
    }
    await uploadFileShowProgress(
      userId,
      e,
      `content/${contentId}`,
      setVideoProgress,
      setVideoUrl,
      currentPlanCapa,
      currentUsage
    ).catch((err) => {});
  };
  /**サムネイルアップロード用 */
  const onUploadThumbnail = async (e) => {
    let imageUrl = "";
    let file = e.target.files[0];
    const size = file ? file.size : 0;
    if (size + currentUsage > currentPlanCapa) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.ALEADY_FULL,
        success: false,
      });
      return;
    }
    const type = file?.type;
    if (
      !(
        type.endsWith("mp4") ||
        type.endsWith("jpeg") ||
        type.endsWith("png")
      ) ||
      !type
    ) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.NOT_APPROPRIATE_FILE_TYPE,
        success: false,
      });
      return;
    }
    setDisabledThumb(true);
    imageUrl = await uploadFile(userId, e, `content/${contentId}`).catch(
      (err) => {
        setDisabledThumb(false);
      }
    );
    setDisabledThumb(false);
    setThumbUrl(imageUrl);
  };
  /**添付ファイルアップロード用 */
  const onUploadFile = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    const size = file ? file.size : 0;
    if (size + currentUsage > currentPlanCapa) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.ALEADY_FULL,
        success: false,
      });
      return;
    }
    const type = file.type;
    if (!type.endsWith("pdf")) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.NOT_PDF,
        success: false,
      });
      return;
    }
    setDisabledFile(true);
    const response = await uploadFile(userId, e, `content/${contentId}`).catch(
      (err) => {
        setDisabledFile(false);
      }
    );
    setDisabledFile(false);
    setFileUrl(response);
    setFileName(file.name);
  };
  const checkValidations = () => {
    const list = [];
    list.push(checkValidation("title", title));
    list.push(checkValidation("backNumberPrice", backNumberPrice));
    list.push(checkValidation("forSubscriberBackNumberPrice", forSubscriberBackNumberPrice));
    return list.includes(true);
  };
  /**コンテンツアップロード用 */
  const onUploadContent = async () => {
    if (!(videoUrl && title && forFreeDescription && backNumberPrice >= 0 && forSubscriberBackNumberPrice >= 0)) {
      setSnackState({
        open: true,
        message: "必須項目が漏れています",
        success: false,
      });
      return;
    }
    if (checkValidations()) {
      setSnackState({
        open: true,
        message: "全ての項目を正しい値で入力してください",
        success: false,
      });
      return;
    }
    if (fileUrl && !fileDescription) {
      setSnackState({
        open: true,
        message: "添付資料の説明を記載してください",
        success: false,
      });
      return;
    }
    if (!fileUrl && fileDescription) {
      setSnackState({
        open: true,
        message: "資料を添付するか、添付資料説明を削除してください",
        success: false,
      });
      return;
    }
    if(backNumberPrice === '' || forSubscriberBackNumberPrice === ''){
      setSnackState({
        open: true,
        message: "個別販売価格を入力してください",
        success: false,
      });
      return;
    }
    setDisabled(true);
    const content = {
      videoUrl: videoUrl,
      thumbUrl: thumbUrl,
      title: title,
      description: {
        forFreeDescription: forFreeDescription,
        forSubscriptionDescription: forSubscriptionDescription,
      },
      backNumberStatus: backNumberStatus,
      backNumberPrice: Number(backNumberPrice),
      forSubscriberBackNumberPrice: Number(forSubscriberBackNumberPrice),
      limitedPeriod: limitedPeriod,
      fileUrl: fileUrl,
      fileDescription: fileDescription,
      fileName: fileName,
      free: free,
      published: published,
      tag: tag,
    };
    const response = await functionsProviders
      .uploadContent(contentId, content)
      .catch((err) => {
        setSnackState({
          open: true,
          message: UPLOADE_CONTENT.FAILED,
          success: false,
        });
        setDisabled(false);
        return;
      });
    if (response) {
      setSnackState({
        open: true,
        message: UPLOADE_CONTENT.SUCCESS,
        success: true,
      });
      setDisabled(false);
      setTimeout(() => {
        history.push({
          pathname: "/my",
          state: {
            tab: free ? CONTENTS_CATEGORIES.FREE : backNumberStatus ? CONTENTS_CATEGORIES.LIMITEDTIME : CONTENTS_CATEGORIES.MEMBERONLY,
          },
        });
      }, 2000);
    } else {
      setSnackState({
        open: true,
        message: UPLOADE_CONTENT.FAILED,
        success: false,
      });
      setDisabled(false);
    }
  };
  const setOpenAction = () => {
    if (!contentExist) return;
    setOpen(true);
  };

  const showPreview = () => {
    setPreviewFlag(true);
  };

  const handleDeleteChip = (chip, index) => {
    setTag((tag) => tag.filter((doc, key) => key !== index));
  };
  const handleAddChip = (chip) => {
    setTag((tag) => {
      return [...tag, chip[chip.length - 1]];
    });
  };
  const handleSwich = (data) => {
    if (status === null) {
      setSnackState({
        open: true,
        message: "アップロード前のため変更は行えません",
        success: false,
      });
      return;
    }
    if (status === "uploaded" || status === "transCoding") {
      setSnackState({
        open: true,
        message: "現在処理中のためステータスの変更は行えません",
        success: false,
      });
      return;
    }
    setPublished(data);
  };
  const handleFree = (data) => {
    if (providerStatus !== "active") {
      setSnackState({
        open: true,
        message: "有料での販売には、口座登録・本人確認の実施が必要になります。",
        success: false,
      });
      return;
    }
    setFree(data);
  };
  const handleVideoRef = () => videoRef.current.click();
  const handleClickThumbnail = () => thumbnailRef.current.click();
  const handleClickMaterial = () => materialRef.current.click();
  return (
    <>
      <StyledFullPaper>
        <Input onChange={(e) => onUploadVideo(e)} ref={videoRef}></Input>
        <UploadContentButton
          progress={videoProgress}
          onClick={() => handleVideoRef()}
          videoUrl={videoUrl}
        />
        <Input
          ref={thumbnailRef}
          id="id_upload_thumbnail"
          onChange={(e) => onUploadThumbnail(e)}
        />
        <EditableThumbnail
          title="サムネイル"
          avatar={thumbUrl}
          loading={disabledThumb}
          onClick={() => handleClickThumbnail()}
          helperText={"jpg,jpeg,pngのファイル形式でアップロードしてください"}
        />
        <SimpleSwitch
          title="公開状態"
          required={true}
          array={publishOption}
          checked={published}
          onChange={(e) => handleSwich(e.target.checked)}
          helperText={
            beforePublished && !published
              ? "※ 非公開にすると既に本コンテンツを購入済みのユーザーにも表示されなくなります"
              : null
          }
        />
        <SingleTextInput
          title={"タイトル"}
          label="タイトルを入力してください"
          value={title}
          name={"title"}
          validation={true}
          multiline={false}
          setFunction={setTitle}
          required={true}
          propsHelperMessage={"使用できない特殊文字を含んでいます"}
        />
        <MultiLineTextInput
          title={"動画説明"}
          label="動画説明を入力してください"
          multiline={true}
          value={forFreeDescription}
          setFunction={setForFreeDescription}
          required={true}
          rows={10}
        />
        <ChipTextInput
          variant={"outlined"}
          title={"タグ"}
          label="関連するタグを入力してください"
          value={tag}
          onChange={(chip) => handleAddChip(chip)}
          onDelete={(chip, index) => handleDeleteChip(chip, index)}
        />
        <SimpleSwitch
          title="無料公開する"
          array={priceOption}
          checked={free}
          onChange={(e) => handleFree(e.target.checked)}
          helperText={!free && '購入者のみ動画を視聴できます'}
        />
        <Collapse in={!free ? true : false}>
        <SimpleSwitch
            title="動画販売方式"
            array={salesOption}
            checked={isMemberOnly}
            onChange={(e) => setIsMemberOnly(e.target.checked)}
            helperText={
              isMemberOnly
                ? "月会員限定コンテンツとなります"
                : "個別販売されます"
            }
          />
          <Collapse in={isMemberOnly}>
          <SimpleSwitch
            title="期間限定にする"
            array={backNumberOption}
            checked={backNumberStatus}
            onChange={(e) => setBackNumberStatus(e.target.checked)}
            helperText={
              backNumberStatus
                ? "期間限定で会員に公開されます。限定期間以降は個別販売されます"
                : "会員に対して無期限に公開されます"
            }
          />
          </Collapse>
          <Collapse in={isMemberOnly && backNumberStatus}>
            <SelectInput
              label="選択してください"
              title={"限定期間の日数"}
              value={limitedPeriod}
              items={limitedPeriodData}
              onChange={(e) => setLimitedPeriod(e.target.value)}
              helperText={
                "限定期間以降、本コンテンツは会員限定ではなくなり、個別商品として販売されます"
              }
            />
            </Collapse>
            <Collapse in={!free && (backNumberStatus || !isMemberOnly)}>
            <SingleTextInput
              title={"個別販売価格(非会員)"}
              label="販売価格を入力してください"
              value={backNumberPrice}
              name={"backNumberPrice"}
              placeholder={free ? "0" : "3000"}
              validation={true}
              setFunction={setBackNumberPrice}
              required={true}
              propsHelperMessage={"正しい数値を入力してください"}
              disabled={free ? true : false}
              shrink={true}
            />
            <SingleTextInput
              title={"個別販売価格(会員)"}
              label="販売価格を入力してください"
              value={forSubscriberBackNumberPrice}
              name={"backNumberPrice"}
              placeholder={free ? "0" : "3000"}
              validation={true}
              setFunction={setForSubscriberBackNumberPrice}
              required={true}
              propsHelperMessage={"正しい数値を入力してください"}
              disabled={free ? true : false}
              shrink={true}
            />
          </Collapse>
          <MultiLineTextInput
            title={"購入者限定の動画説明"}
            label="購入者限定動画説明を入力してください"
            multiline={true}
            value={forSubscriptionDescription}
            setFunction={setForSubscriptionDescription}
            rows={10}
          />
          <Input
            id="id_upload_file"
            onChange={(e) => onUploadFile(e)}
            ref={materialRef}
          ></Input>
          {!fileName ? (
            <UploadDottedButton
              onClick={() => handleClickMaterial()}
              title={"購入限定資料"}
              disabled={disabledFile}
            >
              {disabledFile
                ? "アップロード中"
                : "資料をアップロードしてください"}
            </UploadDottedButton>
          ) : (
            <DeleteContentButton
              title={"登録中の資料"}
              fileName={fileName}
              onClick={() => {
                setFileUrl(null);
                setFileName(null);
              }}
            />
          )}
          <MultiLineTextInput
            title={"購入者限定資料の説明"}
            label="購入者限定資料の説明を入力してください"
            multiline={true}
            value={fileDescription}
            setFunction={setFileDescription}
            rows={10}
          />
        </Collapse>
        <StyledPreviewButtonWrap>
          <BasicButton onClick={() => showPreview()}>
            {"プレビュー"}
          </BasicButton>
        </StyledPreviewButtonWrap>
        <BasicButton onClick={() => onUploadContent()} disabled={disabled}>
          {disabled ? "登録中..." : "登録する"}
        </BasicButton>
        <WhiteButton onClick={() => setOpenAction()}>{"削除する"}</WhiteButton>
        <CustomizedSnackbar
          snackState={snackState}
          setSnackState={setSnackState}
        />
        <DeleteDialogComponent
          open={open}
          setOpen={setOpen}
          contentId={contentId}
          free={free}
        />
      </StyledFullPaper>
      <PreviewModal
        open={previewFlag}
        setOpen={setPreviewFlag}
        providerId={providerData ? providerData.uid : null}
        fileUrl={fileUrl}
        contentData={{
          videoUrl: videoUrl,
          thumbUrl: thumbUrl,
          title: title,
          description: {
            forFreeDescription: forFreeDescription,
            forSubscriptionDescription: forSubscriptionDescription,
          },
          backNumberPrice: backNumberPrice,
          fileUrl: fileUrl,
          fileDescription: fileDescription,
          free: free,
          published: published,
          tag: tag,
        }}
      />
    </>
  );
};

export default Content;

const Input = styled.input.attrs({ type: "file" })`
  height: 0;
  width: 0;
  display: none;
`;

const StyledPreviewButtonWrap = styled.div``;
