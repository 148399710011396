export const getImage = (imageFile) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        const base64 = event.target.result;
        resolve(base64);
      }
    };
    reader.readAsDataURL(imageFile);
  });
};