import React from "react";
import styled, { keyframes } from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import FooterMenu from "../molecules/FooterMenu";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { manualUrl } from "../../static/data";

const fadeIn = keyframes`
    0% {
      opacity:0.1;
    }
    100% {
      opacity:1;
    }
`;

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    max-width: calc(100% - ${(props) => props.theme.drawerWidth});
    margin-left: ${(props) => props.theme.drawerWidth};
  }
`;

const StyledIconButton = styled(IconButton)`
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    display: none;
  }
`;
const AppName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(props) => props.theme.gutter.sp};
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    height: ${(props) => props.theme.gutter.pc};
  }
`;

const DrawerArea = styled.div`
  width: ${(props) => props.theme.drawerWidth};
  background: ${(props) => props.theme.palette.brand};
  color: white;
  height: 100%;
`;

const StyledDrawer = styled(Drawer)`
  background-color: red;
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.2s ease-in-out;
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${(props) => props.theme.zIndex.drawer};
`;
const Content = styled.div`
  padding-top: calc(${(props) => props.theme.spacing(6)}px);
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    max-width: calc(100% - ${(props) => props.theme.drawerWidth});
    margin-left: ${(props) => props.theme.drawerWidth};
    padding-top: ${(props) => props.theme.spacing(7)}px;
    background: #fafafa;
    min-height: 100vh;
  }
`;

const LogOutContent = styled.div`
  padding-top: 0;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    max-width: calc(100% - ${(props) => props.theme.drawerWidth});
    margin-left: ${(props) => props.theme.drawerWidth};
    padding-top: 0;
    background: #ffffff;
    min-height: 100vh;
  }
`;

const BarContents = styled(Toolbar).attrs({
  variant: "dense",
})`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const SimpleAppBar = (props) => {
  return (
    <StyledAppBar color="inherit">
      <Toolbar variant="dense">
      <StyledIconButton
        onClick={() => props.handleMenu()}
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </StyledIconButton>
        <BarContents logined={props.logined}>{props.children}</BarContents>
      </Toolbar>
    </StyledAppBar>
  );
};

export const AppFrame = (props) => {
  const { logined } = props;
  const [open, setOpen] = React.useState(false);
  const handleMenu = () => {
    setOpen(!open);
  };
  const openManual = () => {
    window.open(manualUrl, "_blank");
  };
  return (
    <>
      {logined && (
        <SimpleAppBar handleMenu={handleMenu} logined={logined}>
          {
            <StyledHeaderMenu
              component="div"
              variant="body2"
              onClick={() => openManual()}
            >
              {"新機能・マニュアルはこちら"}
              <OpenInNewIcon />
            </StyledHeaderMenu>
          }
        </SimpleAppBar>
      )}
      <Hidden smUp>
        {open ? (
          <Overlay onClick={handleMenu}>
            <StyledDrawer variant="permanent" open>
              <DrawerArea>
                <AppName>{props.appLogo}</AppName>
                {props.menus}
              </DrawerArea>
            </StyledDrawer>
          </Overlay>
        ) : null}
      </Hidden>
      {logined && (
        <Hidden xsDown>
          <StyledDrawer variant="permanent" open>
            <DrawerArea>
              <AppName>{props.appLogo}</AppName>
              {props.menus}
            </DrawerArea>
          </StyledDrawer>
        </Hidden>
      )}
      {logined && <Content>{props.children}</Content>}
      {!logined && <LogOutContent>{props.children}</LogOutContent>}
      <Hidden smUp>{logined && <FooterMenu />}</Hidden>
    </>
  );
};

const StyledHeaderMenu = styled(Typography)`
  font-weight: bold;
  color: gray;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const StyledToorbar = styled(Toolbar)`
  .MuiToolbar-regular {
    min-height: 56px;
  }
`;
