import React, { useState, useEffect } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
  Elements
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { CardForm } from '../components/molecules/CardForm';
import { stripeApiKey } from '../config';
import { useSelector } from 'react-redux';
import { theme } from '../config';
import  CustomizedSnackbar from '../components/atoms/CustomizedSnackbar';
import functionsProviders from '../firebase/functions/functionsProviders';
import { useHistory } from 'react-router-dom';
import { StyledFullPaper } from '../components/atoms/Paper';

const stripePromise = loadStripe(stripeApiKey);

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: theme.palette.primary.main
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  　}
  }
const CheckoutForm = (props) => {
  const history = useHistory();
  const providerData = useSelector(state => state.providerData);
  const stripeInfo = useSelector(state => state.stripeInfo);
  const profile = providerData ? providerData.profile ? providerData.profile : null :null;
  const guestEmail = profile ? profile.email ? profile.email : null : null;
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    success: true,
  });
  useEffect(()=>{
    setEmail(guestEmail ? guestEmail : '');
  },[profile]);

  const handleSubmit = async (event) => {
    const cardElement = elements.getElement(CardElement);
    event.preventDefault();
    setDisabled(true);
    const token = await stripe.createToken(cardElement).catch((err) => {
      throw err;
    })
    if(token?.error) {
      setSnackState({
        open: true,
        message: token?.error.message,
        success: false,
      })
      setDisabled(false);
      return;
    }
    const response = await functionsProviders.addCardAPI({tokenId:token.token.id}).catch((err)=> {
      setSnackState({
        open: true,
        message: 'カード登録に失敗しました',
        success: false,
      })
      setDisabled(false);
      return;
    });
    if(response.success){
      setSnackState({
        open: true,
        message: 'カード登録が完了しました',
        success: true,
    });
    setDisabled(false);
    setTimeout(() => {
      history.push('/config');
    }, 2000);
    }else {
      setDisabled(false);
      setSnackState({
        open: true,
        message: 'カード登録に失敗しました',
        success: false,
    })
    }
  }
  return(
    <form onSubmit={(e) => handleSubmit(e)}>
      <CardForm
        element={<CardElement options={CARD_OPTIONS}/>}
        stripe={stripe}
        disabled={disabled}
        stripeInfo={stripeInfo}
      />
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState}/>
    </form>
  )
}

const StripeElementsCardForm = (props) => {
  return(
    <StyledFullPaper>
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  </StyledFullPaper>
 );
}

export default StripeElementsCardForm;
