import React, { useEffect } from 'react';
import './App.css';
import { pages } from './Router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { theme, formRunUrl, consumerFrontUrl } from './config';
import FirebaseToRedux from './firebase/FirebaseToRedux';
import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider,
} from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { AppFrame } from './components/templates/AppFrame';
import { MenuList, IconMenu } from './components/molecules/MenuList';
import { useSelector } from 'react-redux';
import { SettingIcon, MyMenuMyPageIcon, LogOutIcon,} from './icons/icon';
import { AppLogo } from './components/atoms/web_kit/AppLogo';
import MovieIcon from '@material-ui/icons/Movie';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import TextsmsIcon from '@material-ui/icons/Textsms';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import liff from '@line/liff';
import { liffId } from './config';
import { useDispatch } from "react-redux";
import { setLiffUrlQueryParam } from './redux/action_reducers/liffUrlQueryParam';
import { TicketIcon } from './icons/icon';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

const muiTheme = createMuiTheme(theme);

const myMenus = (organizationId, logined) => {
  return [
    {
      icon: <MyMenuMyPageIcon />,
      text: 'マイページ',
      path: `/my`,
    },
    {
      icon: <LogOutIcon />,
      text: 'ログアウト',
      path: '/logOut'
    },
  ];
};

const menus = () => {
    return [
      {
        path: `/my`,
        icon: <MovieIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
        text: 'マイメニュー',
      },
      {
        path: `/accounting`,
        icon: <InsertChartIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
        text: '売上管理',
      },
      {
        path: `/config`,
        icon: <AccountCircleIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
        text: '設定',
      },
      {
        path: `/mailmagazine`,
        icon: <MailIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
        text: 'メールマガジン',
      },
      {
        path: `/comment-list`,
        icon: <QuestionAnswerIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
        text: 'コメント一覧',
      },
      {
        path: `/coupon-list`,
        icon: <TicketIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
        text: 'クーポン設定',
      },
      {
        path: `${formRunUrl}`,
        icon: <TextsmsIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
        text: 'お問い合わせ',
      },
    /* {
        path: `${consumerFrontUrl}`,
        icon: <YouTubeIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
        text: '他の動画を閲覧する',
      },*/
      {
        path: `/logout`,
        icon: <ExitToAppIcon style={{color: 'white'}} className={{width:'16px', height:'16px'}} />,
      text: 'ログアウト',
    },
  ];
};


function App() {
  const logined = useSelector(state => state.logined);
  const dispatch = useDispatch();
  useEffect(() => {
    liff.init({ liffId: liffId });
    const liff_url = window.location.href;
    const queryParams = liff_url.split("?");
    if (queryParams?.length >= 2) {
      const listQueryParam = [];
      const keyWords = [/content/, /singleItem/, /providerId/, /providers/];
      for (let i = 0; i < queryParams.length; i++) {
        for (let j = 0; j < keyWords.length; j++) {
          if (queryParams[i].match(keyWords[j])) {
            listQueryParam.push(queryParams[i])
            break;
          }
        }
      }
      dispatch(setLiffUrlQueryParam(listQueryParam));
    }
  }, []);
  return (
    <div className="App" style={{ textAlign: 'left', position: 'relative' }}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <StyledThemeProvider theme={muiTheme}>
            <FirebaseToRedux />
      <Router>
        <AppFrame
          logined={logined}
          appLogo={<AppLogo />}
          menus={<MenuList logined={logined} menus={menus} />}
          iconMenu={<IconMenu logined={logined} myMenus={myMenus} />}
        >
						{pages.map((item) => (
							<Route key={item.path} exact path={item.path} component={() => item.component} />
						))}
        </AppFrame>
				</Router>
        </StyledThemeProvider>
			</ThemeProvider>
      </StylesProvider>
		</div>
	);
}

export default App;
