import React from 'react';
import loadable from '@loadable/component';
import MyPage from './components/pages/MyPage';
import AuthPage from './components/pages/AuthPage';
import KYCPage from './components/pages/KYCPage';
import RegisterBankPage from'./components/pages/RegisterBankPage';
import IsLogined from './utils/IsLogined';
import HistoryPage from './components/pages/HistoryPage';
import ContentsPage from './components/pages/ContentsPage';
import EditContentPage from './components/pages/EditContentPage';
import AccountingPage from './components/pages/AccountingPage';
import LoginPage from './components/pages/LoginPage';
import ConfigPage from './components/pages/ConfigPage';
import RegisterCardPage from './components/pages/RegisterCardPage';
import EditSingleItemPage from './components/pages/EditSingleItemPage';
import ContractPage from './components/pages/ContractPage';
import ContactPage from './components/pages/ContactPage';
import FollowerListPage from './components/pages/FollowerListPage';
import MailMagazinePage from './components/pages/MailMagazinePage';
import EditCouponPage from './components/pages/EditCouponPage';
import CouponDetailPage from './components/pages/CouponDetailPage';
import CouponListPage from './components/pages/CouponListPage';
import CommentListPage from './components/pages/CommentListPage';

export const pages = [
  { path: '/', component: <LoginPage/> },
  { path: '/my', component: <IsLogined><MyPage /></IsLogined> },
  { path: '/auth', component:<AuthPage/> },
  { path: '/kyc', component: <IsLogined><KYCPage/></IsLogined> },
  { path: '/register-bank' , component: <IsLogined><RegisterBankPage/></IsLogined>},
  { path: '/history' , component: <IsLogined><HistoryPage/></IsLogined>},
  { path: '/contents' , component: <IsLogined><ContentsPage/></IsLogined>},
  { path: '/edit-content' , component: <IsLogined><EditContentPage/></IsLogined>},
  { path: '/edit-content/:id' , component: <IsLogined><EditContentPage/></IsLogined>},
  { path: '/accounting' , component: <IsLogined><AccountingPage/></IsLogined>},
  { path: '/config' , component: <IsLogined><ConfigPage/></IsLogined>},
  { path: '/register-card', component: <IsLogined><RegisterCardPage/></IsLogined>},
  { path: '/edit-singleItem' , component: <IsLogined><EditSingleItemPage/></IsLogined>},
  { path: '/edit-singleItem/:id' , component: <IsLogined><EditSingleItemPage/></IsLogined>},
  { path: '/contract' , component: <IsLogined><ContractPage/></IsLogined>},
  { path: '/contact' , component: <IsLogined><ContactPage/></IsLogined>},
  { path: '/follower-list' , component: <IsLogined><FollowerListPage/></IsLogined>},
  { path: '/mailmagazine' , component: <IsLogined><MailMagazinePage/></IsLogined>},
  { path: '/coupon-edit' , component: <IsLogined><EditCouponPage/></IsLogined>},
  { path: '/coupon-detail/:id' , component: <IsLogined><CouponDetailPage/></IsLogined>},
  { path: '/coupon-list' , component: <IsLogined><CouponListPage/></IsLogined>},
  { path: '/comment-list' , component: <IsLogined><CommentListPage/></IsLogined>},
]


