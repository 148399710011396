import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  success: {
    backgroundColor: 'green',
  },
  error: {
    backgroundColor: 'red',
  },
}));

export default function CustomizedSnackbar(props) {
  const { snackState, setSnackState } = props;
  const classes = useStyles();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackState({
      open: false,
      message: null,
      success: snackState.success,
    });
  };
  return (
      <Snackbar
        open={snackState.open}
        message={<span>{snackState.message}</span>}
        anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
        autoHideDuration={3000}
        onClose={handleClose}
        ContentProps={{
          className: snackState.success ? classes.success : classes.error,
        }}
        >
      </Snackbar>
  );
}