import React, { useState, useEffect } from 'react'
import { firestoreConsumers } from '../../firebase/firestore/firestoreConsumers';
import { FollowerCard } from './ProviderCard';
const FollowerItem = (props) => {
  const [profile, setProfile] =  useState(null);
  const consumerId = props.consumerId;

  useEffect(()=>{
    const getConsumerData = async() =>{
      const response = await firestoreConsumers.getData(consumerId).catch((err)=>{
        console.log(err);
      });
      setProfile(response ? response.profile : {
        avatar: '',
        nickName: '',
        webSite: '',
        area: ''
      });
    }
    if(consumerId){
      getConsumerData();
    }
  },[consumerId]);
  return (
    <>
      <FollowerCard
        avatar={profile ? profile.avatar ? profile.avatar : '' : null}
        title={profile ? profile.nickName ? profile.nickName : '' : null}
        subTitle1={profile ? profile.webSite ? profile.webSite : '' : null}
        subTitle2={profile ? profile.area ? profile.area : '' : null}
      />
    </>
  )
}

export default FollowerItem;
