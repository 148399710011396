import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import styled from "styled-components"
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

export const SelectInput = (props) => {
    const title = props.title;
    const value = props.value;
    const helperText = props.helperText;
    const required = props.required? props.required : false;
    const items = props.items? props.items : [];
    const onChange = props.onChange ? props.onChange : ()=>{};
    const disabled = props.disabled;
  return(
    <Div>
    <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
    <StyledForm variant="outlined">
    <StyledSelect
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      {items.map((item,index) => (
        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
      ))}
    </StyledSelect>
    <FormHelperText>{helperText}</FormHelperText>
    </StyledForm>
  </Div>
  );
}

export const SelectSmallInput = (props) => {
  const label = props.label;
  const value = props.value;
  const items = props.items ? props.items : [];
  const onChange = props.onChange ? props.onChange : () => { };
  const disabled = props.disabled || false;
  const helperText = props.helperText ? props.helperText : '';
  return (
    <FormControl variant="outlined">
      <StyledSmallSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </StyledSmallSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>

  );
};

export const ReplySmallInput = (props) => {
  const label = props.label;
  const value = props.value;
  const items = props.items ? props.items : [];
  const onChange = props.onChange ? props.onChange : () => { };
  const disabled = props.disabled || false;
  const helperText = props.helperText ? props.helperText : '';
  return (
    <ReplyStyledForm variant="outlined">
      <ReplySelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </ReplySelect>
      <FormHelperText>{helperText}</FormHelperText>
    </ReplyStyledForm>

  );
};

const StyledSmallSelect = styled(Select)`
  left: 16px;
  margin: 10px 0 0 0;
  min-width: 106px;
`;

const ReplySelect = styled(Select)`
width: 120px;
font-size: 12px;

`;

const StyledSelect = styled(Select)`
    margin:${props => props.theme.spacing(2)}px;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      width:375px;
    }

`
const StyledForm = styled(FormControl)`
    width:375px;
`

const ReplyStyledForm= styled(FormControl)`
.MuiOutlinedInput-input {
  padding: 4px;
  text-align: left
}
`

const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  }
`

const Title = styled(Typography)`
    margin: ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px 0;
    font-weight: 700;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left:${props => props.theme.spacing(1)}px;
          font-size:13.5px;
          color:red;
        }
    }
    //font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #2B2B2B;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      margin: ${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #2B2B2B;
    }
`;
