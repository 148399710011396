import React from 'react';
import styled from "styled-components";
import { StyledHalfFlexWrap} from '../atoms/Paper';
import { SmallProviderAvatar, ProviderAvatar, ConsumerAvatar } from '../atoms/Avatar';
import { FollowButton } from '../atoms/Button';
import ListItem from '@material-ui/core/ListItem';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { SmallChip } from '../atoms/Chip';

export const SmallProviderCard = (props) => {
  const { following, onFollowAction, setFollowing } = props;
  const onClick = props.onClick ? props.onClick : ()=>{};
  const onChange = () =>{
    onFollowAction();
    setFollowing(!following)
  }
  return(
    <CustomizeStyledHalfFlexWrap>
      <StyledListItem button onClick={onClick}>
       <SmallProviderAvatar
          avatar={props.avatar}
          title={props.title}
          subTitle1={props.subTitle1}
          subTitle2={props.subTitle2}
        />
      </StyledListItem>
      <ButtonWrap>
        <FollowButton
          value={following}
          onClick={onChange}
        />
      </ButtonWrap>
    </CustomizeStyledHalfFlexWrap>
  )
}

export const ProviderCard = (props) => {
  const { following, onFollowAction, setFollowing } = props;
  const onChange = () =>{
    onFollowAction();
    setFollowing(!following)
  }
  return(
    <CustomizeStyledHalfFlexWrap>
       <ProviderAvatar
          avatar={props.avatar}
          title={props.title}
          subTitle1={props.subTitle1}
          subTitle2={props.subTitle2}
        />
      <ButtonWrap>
        <FollowButton
          value={following}
          onClick={onChange}
        />
      </ButtonWrap>
    </CustomizeStyledHalfFlexWrap>
  )
}

export const ConsumerCard= (props) => {
  const onClick = props.onClick ? props.onClick : ()=>{};
  return(
    <CustomizeStyledHalfFlexWrap>
       <ConsumerAvatar
          avatar={props.avatar}
          title={props.title}
          subTitle1={props.subTitle1}
          subTitle2={props.subTitle2}
        />
      <ButtonWrap>
        <SettingsOutlinedIcon
          style={{color:'#B5B5B5'}}
          onClick={onClick}
        />
      </ButtonWrap>
    </CustomizeStyledHalfFlexWrap>
  )
}

export const FollowerCard = (props) => {
  return(
    <CustomizeStyledHalfFlexWrap>
       <ConsumerAvatar
          avatar={props.avatar}
          title={props.title}
          subTitle1={props.subTitle1}
          subTitle2={props.subTitle2}
        />
    </CustomizeStyledHalfFlexWrap>
  )
}

const CustomizeStyledHalfFlexWrap = styled(StyledHalfFlexWrap)`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ButtonWrap = styled.div`
position: absolute;
right: ${props => props.theme.spacing(2)}px;
bottom: ${props => props.theme.spacing(5)}px;
`;

const StyledListItem = styled(ListItem)`
padding-left: 0;

`
