import React, { useState } from "react";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Skeleton } from "@material-ui/lab";
import { StyledSmallChip } from "../atoms/Chip";
import LinkIcon from "@material-ui/icons/Link";
import CustomizedSnackbar from "../atoms/CustomizedSnackbar";
import { useSelector } from "react-redux";
import { liffConsumerId } from "../../config";

export const SimpleAddContentCard = React.memo((props) => {
  const type = props.type;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <AddCardWrap>
      <CardActionArea onClick={onClick}>
        <StyledAddCard>
          <DivAdd>
            <DivHorizontal />
            <DivVertical />
          </DivAdd>
        </StyledAddCard>
      </CardActionArea>
      <ButtonWrap>
        {type === "video" ? "動画を追加する" : "イベントを追加する"}
      </ButtonWrap>
    </AddCardWrap>
  );
});

export const SimpleContentCard = React.memo((props) => {
  const providerData = useSelector((state) => state.providerData);
  const type = props.type ? props.type : [];
  const onClick = props.onClick ? props.onClick : () => {};
  const thumbUrl = props ? (props.thumbUrl ? props.thumbUrl : "") : null;
  const title = props ? (props.title ? props.title : "") : null;
  const description = props
    ? props.description
      ? props.description
      : ""
    : null;
  const status = props ? (props.status ? props.status : "") : null;
  const id = props ? (props.id ? props.id : "") : null;
  const [snackState, setSnackState] = useState({
    open: false,
    message: "message",
    success: true,
  });
  const onClipBoardCopy = async () => {
    const contentTitle = type === "video" ? "content" : "singleItem";
    navigator.clipboard.writeText(
      "https://liff.line.me/" +
        liffConsumerId +
        "/?" +
        contentTitle +
        "/" +
        id +
        "?providerId=" +
        providerData?.uid
    );
    setSnackState({
      open: true,
      message:
        type === "video"
          ? "動画の公開用リンクをコピーしました"
          : "イベントの公開用リンクをコピーしました",
      success: true,
    });
    return;
  };
  return (
    <>
      <StyledCard>
        <CardActionArea onClick={onClick}>
          {thumbUrl ? (
            <StyledCardMedia image={thumbUrl} />
          ) : (
            <StyledSkeletonMedia variant="rect" />
          )}
          <StyledCardContent>
            <StyledSmallChip label={status} />
            <StyleTitle variant="body1" component="div" noWrap={true}>
              {title ? title : <Skeleton variant="text" />}
            </StyleTitle>
            <StyleDescription
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {description ? description : <Skeleton variant="text" />}
            </StyleDescription>

          </StyledCardContent>
        </CardActionArea>
        <CustomizedSnackbar
          snackState={snackState}
          setSnackState={setSnackState}
        />
          {thumbUrl && title && description ? (
              <StyledLinkIconButton
                variant="outlined"
                color="primary"
                onClick={onClipBoardCopy}
              >
                <StyledLinkIcon color="primary" />
              </StyledLinkIconButton>
            ) : null}
      </StyledCard>
    </>
  );
});

const StyledCard = styled(Card)`
  position:relative;
  margin: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(1.5)}px 0;
  width: 153px;
  height: 200px;
  box-shadow: none;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 266px;
    height: 257px;
  }
`;

const StyledAddCard = styled(Card)`
  width: 153px;
  height: 108px;
  border: 3px solid ${(props) => props.theme.palette.primary.main};
  box-sizing: border-box;
  box-shadow: 0px 7px 16px -3px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  text-algin: center;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 266px;
    height: 176px;
  }
`;

const StyledCardMedia = styled(CardMedia)`
  width: 153px;
  height: 108px;
  box-shadow: 0px 7px 16px -3px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 250px;
    height: 176px;
    margin: 0 auto;
  }
`;
const StyledSkeletonMedia = styled(Skeleton)`
  width: 153px;
  height: 108px;
  box-shadow: 0px 7px 16px -3px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 266px;
    height: 176px;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 0;
  width: 100%;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: calc(100% - ${(props) => props.theme.spacing(1)}px * 2);
    margin: 0 auto;
  }
`;

const AddCardWrap = styled.div`
  margin: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(1.5)}px 0;
  width: 153px;
  text-align: center;
  height: auto;
  box-shadow: none;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 267px;
    height: 257px;
  }
`;

const DivAdd = styled.div`
  position: relative;
  margin: 32px auto;
  width: 35px;
  height: 35px;
  text-align: center;
  height: auto;
  box-shadow: none;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 57px;
    height: 57px;
    margin: 59px auto;
  }
`;
const DivHorizontal = styled.div`
  position: absolute;
  width: 100%;
  top: 17.5px;
  border: 1px solid ${(props) => props.theme.palette.gray.gray03};
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 100%;
    top: 28.5px;
  }
`;
const DivVertical = styled.div`
  height: 35px;
  width: 0;
  border: 1px solid ${(props) => props.theme.palette.gray.gray03};
  text-align: center;
  margin: 0 auto;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 0;
    height: 57px;
  }
`;
const ButtonWrap = styled(Button)`
    width:auto;
    text-algin: center;
    font-weight: 700;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.palette.primary.main};
    background: none;
    border-radius: 4px;
    margin-top:${(props) => props.theme.spacing(2)}px;
    @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px){
      color: white;
      background:${(props) => props.theme.palette.primary.main};
      margin-top:${(props) => props.theme.spacing(3)}px;
      padding: 3px 22px 3px;
      &:hover {
        background:${(props) => props.theme.palette.primary.light};
      },
  }
`;

const StyleTitle = styled(Typography)`
  font-weight: 700;
  margin-top: ${(props) => props.theme.spacing(0)}px;
  font-size: 12px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    margin-top: ${(props) => props.theme.spacing(0)}px;
    font-size: 14px;
  }
`;

const StyleDescription = styled(Typography)`
  overflow: hidden;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
`;

const StyledLinkIcon = styled(LinkIcon)`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const StyledLinkIconButton = styled(Button)`
  position: absolute;
  width: 23px;
  height: 23px;
  top: 116px;
  left: 87px;
  background: white;
  border-radius: 38px;
  &:action {
    background: rgba(255, 255, 255, 0.8);
  }
  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    top: 184px;
    left: 190px;
  }
`;
