import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { SingleTextInput, checkValidation, MultiLineTextInput } from '../atoms/TextInput';
import { BasicButton, UploadRegistrationButton, ContractRegistrationButton } from '../atoms/Button';
import styled from 'styled-components';
import uploadFile from '../../firebase/storage/uploadFile';
import { useHistory } from 'react-router-dom';
import { accountStatus, bankStatusArray, registerCardStatusArray } from '../../static/data';
import { EditableAvatar } from '../atoms/Avatar';
import { useSelector } from 'react-redux';
import { areaList } from '../../static/data';
import functionsCommon from '../../firebase/functions/functionsCommon';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import moment from 'moment-timezone';
import { SubTitle } from '../atoms/Title';
import { DatePicker } from '../atoms/DatePicker';
import { SelectInput } from '../atoms/SelectInput';
import Collapse from '@material-ui/core/Collapse';
import { EDIT_PROFILE, VALIDATION_MESSAGE, STORAGE_UPLOAD_MESSAGE } from '../../static/messages';

const contactTypeList = [
  { value: 'mail',  label: 'メール' },
  { value: 'other',  label: 'その他(外部フォーム等）' },
]

const Config = () => {
  const history = useHistory();
  const providerData = useSelector(state => state.providerData);
  const contract = useSelector(state => state.contract);
  const planList = useSelector(state => state.planList);
  const stripeInfo = useSelector(state => state.stripeInfo);
  const identificationStatus = stripeInfo ? stripeInfo.identificationStatus : null;
  const customerId = stripeInfo ? stripeInfo.customerId : null;
  const plan = contract ? contract.plan : '';
  const currentPlanInfo = planList ? planList[plan] : null;
  const currentPlanLabel = currentPlanInfo ? currentPlanInfo.label : '';
  const currentPlanCapa = currentPlanInfo ? Number(currentPlanInfo.capacity) * 1000000000 : 0;
  const shopInfo = providerData ? providerData.shopInfo : null;
  const profile = providerData ? providerData.profile : null;
  const userId = providerData ? providerData.uid : null;
  const currentUsage = shopInfo ? shopInfo.currentUsage : 0;
  const [avatar, setAvatar] = useState(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [nickName, setNickName] = useState('');
  const [price, setPrice] = useState('');
  const [address, setAddress] = useState("");
  const [webSite, setWebSite] = useState("");
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [area, setArea] = useState(areaList[0]);
  const [birthday, setBirthday] = useState("2020-01-01");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('')
  const [disabled, setDisabled] = useState(false);
  const [identificationStatusStr, setIdentificationStatusStr] = useState(false);
  const [bankStatus, setBankStatus] = useState(false);
  const [bankStatusStr, setBankStatusStr] = useState(false);
  const [registerCardStatus, setRegisterCardStatus] = useState(false);
  const [registerCardStatusStr, setRegisterCardStatusStr] = useState(false);
  const [snackState, setSnackState] = useState({
    open: false,
    message: 'message',
    sunccess: true,
  });
  const [contactType, setContactType] = useState('mail');
  const [formUrl, setFormUrl] = useState('');

  useEffect(() => {
    const checkProfile = profile ? Object.keys(profile).length : null;
    if (checkProfile) {
      setName(profile.name)
      setArea(profile.area);
      setBirthday(profile.birthday);
      setEmail(profile.email);
      setPhoneNumber(profile.phoneNumber)
    }
    if (shopInfo) {
      setAddress(shopInfo.address);
      setWebSite(shopInfo.webSite);
      setComment(shopInfo.comment);
      setAvatar(shopInfo.avatar);
      setNickName(shopInfo.nickName);
      setPrice(shopInfo.price);
      setContactType(shopInfo?.contactType ? shopInfo?.contactType : 'mail');
      setFormUrl(shopInfo?.formUrl ? shopInfo?.formUrl : '');
    }
    if (stripeInfo) {
      if (identificationStatus === accountStatus[3].status) {
        // 本人確認完了済み
        setIdentificationStatusStr(accountStatus[3].text);
      } else if (identificationStatus === accountStatus[1].status) {
        // 本人確認が完了していません
        setIdentificationStatusStr(accountStatus[1].text);
      } else if (identificationStatus === accountStatus[2].status) {
        // 本人確認実施中
        setIdentificationStatusStr(accountStatus[2].text);
      } else {
        setIdentificationStatusStr('本人確認を行ってください');
      }
      if (stripeInfo?.last4) {
        // 口座登録済み
        setBankStatus(bankStatusArray[0].status);
        setBankStatusStr(bankStatusArray[0].text);
      } else {
        // 口座未登録
        setBankStatus(bankStatusArray[1].status);
        setBankStatusStr(bankStatusArray[1].text);
      }
      if (customerId) {
        // 決済登録済み
        setRegisterCardStatus(registerCardStatusArray[0].status);
        setRegisterCardStatusStr(registerCardStatusArray[0].text);
      } else {
        // 決済未登録
        setRegisterCardStatus(registerCardStatusArray[1].status);
        setRegisterCardStatusStr(registerCardStatusArray[1].text);
      }
    } else {
      setIdentificationStatusStr('本人確認を行ってください');
      setBankStatusStr(bankStatusArray[1].text);
      setRegisterCardStatusStr(registerCardStatusArray[1].text);
    }
  }, [/*profile,*/ shopInfo]);

  const onUploadFile = async (e) => {
    let imageUrl = '';
    let file = e.target.files[0]
    const size = file ? file.size : 0;
    if (size + currentUsage > currentPlanCapa) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.ALEADY_FULL,
        success: false
      })
      return;
    }
    const type = file.type;
    if (!(type.endsWith('mp4') || type.endsWith('jpeg') || type.endsWith('png')) || !type) {
      setSnackState({
        open: true,
        message: STORAGE_UPLOAD_MESSAGE.NOT_APPROPRIATE_FILE_TYPE,
        success: false
      })
      return;
    }
    setUploadingAvatar(true);
    imageUrl = await uploadFile(userId, e, 'profile').catch((err) => {
      setUploadingAvatar(false);
      throw err;
    });
    setAvatar(imageUrl);
    setUploadingAvatar(false);
  }
  const onChangeSelfIntroductions = useCallback((data) => {
    if (String(data).length < 100) {
      setComment(data);
    } else {
      setSnackState({
        open: true,
        message: `${100}文字以内にしてください`,
        success: false,
      });
    }
  }, []);
  const checkValidations = () => {
    /*if (!avatar) {
      return 'プロフィール写真をアップロードしてください';
    }*/
    if (!nickName) {
      return VALIDATION_MESSAGE.NO_NICKNAME;
    } else {
      if (checkValidation('nickName', nickName)) {
        return VALIDATION_MESSAGE.NOT_APPROPRIATE_NICKNAME_FORMAT;
      }
    }
    /*if (!comment) {
      return '自己紹介(100文字以内)を入力してください';
    }*/
    if (!price) {
      return VALIDATION_MESSAGE.NO_MEMBERSHIP_PRICE;
    } else {
      if (checkValidation('membershipPrice', price)) {
        return VALIDATION_MESSAGE.NOT_APPROPRIATE_MEMBERSHIP_PRICE_FORMAT;
      }
    }
    /*if (!webSite) {
      return 'URL（紹介サイト/SNS）を入力してください';
    } else {*/
    if (webSite) {
      if (checkValidation('webSite', webSite)) {
        return VALIDATION_MESSAGE.NOT_APPROPRIATE_WEBSITE_FORMAT;
      }
    }
    /*if (!address) {
      return 'お店の住所を入力してください';
    }*/
    if (!name) {
      return VALIDATION_MESSAGE.NO_NAME;
    } else {
      if (checkValidation('name', name)) {
        return VALIDATION_MESSAGE.NOT_APPROPRIATE_NAME_FORMAT;
      }
    }
    if (!birthday) {
      return VALIDATION_MESSAGE.NO_BIRTHDAY;
    }
    if (!phoneNumber) {
      return VALIDATION_MESSAGE.NO_PHONENUMBER;
    } else {
      if (checkValidation('phoneNumber', phoneNumber)) {
        return VALIDATION_MESSAGE.NOT_APPROPRIATE_PHONENUMBER_FORMAT;
      }
    }
    if (!email) {
      return VALIDATION_MESSAGE.NO_EMAIL;
    } else {
      if (checkValidation('email', email)) {
        return VALIDATION_MESSAGE.NOT_APPROPRIATE_EMAIL_FORMAT;
      }
    }
    if(contactType === 'other'){
      if(!formUrl){
        return VALIDATION_MESSAGE.NO_FORMURL
      }
      if (checkValidation('webSite', formUrl)) {
        return VALIDATION_MESSAGE.NOT_APPROPRIATE_FORMURL_FORMAT;
      }
    }
    return null;
  }

  const onEditProfile = async () => {
    const validationRes = checkValidations();
    if (validationRes) {
      setSnackState({
        open: true,
        message: validationRes,
        success: false,
      });
      return;
    }
    setDisabled(true);
    const profile = {
      name: name,
      birthday: birthday,
      email: email,
      phoneNumber: phoneNumber,
    }
    const shopInfo = {
      avatar: avatar,
      nickName: nickName,
      webSite: webSite,
      address: address,
      comment: comment ? comment : '',
      price: price,
      contactType: contactType,
      formUrl: formUrl,
    }
    await functionsCommon.updateProfile('provider', {
      profile: profile,
      shopInfo: shopInfo
    }).catch((err) => {
      setSnackState({
        open: true,
        message: EDIT_PROFILE.FAILED,
        success: true,
      });
      setDisabled(false);
      return;
    });
    setSnackState({
      open: true,
      message: EDIT_PROFILE.SUCCESS,
      success: true,
    });
    setDisabled(false);
    setTimeout((() => {
      history.push('/my');
    }), 3000);
  };
  const setValidateBirthday = (data) => {
    if (moment(data).diff(moment().startOf('day'), 'days') > 0) {
      setSnackState({
        open: true,
        message: '未来の日付は設定できません',
        success: false
      })
      return;
    }
    setBirthday(moment(data).format('YYYY-MM-DD'));
  }
  const moveToContract = useCallback(() => {
    if (!customerId) {
      setSnackState({
        open: true,
        message: '決済方法を登録してください',
        success: false,
      })
      return;
    }
    history.push('/contract')
  }, [customerId]);

  const moveToKYC = useCallback(() => {
    if (identificationStatus === 'verified') {
      setSnackState({
        open: true,
        message: '本人確認は完了済みです',
        success: true,
      })
      return;
    }
    history.push('/kyc')
  }, [identificationStatus])
  const avatarRef = useRef();
  const handleClickAvatar = () => avatarRef.current.click();

  return (
    <>
      <Grid container justify="" style={{ marginTop: '1vw' }} />
      <Input
        ref={avatarRef}
        id="id_upload_avatarRef"
        onChange={(e) => onUploadFile(e)}
      />
      <UploadRegistrationButton
        title={'本人確認'}
        onClick={() => moveToKYC()}
        required={true}
        status={identificationStatus}
      >
        {identificationStatusStr}
      </UploadRegistrationButton>
      <UploadRegistrationButton
        title={'口座登録'}
        onClick={() => history.push('/register-bank')}
        required={true}
        status={bankStatus}
      >
        {bankStatusStr}
      </UploadRegistrationButton>
      <UploadRegistrationButton
        title={'クレジットカード登録'}
        onClick={() => history.push('/register-card')}
        required={true}
        status={registerCardStatus}
      >
        {registerCardStatusStr}
      </UploadRegistrationButton>
      <ContractRegistrationButton
        title={'ご契約中のプラン'}
        onClick={() => history.push(moveToContract())}
        required={true}
        status={currentPlanLabel ? currentPlanInfo.value ? 'verified' : null : null}
      >
        {currentPlanLabel ? currentPlanLabel : '選択してください'}
      </ContractRegistrationButton>
      <SubTitle text={'公開情報'} />
      <EditableAvatar
        title="プロフィール写真"
        avatar={avatar}
        loading={uploadingAvatar}
        onClick={() => handleClickAvatar()}
      />
      <SingleTextInput
        title={"ニックネーム"}
        value={nickName}
        name={'nickName'}
        validation={true}
        multiline={false}
        setFunction={setNickName}
        required={true}
        placeholder={'xxx料理教室'}
        propsHelperMessage={'使用できない特殊文字を含んでいます'}
        helperText1={'お店・教室の名前として表示されます'}
      />
      <MultiLineTextInput
        multiline={true}
        rows={10}
        title={"自己紹介(100文字以内)"}
        value={comment}
        placeholder={'こんにちは、xxx料理教室です。'}
        setFunction={onChangeSelfIntroductions}
      />
      <SingleTextInput
        multiline={false}
        name={'membershipPrice'}
        title={"月額会員販売価格(円)"}
        placeholder={'3000'}
        validation={true}
        value={price}
        setFunction={setPrice}
        required={true}
        propsHelperMessage={'数値のみで入力してください'}
      />
      <SingleTextInput
        multiline={false}
        name={'webSite'}
        title={"URL（紹介サイト/SNS）"}
        placeholder={'https://xxxx.com'}
        value={webSite}
        setFunction={setWebSite}
        validation={true}
        required={false}
        propsHelperMessage={'無効なURLです。正しい形式で入力してください'}
      />
      <SingleTextInput
        multiline={false}
        title={"お店の住所"}
        placeholder={'東京都港区xx'}
        validation={false}
        value={address}
        setFunction={setAddress}
      />
      <SelectInput
        items={contactTypeList}
        title={'お問い合わせ方法'}
        required={true}
        value={contactType}
        onChange={(e) => setContactType(e.target.value)}
        helperText={'お客様からのお問い合わせ方法を設定します'}
      />
      <Collapse in={(contactType === 'other') ? true : false}>
        <SingleTextInput
          multiline={false}
          title={"お問い合わせフォームURL"}
          placeholder={'https://xxx'}
          validation={false}
          value={formUrl}
          setFunction={setFormUrl}
          required={true}
          helperText1={'お問い合わせフォームを設定します（外部URL、LINE@等）'}
        />
      </Collapse>
      <SubTitle text={'非公開情報'} />
      <SingleTextInput
        multiline={false}
        name={'name'}
        title={"お名前"}
        placeholder={'田中　太郎'}
        value={name}
        setFunction={setName}
        validation={true}
        required={true}
        propsHelperMessage={'使用できない特殊文字を含んでいます'}
      />
      <DatePicker
        title={"生年月日"}
        value={birthday}
        setFunction={setValidateBirthday}
        required={true}
      />
      <SingleTextInput
        multiline={false}
        name={'email'}
        title={"email"}
        placeholder={'xxx@domain.com'}
        value={email}
        setFunction={setEmail}
        validation={true}
        required={true}
        propsHelperMessage={'無効なemailです。正しい形式で入力してください'}
        helperText1={'お客様からのお問い合わせがこちらのメールアドレスに届きます'}
      />
      <SingleTextInput
        multiline={false}
        name={'phoneNumber'}
        title={"電話番号(ハイフンなし)"}
        placeholder={'080xxxxxxxx'}
        value={phoneNumber}
        setFunction={setPhoneNumber}
        validation={true}
        required={true}
        propsHelperMessage={'11桁以内の数値で入力してください'}
        maxNumber={11}
      />
      <BasicButton
        disabled={disabled}
        onClick={() => onEditProfile()}
      >
        {disabled ? '登録中...' : '登録する'}
      </BasicButton>
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
    </>
  )
}

export default Config;

const Input = styled.input.attrs({ type: 'file' })`
height:0;
width: 0;
display:none;
`;