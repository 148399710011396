import React, { useState } from "react";
import styled from "styled-components";
import { bankList } from "../../static/data";
import { useStripe } from "@stripe/react-stripe-js";
//import functionsCasts from '../../firebase/functions/functionsCasts';
import { SubmitButton } from "../atoms/Button";
import CustomizedSnackbar from "../atoms/CustomizedSnackbar";
import functionsProviders from "../../firebase/functions/functionsProviders";
import { useHistory } from "react-router-dom";
import { SingleTextInput, checkValidation } from "../atoms/TextInput";
import { SelectInput } from "../atoms/SelectInput";
import { StyledFullPaper } from "../atoms/Paper";
import { isZenKatakana } from "../../utils/isZenkakuKana";
import isNumber from "../../utils/isNumber";
import { SmallChip } from "../atoms/Chip";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import {
  REGISTER_BANK_MESSAGE,
  INPUT_BANK_MEESAGE,
} from "../../static/messages";

const BankCardForm = (props) => {
  const stripe = useStripe();
  const history = useHistory();
  const stripeInfo = useSelector((state) => state.stripeInfo);
  const last4 = stripeInfo
    ? stripeInfo.last4
      ? stripeInfo.last4
      : null
    : null;
  const [bank, setBank] = useState(bankList[0].value);
  const [branchCode, setBranchCode] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountHolder, setBankAccountHolder] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [snackState, setSnackState] = useState({
    open: false,
    message: "message",
    success: true,
  });
  const checkValidations = () => {
    const list = [];
    list.push(checkValidation("branchCode", branchCode));
    list.push(checkValidation("bankAccountNumber", bankAccountNumber));
    list.push(checkValidation("bankAccountHolder", bankAccountHolder));
    return list.includes(true);
  };
  const handleSubmit = async (e) => {
    console.log("submit");
    e.preventDefault();
    if (!(bank && branchCode && bankAccountHolder && bankAccountNumber)) {
      setSnackState({
        open: true,
        message: INPUT_BANK_MEESAGE.NOT_ENOUGH,
        success: false,
      });
      return;
    }
    if (branchCode.length !== 3) {
      setSnackState({
        open: true,
        message: INPUT_BANK_MEESAGE.ERROR_BRANCH01,
        success: false,
      });
      return;
    }
    if (bankAccountNumber.length !== 7) {
      setSnackState({
        open: true,
        message: INPUT_BANK_MEESAGE.ERROR_BANKNUMBER01,
        success: false,
      });
      return;
    }
    if (!isNumber(branchCode)) {
      setDisabled(false);
      setSnackState({
        open: true,
        message: INPUT_BANK_MEESAGE.ERROR_BRANCH02,
        success: false,
      });
      return;
    }
    if (!isNumber(bankAccountNumber)) {
      setDisabled(false);
      setSnackState({
        open: true,
        message: INPUT_BANK_MEESAGE.ERROR_BANKNUMBER02,
        success: false,
      });
      return;
    }
    if (!isZenKatakana(bankAccountHolder)) {
      setDisabled(false);
      setSnackState({
        open: true,
        message: INPUT_BANK_MEESAGE.ERROR_BANKACCOUNT,
        success: false,
      });
      return;
    }
    const info = {
      country: "JP",
      currency: "jpy",
      routing_number: `${bank}${branchCode}`,
      account_number: `${bankAccountNumber}`,
      account_holder_name: `${bankAccountHolder}`,
      account_holder_type: "individual",
    };
    /** テストモードの時は実際の口座のtokenは発生したないため、かりでテストデータを入れる */
    if (process.env.REACT_APP_ENV !== "production") {
      info.routing_number = "1100000";
      info.account_number = "0001234";
    }
    setDisabled(true);
    const response = await stripe.createToken("bank_account", info);
    if (!response.error) {
      const { token } = response;
      const result = await functionsProviders
        .addBank({ tokenId: token.id })
        .catch((err) => {
          setDisabled(false);
          setSnackState({
            open: true,
            message: REGISTER_BANK_MESSAGE.FAILED,
            success: false,
          });
        });
      setDisabled(false);
      setSnackState({
        open: true,
        message: REGISTER_BANK_MESSAGE.SUCCESS,
        success: true,
      });
      setTimeout(() => {
        history.push("/config");
      }, 2000);
    } else {
      setSnackState({
        open: true,
        message: `${response.error.message}`,
        success: false,
      });
      setDisabled(false);
      return;
    }
  };
  return (
    <StyledFullPaper>
      <form onSubmit={(e) => handleSubmit(e)}>
        <SelectInput
          title="金融機関名"
          required={true}
          items={bankList}
          onChange={(e) => {
            setBank(e.target.value);
          }}
          value={bank}
        />
        <SingleTextInput
          name={"branchCode"}
          validation={true}
          title="支店番号(3桁)"
          label="支店番号(3桁)を入力してください"
          required={true}
          value={branchCode}
          setFunction={setBranchCode}
          propsHelperMessage={"無効な値です。数字3桁を入力してください"}
          maxNumber={3}
        />
        <SingleTextInput
          name={"bankAccountNumber"}
          validation={true}
          title="口座番号(7桁)"
          label="口座番号(7桁)を入力してください"
          required={true}
          maxNumber={7}
          value={bankAccountNumber}
          setFunction={setBankAccountNumber}
          propsHelperMessage={"無効な値です。数字7桁を入力してください"}
        />
        <SingleTextInput
          name={"bankAccountHolder"}
          validation={true}
          title="口座名義(カタカナ)"
          label="口座名義(カタカナ)を入力してください"
          required={true}
          value={bankAccountHolder}
          setFunction={setBankAccountHolder}
          propsHelperMessage={"無効な値です。カタカナを入力してください"}
        />
        {last4 && (
          <Div>
            <Title variant="body1" gutterBottom>
              {"登録されている口座"}
            </Title>
            <StyledChip color={"secondary"} label={`***${last4}`} />
          </Div>
        )}
        <SubmitButton disabled={disabled}>
          {disabled ? "登録中..." : "登録する"}
        </SubmitButton>
        <CustomizedSnackbar
          snackState={snackState}
          setSnackState={setSnackState}
        />
      </form>
    </StyledFullPaper>
  );
};
export default BankCardForm;

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
  }
`;

const Title = styled(Typography)`
  margin: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(2)}px 0;
  font-weight: 700;
  width: calc(240px - ${(props) => props.theme.spacing(2)}px * 3);
  &[data-required="true"] {
    &::after {
      content: "*";
      margin-left: ${(props) => props.theme.spacing(2)}px;
      font-size: 14px;
      color: red;
    }
  }
`;

const StyledChip = styled(SmallChip)`
  font-weight: 700;
  margin: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(2)}px 0;
`;
