import React, { useCallback } from 'react';
import { PageTitle } from '../atoms/Title';
import { StyledFullPaper } from '../atoms/Paper';
import CouponList from '../organisms/CouponList';
import { BasicButton} from '../atoms/Button';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'

const CouponListPage = () => {
  const history = useHistory();
  const moveToCouponEdit = useCallback(()=>{
    history.push('/coupon-edit');
  },[]);
  return (
    <>
      <PageTitle>発行済クーポン一覧</PageTitle>
      <CustomizedStyledFullPaper>
       <BasicButton onClick={moveToCouponEdit}>{'新規クーポン作成'}</BasicButton>
        <CouponList/>
      </CustomizedStyledFullPaper>
    </>
  )
}
export default CouponListPage;

const CustomizedStyledFullPaper = styled(StyledFullPaper)`
min-height: 80vh
`;