import React, { useMemo } from "react";
import { useStripe, useElements, IbanElement, Elements } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import { stripeApiKey } from '../config';
const stripePromise = loadStripe(stripeApiKey);

const StripeElementsForm = (props) => {
  const { children } = props;
  return(
    <Elements stripe={stripePromise}>
      {props.children}
    </Elements>
 );
}

export default StripeElementsForm;