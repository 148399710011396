import { firestore } from './firestore';

const providers = 'providers';
const hidden = 'hidden';
const stripeInfo = 'stripeInfo';
const profile = 'profile';
const contents = 'contents';
const views = 'views';
const likes = 'likes';
const singleItems = 'singleItems';
const contracts = 'contracts';
const providerContract = 'providerContract';
const followers = 'followers';
const mails = 'mails';
const coupons = 'coupons';
const comments = 'comments';
const replies = 'replies';

const ref = {
	collection: () => firestore.collection(providers),
	doc: (guestId) => ref.collection().doc(guestId),
	hiddenRef: (guestId) => ref.collection().doc(guestId).collection(hidden).doc(stripeInfo),
	hiddenProfileRef: (guestId) => ref.collection().doc(guestId).collection(hidden).doc(profile),
	contentRef: (userId) => ref.collection().doc(userId).collection(contents),
	followersRef: (userId) => ref.collection().doc(userId).collection(followers),
	singleItemRef: (userId) => ref.collection().doc(userId).collection(singleItems),
	contentDoc: (userId, contentId) => ref.collection().doc(userId).collection(contents).doc(contentId),
  viewsRef:(providerId, contentId) => ref.collection().doc(providerId).collection(contents).doc(contentId).collection(views),
	singleItemDoc: (userId, contentId) => ref.collection().doc(userId).collection(singleItems).doc(contentId),
	likesRef: (contentId, providerId) =>
		ref.collection().doc(providerId).collection(contents).doc(contentId).collection(likes),
	contractsRef: (providerId) => ref.collection().doc(providerId).collection(contracts).doc(providerContract),
	mailsRef: (providerId) => ref.collection().doc(providerId).collection(mails),
	couponsRef: (providerId) => ref.collection().doc(providerId).collection(coupons),
	couponRef: (providerId, couponId) => ref.collection().doc(providerId).collection(coupons).doc(couponId),
	commentsRef: (providerId, contentId) =>
		ref.collection().doc(providerId).collection(contents).doc(contentId).collection(comments),
	repliesRef: (providerId, contentId, commentId) =>
		ref.commentsRef(providerId, contentId).doc(commentId).collection(replies)
};

export const firestoreProviders = {
	getCollectionRef: () => ref.collection(),
	getHiddenProfileRef: (providerId) => ref.hiddenProfileRef(providerId),
	getContentRef: (providerId) => ref.contentRef(providerId),
	getSingleItemRef: (providerId) => ref.singleItemRef(providerId),
	getNewDocRef: () => ref.newDoc(),
	getRef: (providerId) => ref.doc(providerId),
	getContractRef: (providerId) => ref.contractsRef(providerId),
	getFollwersRef: (providerId) => ref.followersRef(providerId),
	getCouponsRef: (providerId) => ref.couponsRef(providerId),
	getCommentsRef: (providerId, contentId) => ref.commentsRef(providerId, contentId),
	getRepliesRef: (providerId, contentId, commentId) => ref.repliesRef(providerId, contentId, commentId),
  viewsRef:(providerId, contentId) => ref.collection().doc(providerId).collection(contents).doc(contentId).collection(views),
  getViewsRef: (providerId, contentId) => ref.viewsRef(providerId, contentId),
	getAllData: async () => {
		const userDatas = await firestore.getAllDatas(ref.collection()).catch((err) => {
			console.log('firestoreProviders getAllData err');
			throw err;
		});
		return userDatas;
	},
	getData: async (orderId) => {
		const data = await firestore.get(ref.doc(orderId)).catch((err) => {
			console.log('firestoreProviders getRef err');
			throw err;
		});
		return data;
	},
	getContents: async (userId) => {
		return await firestore.getAllDatas(ref.contentRef(userId)).catch((err) => {
			console.log('firestoreProviders getAllData err');
			throw err;
		});
	},
	getContentData: async (userId, contentId) => {
		return await firestore.get(ref.contentDoc(userId, contentId)).catch((err) => {
			console.log('firestoreProviders getContentData err');
			throw err;
		});
	},
	getSingleItemData: async (userId, contentId) => {
		return await firestore.get(ref.singleItemDoc(userId, contentId)).catch((err) => {
			console.log('firestoreProviders getContentData err');
			throw err;
		});
	},
	getLikes: async (contentId, providerId) => {
		return await firestore.getAllDatas(ref.likesRef(contentId, providerId)).catch((err) => {
			console.log('firestoreProviders getAllData err');
			throw err;
		});
	},
	getRefStripeInfo: (guestId) => ref.hiddenRef(guestId),
	getMailsData: async (providerId) => {
		const mailsData = await firestore.getAllDatas(ref.mailsRef(providerId)).catch((err) => {
			console.log('firestoreProviders getAllData err');
			throw err;
		});
		return mailsData;
	},
	getComments: async (providerId, contentId) => {
		const docRef = ref.commentsRef(providerId, contentId);
		return await firestore.getAllDatas(ref.commentsRef(providerId, contentId)).catch((err) => {
			console.log('firestoreProviders getComments err');
			throw err;
		});
	},
	getCouponData: async (userId, couponId) => {
		return await firestore.get(ref.couponRef(userId, couponId)).catch((err) => {
			console.log('firestoreProviders getContentData err');
			throw err;
		});
	},
	getCommentsData: async (uid) => {
		const contents = await firestoreProviders.getContents(uid).catch((err) => {
			console.log(err);
			return [];
		});
		if (contents) {
			let commentList = [];
			await Promise.all(
				contents.map(async (content) => {
					const comments = await firestoreProviders.getComments(uid, content.id).catch((err) => {
						return [];
					});
					if (comments) {
						await Promise.all(
							comments.map(async (comment) => {
								const sendFrom = comment.data.sendFrom;
                let repliedFlag = false;
								const response = await Promise.all([
									sendFrom.get(),
									firestoreProviders.getRepliesRef(uid, content.id, comment.id).get(),
									firestoreProviders.getContentData(uid, content.id)
								]).catch((err) => {
									return [];
								});
                if(response[1].size > 0){
                  for (let index = 0; index < response[1].docs.length; index++) {
                    if(response[1].docs[index].data().sendFrom.id === uid){
                      repliedFlag = true;
                      break;
                    }
                  }
                }
								commentList.push({
									contentId: content.id,
									contentData: response[2] ? response[2] : null,
									commentId: comment.id,
									commentData: comment.data,
									userData: response[0]
										? sendFrom.parent.id === 'consumers'
											? response[0].data().profile
											: response[0].data()
										: null,
									numberOfReply: response[1] ? response[1].size : null,
                  repliedFlag: repliedFlag
								});
							})
						);
					}
				})
			);
			const sortedCommentList = commentList
				? commentList.sort((a, b) => (a.commentData.createAt.seconds > b.commentData.createAt.seconds ? -1 : 1))
				: commentList;
			return sortedCommentList;
		} else {
			return [];
		}
	}
};
