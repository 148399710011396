import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import QRCode from "qrcode.react";
import Typography from '@material-ui/core/Typography';

export const QRcodeModule = (props) => {
  const title = props.title;
  const url = props.url
  return (
    <Div>
      {title ?
        <Title variant="body1" gutterBottom>
          {title}
        </Title> :
        null}
      <QRcodeWrap>
       <QRCode value={url} />
      </QRcodeWrap>
    </Div>
  );
};

const Div = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
    }
  `;

const Title = styled(Typography)`
    margin: ${(props) => props.theme.spacing(3)}px
      ${(props) => props.theme.spacing(2)}px 0;
    font-weight: 700;
    font-size: 14px;
    width: calc(240px - ${(props) => props.theme.spacing(2)}px * 3);
    &[data-required='true'] {
      &::after {
        content: '*';
        margin-left: ${(props) => props.theme.spacing(2)}px;
        font-size: 14px;
        color: red;
      }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
      width:375px;
    }
  `;

const QRcodeWrap = styled.div`
  width:auto;
  margin-top: ${(props) => props.theme.spacing(2)}px;
  margin-left: ${(props) => props.theme.spacing(2)}px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  width:375px;
  margin: ${(props) => props.theme.spacing(2)}px;
}
`;