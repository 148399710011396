import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import moment from "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { StyledFullPaper } from "../atoms/Paper";
import { SelectSmallInput } from "../atoms/SelectInput";
import { FlexWrap } from "../atoms/Paper";
import { firestoreProviders } from "../../firebase/firestore/firestoreProviders";
import { SmallChip } from "../atoms/Chip";
import Typography from "@material-ui/core/Typography";
import { Modal } from "../molecules/Modal";
import { HeadlessTable } from "../atoms/Table";
import { nl2br } from "../../utils/nl2br";

let initMonthList = [{ label: "全て", value: "全て" }];

const MailMagazineHistory = (props) => {
  const rowPerPage = 20;
  const [mails, setMails] = useState(null);
  const [mailsDate, setMailsdata] = useState(null);
  const [targetList, setTargetList] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [monthList, setMonthList] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(initMonthList[0].value);
  const contract = useSelector((state) => state.contract);
  const providerData = useSelector((state) => state.providerData);
  const providerId = providerData ? providerData.uid : null;
  const plan = contract ? contract.plan : null;

  useEffect(() => {
    if (providerId) {
      setMailMagazines(providerId);
    }
  }, [providerId /* selectedMonth*/]);

  // ページネーションの設定
  useEffect(() => {
    if (mails) {
      const first = (page - 1) * rowPerPage + 1;
      const last = page * rowPerPage;
      setTargetList(null);
      const list = [];
      mails.map((order, index) => {
        if (!(index < first - 1 || index > last - 1)) {
          list.push(order);
        }
      });
      setTargetList(list);
    }
  }, [page, mails]);

  // 表示するリストの生成と、フィルタの期間の設定(初回のみ読み込む)
  const setMailMagazines = async (providerId) => {
    const list = [];
    let mailmagazineData = await firestoreProviders.getMailsData(providerId);
    if (mailmagazineData && mailmagazineData.length > 0) {
      mailmagazineData = mailmagazineData.sort((a, b) =>
        a.data.createAt.seconds > b.data.createAt.seconds ? -1 : 1
      );
      mailmagazineData.map((doc) => {
        list.push({
          date: moment(doc.data.createAt.seconds * 1000)
            .tz("Asia/Tokyo")
            .format("YYYY/MM/DD HH:mm"),
          title: doc.data?.title,
          message: doc.data?.message,
          delivery: doc.data?.delivery,
        });
      });
    }
    setCount(Math.ceil(list.length > 0 ? list.length / rowPerPage : 0));
    const sortedList =
      list.length > 0
        ? list.sort((a, b) => {
            return a.date > b.timestamp ? -1 : 1;
          })
        : list;
    setMails(sortedList);
    setMailsdata(sortedList);
    const tmp_monthList = [];
    list.map((doc) => {
      if (tmp_monthList.length > 0) {
        if (
          !tmp_monthList.find(
            (n) => n.label === moment(doc.date).format("YYYY/MM")
          )
        ) {
          tmp_monthList.push({
            label: moment(doc.date).format("YYYY/MM"),
            value: moment(doc.date).format("YYYY/MM"),
          });
        }
      } else {
        tmp_monthList.push({
          label: moment(doc.date).format("YYYY/MM"),
          value: moment(doc.date).format("YYYY/MM"),
        });
      }
    });
    setMonthList(initMonthList.concat(tmp_monthList));
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  // データテーブルのソート
  useEffect(() => {
    if (selectedMonth !== initMonthList[0].value) {
      setMails(
        mailsDate.length > 0
          ? mailsDate.filter(
              (doc) => moment(doc.date).format("YYYY/MM") === selectedMonth
            )
          : []
      );
    } else {
      setMails(mailsDate);
    }
  }, [selectedMonth, mailsDate]);

  const onOpenModal = useCallback(
    (row) => {
      setViewData([
        { title: "メールタイトル", value: row?.title },
        { title: "メール内容", value: nl2br(row?.message) },
      ]);
      setOpen(true);
    },
    [open]
  );

  const onCloseModal = useCallback(() => {
    setViewData([
      { title: "メールタイトル", value: "" },
      { title: "メール内容", value: "" },
    ]);
    setOpen(false);
  }, [open]);

  return (
    <>
      <CustomizeFullPaper>
        <CustomSelectSmallInput>
          <FlexWrap>
            <SmallTitle>{"表示時期（月別）"}</SmallTitle>
            <SelectSmallInput
              items={monthList}
              onChange={(e) => {
                setSelectedMonth(e.target.value);
              }}
              value={selectedMonth}
            />
          </FlexWrap>
        </CustomSelectSmallInput>
        {targetList ? (
          targetList.length > 0 ? (
            <StyledContainer component={Paper}>
              <StyledTable aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell component="th" scope="row">
                      {"日付"}
                    </StyledTableHeadCell>
                    <StyledTableHeadCell align="left">
                      {"タイトル"}
                    </StyledTableHeadCell>
                    <StyledTableHeadCell align="left">
                      {"メッセージ内容"}
                    </StyledTableHeadCell>
                    <StyledTableHeadCell component="th" scope="row">
                      {"配信方法"}
                    </StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {targetList.map((row) => (
                    <TableRow
                      key={row.id}
                      hover
                      style={{ cursor: "pointer" }}
                      onClick={() => onOpenModal(row)}
                    >
                      <StyledTableDataCell component="th" scope="row">
                        {row.date}
                      </StyledTableDataCell>
                      <StyledTableDataCell align="left">
                        <StyledTypography noWrap={true}>
                          {row.title}
                        </StyledTypography>
                      </StyledTableDataCell>
                      <StyledMessageDataCell align="left">
                        <StyledTypography noWrap={true}>
                          {row.message}
                        </StyledTypography>
                      </StyledMessageDataCell>
                      <StyledTableDataCell align="left">
                        {row.delivery === "line" ? (
                          <SmallChip
                            label="LINE"
                            style={{
                              color: "#06C755",
                              border: "1px solid #06C755",
                            }}
                          />
                        ) : row.delivery === "mail" ? (
                          <SmallChip label="メール" color="primary" />
                        ) : (
                          ""
                        )}
                      </StyledTableDataCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
              <StyledTableFooter>
                <StyledPagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  size="small"
                />
              </StyledTableFooter>
            </StyledContainer>
          ) : (
            <ProgressWrap>配信履歴はありません</ProgressWrap>
          )
        ) : (
          <ProgressWrap>
            <CircularProgress />
          </ProgressWrap>
        )}
      </CustomizeFullPaper>
      {open ? (
        <Modal title={"メール詳細"} onClose={onCloseModal}>
          <HeadlessTable rows={viewData} />
        </Modal>
      ) : null}
    </>
  );
};

export default MailMagazineHistory;

const StyledContainer = styled(TableContainer)`
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: none;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    border: none;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
`;

const StyledTable = styled(Table)`
  width: calc(100% - ${(props) => props.theme.spacing(4)}px * 2);
  margin: ${(props) => props.theme.spacing(3)}px auto 0;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const CustomizeFullPaper = styled(StyledFullPaper)`
  text-align: center;
  min-height: 80vh;
  margin-top: ${(props) => props.theme.spacing(4)}px;
  padding: 0;
  box-shadow: none;
  width: calc(100% - ${(props) => props.theme.spacing(2)}px * 2);
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    margin-top: ${(props) => props.theme.spacing(2)}px;
  }
`;

const StyledTableFooter = styled(TableFooter)`
  display: flex;
`;

const StyledPagination = styled(Pagination)`
  margin: ${(props) => props.theme.spacing(2)}px auto;
`;
const ProgressWrap = styled.div`
  margin: ${(props) => props.theme.spacing(4)}px auto;
`;

const StyledTableHeadCell = styled(TableCell).attrs({ align: "left" })`
  color: ${(props) => props.theme.palette.primary.light};
  whitespace: nowrap;
  font-weight: 700;
  font-size: 12px;
  min-width: 80px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    min-width: 200px;
    font-size: 16px;
  }
`;

const StyledTableDataCell = styled(TableCell)`
  font-size: 12px;
  max-width: 120px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    font-size: 16px;
    table-layout: fixed;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
  }
`;
const StyledMessageDataCell = styled(TableCell)`
  font-size: 12px;
  max-width: 200px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    font-size: 16px;
    table-layout: fixed;
    overflow: hidden;
    white-space: nowrap;
    max-width: 600px;
  }
`;
const StyledTypography = styled(Typography)`
  font-size: 12px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    font-size: 16px;
  }
`;

const SmallTitle = styled.div`
  //font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -0.333333px;
  color: #2b2b2b;
  margin: 27px 10px;
  vertical-align: top;
`;

const CustomSelectSmallInput = styled.div`
  text-align: left;
`;
