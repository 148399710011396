import { functions } from '../index';

const functionsProviders = {
  addBank: async (param) => {
    try {
      const registerBankInfo = functions.httpsCallable('onCall-addBank');
      const result = await registerBankInfo(param);
      return result.data;
    } catch (err) {
      console.log('err functionsCasts addBank');
      throw err;
    }
  },
  confirmIdentify: async (param) => {
    try {
      const identify = functions.httpsCallable('onCall-confirmIdentify');
      const result = await identify(param);
      return result.data;
    } catch (err) {
      console.log('err fucntionsCasts confirmIdentify');
      throw err;
    }
  },
  getCastStripeInfo: async () => {
    return await functions.httpsCallable('onCall-getCastStripeInfo')().catch((err) => {
      console.log('err getCastStripeInfo ');
      throw err;
    });
  },
  uploadContent: async (contentId, content) => {
    return await functions.httpsCallable('onCall-uploadContent')({
      contentId: contentId,
      content: content
    }).catch((err) => {
      console.log('err getCastStripeInfo ');
      throw err;
    });
  },
  uploadSingleItem: async (contentId, content) => {
    return await functions.httpsCallable('onCall-uploadSingleItem')({
      contentId: contentId,
      content: content
    }).catch((err) => {
      console.log('err uploadSingleItem ');
      throw err;
    });
  },
  deleteContent: async (contentId) => {
    return await functions.httpsCallable('onCall-deleteContent')({
      contentId: contentId
    }).catch((err) => {
      console.log('err getCastStripeInfo ');
      throw err;
    });
  },
  deleteSingleItem: async (contentId) => {
    return await functions.httpsCallable('onCall-deleteSingleItem')({
      contentId: contentId
    }).catch((err) => {
      console.log('err getCastStripeInfo ');
      throw err;
    });
  },
  addCardAPI: async (param) => {
    try {
      const createCard = functions.httpsCallable('onCall-addCard');
      const result = await createCard({
        createCardParam: param,
        type: 'provider'
      });
      return result.data;
    } catch (error) {
      return {
        success: false,
        error
      };
    }
  },
  updateContract: async (plan) => {
    return await functions.httpsCallable('onCall-updateContract')({
      plan: plan
    }).catch((err) => {
      console.log('err updateContract');
      throw err;
    });
  },
  createMailmagazine: async (data) => {
    return await functions.httpsCallable('onCall-createMailmagazine')({
      title: data.title,
      message: data.message,
      delivery: data.delivery,
    }).catch((err) => {
      console.log('err createMailmagazine');
      throw err;
    });
  },
  updateCoupon: async (data) => {
    return await functions.httpsCallable('onCall-updateCoupon')({
      couponId: data?.couponId ? data?.couponId : null,
      couponName: data.couponName,
      period: Number(data.period),
      discountRate: data.discountRate,
      subscription: data.subscription,
      singleItem: data.singleItem,
      eventItem: data.eventItem
    }).catch((err) => {
      console.log('err creatCoupon');
      throw err;
    });
  },
  inActiveCoupon: async (data) => {
    return await functions.httpsCallable('onCall-inActiveCoupon')({
      couponId: data.couponId
    }).catch((err) => {
      console.log('err creatCoupon');
      throw err;
    });
  },
  deleteCoupon: async (data) => {
    return await functions.httpsCallable('onCall-deleteCoupon')({
      couponId: data.couponId
    }).catch((err) => {
      console.log('err creatCoupon');
      throw err;
    });
  },
  updateComment: async (data) => {
    return await functions.httpsCallable('onCall-updateComment')({
      providerId: data.providerId,
      contentId: data.contentId,
      commentId: data.commentId,
      message: data.message,
      userType: 'provider',
      published: data.published,
      target: data.target
    }).catch((err) => {
      console.log('err functionsProviders updateComment');
      throw err;
    });
  },
  replyComment: async (data) => {
    return await functions.httpsCallable('onCall-updateReply')({
      replyId: data.replyId,
      providerId: data.providerId,
      contentId: data.contentId,
      commentId: data.commentId,
      message: data.message,
      userType: data.userType,
      published: data.published,
    }).catch((err) => {
      console.log('err functionsProviders onCall-updateReply');
      throw err;
    });
  },
  deleteReplyComment: async (data) => {
    return await functions.httpsCallable('onCall-deleteReply')({
      replyId: data.replyId,
      providerId: data.providerId,
      contentId: data.contentId,
      commentId: data.commentId,
    }).catch((err) => {
      console.log('err functionsProviders onCall-deleteReply');
      throw err;
    });
  },
  deleteComment: async (data) => {
    return await functions.httpsCallable('onCall-deleteComment')({
      providerId: data.providerId,
      contentId: data.contentId,
      commentId: data.commentId,
    }).catch((err) => {
      console.log('err functionsProviders deleteComment');
      throw err;
    });
  },
};

export default functionsProviders;
