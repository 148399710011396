import React from 'react';
import Accounting from '../organisms/Accounting';
import { PageTitle } from '../atoms/Title';
import { StyledFullPaper } from '../atoms/Paper';
import styled from 'styled-components';

const AccountingPage = () => {
  return (
    <>
      <PageTitle>売上管理</PageTitle>
      <CustomizeStyledFullPaper>
        <Accounting />
      </CustomizeStyledFullPaper>
    </>
  )
}
export default AccountingPage;

const CustomizeStyledFullPaper = styled(StyledFullPaper)`
padding-top: ${props => props.theme.spacing(4)}px;


`;
