import React from 'react';
import CreditCardForm from '../organisms/CreditCardForm';
import {PageTitle} from '../atoms/Title';

const RegisterCardPage = () => {
  return (
    <div>
      <PageTitle>決済情報登録</PageTitle>
       <CreditCardForm/>
    </div>
  )
}
export default RegisterCardPage;