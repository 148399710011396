import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  root : {
    width:'100vw',
    margin:0,
    alignItems: 'flex-end'
  },
  paper: {
    width: '100vw',
    height: '90vh',
    margin: 0,
    alignItems: 'flex-end'
  },
  transition: {
    position:'fixed',
    bottom: 0,
    outline: 'none'
  },
  deletePaper:{
    width: '90vw',
    margin: 0,
    alignItems: 'flex-end'

  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PreviewDialog =(props) => {
  const classes = useStyles();
  const {open ,setOpen } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        className={{root: classes.root}}
        onClose={handleClose}
        PaperProps={{
          className:classes.paper
        }}
        TransitionProps={{
          className:classes.transition
        }}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{padding:0}}>
          {props.children}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export const DeleteDialog =(props) => {
  const classes = useStyles();
  const {open ,setOpen, disabled } = props;
  const handleClose = () => {
    if(disabled) return;
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        className={classes.root}
        onClose={handleClose}
        PaperProps={{
          className:classes.deletePaper
        }}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {props.children}
        </DialogContent>
      </Dialog>
    </div>
  )
}
