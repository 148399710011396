import React from 'react';
import { PageTitle } from '../atoms/Title';
import Config from '../organisms/Config';
import { StyledFullPaper } from '../atoms/Paper';

const ConfigPage = () => {
  return (
    <>
      <PageTitle>設定</PageTitle>
      <StyledFullPaper>
        <Config />
      </StyledFullPaper>
    </>
  )
}
export default ConfigPage;