import React from 'react';
import { PageTitle } from '../atoms/Title';
import { StyledFullPaper } from '../atoms/Paper';
import FollowerList from '../organisms/FollowerList';
import styled from 'styled-components';



const FollowerListPage = () => {
  return (
    <>
    <PageTitle>フォローワー一覧</PageTitle>
    <CustomizeStyledFullPaper>
        <FollowerList/>
    </CustomizeStyledFullPaper>
    </>
  )
}
export default FollowerListPage;

const CustomizeStyledFullPaper = styled(StyledFullPaper)`
min-height: 60vh;
`

