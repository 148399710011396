
import storage from 'redux-persist/lib/storage';
import { createMuiTheme } from "@material-ui/core";

// 永続化の設定
export const persistConfig = {
  key: 'root', // Storageに保存されるキー名を指定する
  storage, // 保存先としてlocalStorageがここで設定される
  whitelist: ['logined'] // Stateは`todos`のみStorageに保存する
  // blacklist: ['visibilityFilter'] // `visibilityFilter`は保存しない
};

/**
 * firebase
 */

export const firebaseConfig =
  process.env.REACT_APP_ENV === 'production'
    ? {
      apiKey: "AIzaSyAK5zt-GYEj-kIR_Bit0E5YSxGP64By0kI",
      authDomain: "cookon-prod.firebaseapp.com",
      databaseURL: "https://cookon-prod.firebaseio.com",
      projectId: "cookon-prod",
      storageBucket: "cookon-prod.appspot.com",
      messagingSenderId: "818050183509",
      appId: "1:818050183509:web:98dae30c4c6c7ecbcfffdc",
      measurementId: "G-QRJDYN4P8G"
    }
    : {
      apiKey: "AIzaSyBD_P-KANAARZAIMSZ9n2txqD6UlPTQjq0",
      authDomain: "cookon-stg.firebaseapp.com",
      databaseURL: "https://cookon-stg.firebaseio.com",
      projectId: "cookon-stg",
      storageBucket: "cookon-stg.appspot.com",
      messagingSenderId: "1069590226917",
      appId: "1:1069590226917:web:a47ef9538a79fd01b0710c",
      measurementId: "G-VV2SGQP2HB"
    };

/**
 * material ui
 */

export const theme = createMuiTheme({
  palette: {
    brand: '#252B43',
    primary: {
      main: '#2E7DF6',
      dark: '#34314C',
      light: '#5891D5',
    },
    secondary: {
      main: '#FCB853',
      light: '#F6C06F',
    },
    black: {
      textColor: '#2B2B2B',
    },
    gray: {
      gray01: `#F0F0F0`,
      gray02: '#DADADA',
      gray03: '#BABAB9',
      gray04: '#7C7C7C'
    }
  },
  typography: {
    textColor: '#2B2B2B',
    fontSize: 14,
    fontFamily: [
      'Noto Sans JP',
      'sans-serif',
    ].join(','),
  },
  drawerWidth: '240px',
  tableHeight: '440px',
  gutter: { pc: '64px', sp: '56px' }
});

/**
 * LINE
 */

export const lineLoginConfig =
  window.location.hostname === 'localhost'
    ? {
      base_url: 'https://access.line.me/oauth2/v2.1/authorize',
      response_type: 'code',
      client_id: '1654395206',
      redirect_uri: 'http://localhost:3000/auth',
      scope: 'openid%20profile&bot_prompt=aggressive'
    }
    : {
      base_url: 'https://access.line.me/oauth2/v2.1/authorize',
      response_type: 'code',
      client_id: '1654395206',
      redirect_uri:
        process.env.REACT_APP_ENV === 'production'
          ? 'https://provider.cookon.app/auth'
          : 'https://cookon-provider-stg.web.app/auth',
      scope: 'openid%20profile&bot_prompt=aggressive'
    };

export const redirect_uri =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000/auth'
    : process.env.REACT_APP_ENV === 'production'
      ? 'https://provider.cookon.app/auth'
      : 'https://cookon-provider-stg.web.app/auth';

/**
   * stripe
   */
export const stripeApiKey =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_STRIPE_PK_LIVE
    : process.env.REACT_APP_STRIPE_PK_TEST;


export const formRunUrl = 'https://form.run/@contact-cookon';
export const consumerFrontUrl = process.env.REACT_APP_ENV === 'production' ? 'https://consumer.cookon.app' : 'https://cookon-consumer-stg.web.app';
export const privacyPolicyUrl = 'https://cookon-lp.web.app/privacy';

export const liffId = process.env.REACT_APP_ENV === 'production'
  ? '1654395206-ndP2pWex'
  : '1654395206-ogBAJM7y';

export const liffConsumerId = process.env.REACT_APP_ENV === 'production'
  ? '1654395207-A0p4R7P0'
  : '1654395207-YXKkdJ3W';

export const liffUrl = process.env.REACT_APP_ENV === 'production'
  ? 'https://liff.line.me/1654395207-A0p4R7P0'
  : 'https://liff.line.me/1654395207-YXKkdJ3W';


