import React, { useMemo } from 'react';
import { CouponChip } from '../atoms/Chip';
import Card from '@material-ui/core/Card';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { CouponAvatar, CouponMiddleAvatar } from '../atoms/web_kit/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Description } from '../atoms/Text';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment-timezone';
import { couponStatus } from '../../static/data';

export const Coupon = (props) => {
  const {  providerData, couponData, expireData, status } = props;
  const avatar = providerData?.avatar ? providerData.avatar : null;
  const nickName = providerData?.nickName ? providerData.nickName : null;
  const formatedexpireData = expireData ? moment(expireData.seconds * 1000).format('YYYY年MM月DD日') : null;
  const label = couponStatus.find((doc) =>doc.status === status) ? couponStatus.find((doc) =>doc.status === status).label : null;
  return(
  <>
  <StyledCard  variant="outlined">
  <CardActionArea>
   <StatusChip label={label} color={status === 'active' ? '#F2A599' :'#C3C3C3'}/>
   <Title>{couponData ? couponData.couponName : <Skeleton width={160}/>}</Title>
   <ExpireDate>{formatedexpireData ? `${formatedexpireData}まで` : <Skeleton width={160}/>}</ExpireDate>
   <CouponAvatar avatar={avatar}  title={<ProviderName>{nickName ? nickName : <Skeleton width={117}/>}</ProviderName>}/>
   </CardActionArea>
  </StyledCard>
  </>)
}




export const AppliedBigCouponCard  = (props) =>{
  const { providerData, couponData, couponId, expireData } = props;
  const data = providerData?.data ? providerData.data : null;
  const period = couponData ? Number(couponData.period) : null;
  const status = couponData ? couponData.status : null;
  const discountTarget = couponData ? couponData.discountTarget : null;
  const discount = useMemo(()=>{
    let discountScope = '';
    if(discountTarget?.subscription){
      discountScope += '会員' ;
    }
    if(discountTarget?.singleItem){
      discountScope += discountScope.length > 1 ? '、個別購入' : '個別購入';
    }
    if(discountTarget?.eventItem){
      discountScope += discountScope.length > 1 ?  '、イベント' :  'イベント';
    }
    return discountScope
  },[discountTarget]);
  const onClick = props.onClick ? props.onClick : ()=>{};
  const label = couponStatus.find((doc) =>doc.status === status) ? couponStatus.find((doc) =>doc.status === status).label : null;
  

  return(
    <CustomizedStyledBigCard>
      <CardActionArea onClick={onClick}>
      <StyledStatusChip label={label} color={status === 'active' ? '#F2A599' :'#C3C3C3'}/>
      <CouponName component="div">{couponData ? couponData.couponName : <Skeleton width={200}/>}</CouponName>
      <CouponDicount>{couponData ? `${couponData.discountRate}%割引き` :  <Skeleton width={200}/>}</CouponDicount>
      <Description title={'クーポンコード'} text={couponData ? couponId : <Skeleton width={160}/>}/>
      <StyledDescription title={'有効期間'} text={period ? period !== 9999  ? `${period}ヶ月間` : '無期限' : <Skeleton width={160}/>}/>
      <Wrap>
      <CustomizedStyledDescription title={'適用対象'} text={discount ?  discount : <Skeleton width={160}/>}/>
      </Wrap>
      </CardActionArea>
    </CustomizedStyledBigCard>
  )
}

const StyledCard = styled(Card)`
position:relative;
border-radius: 8px;
border: 1px solid #DDDDDD;
margin: ${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
    width: 343px;
}
height: 101px;
`;

const StatusChip = styled(CouponChip)`
position:absolute;
right: ${props => props.theme.spacing(1)}px;
top: ${props => props.theme.spacing(1)}px;
`;

const StyledStatusChip = styled(StatusChip)`
top: ${props => props.theme.spacing(1.5)}px;

`;

const Title = styled(Typography)`
font-size: 13px;
font-weight: bold;
margin-top: ${props => props.theme.spacing(1.5)}px;
margin-left: ${props => props.theme.spacing(2)}px;
`;

const ExpireDate = styled(Typography)`
font-size: 12px;
margin-top: ${props => props.theme.spacing(0.6)}px;
margin-left: ${props => props.theme.spacing(2)}px;
color: #8F8F8F;
`;

const ProviderName = styled(Typography)`
font-size: 13px;
font-weight: bold;
`;

const StyledBigCard = styled(StyledCard)`
border: 1px solid #F2A599;
box-shadow: none;
width: 100%;
height: 380px;
@media screen and (min-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
    width: 343px;
}
`;

const CustomizedStyledBigCard = styled(StyledBigCard)`
border: 1px solid #DDDDDD;
position: relative;
height: auto;
width: 100%;
margin: ${props => props.theme.spacing(2)}px 0;
@media screen and (min-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
    width: 500px;
    margin: ${props => props.theme.spacing(2)}px;
}
`;

const CouponName = styled(Typography)`
font-weight: bold;
color: #F2A599;
font-size:0.8rem;
width:65%;
margin-top: ${props => props.theme.spacing(2)}px;
margin-left: ${props => props.theme.spacing(3)}px;
margin-right: ${props => props.theme.spacing(3)}px;
`;


const CouponDicount = styled(Typography)`
font-weight: bold;
color: #F2A599;
margin-top: 2px;
margin-left: ${props => props.theme.spacing(3)}px;
margin-right: ${props => props.theme.spacing(3)}px;
`;

const ButtonWrap = styled.div`
width: 100%;
text-align: center;
margin-top: ${props => props.theme.spacing(6.5)}px
`;

const StyledDescription =  styled(Description)`
margin-top: 12px;
font-size: 10px
`;

const CustomizedStyledDescription = styled(StyledDescription)`
margin-bottom:  ${props => props.theme.spacing(2)}px;
font-size: 10px;
`;

const Wrap = styled.div`
margin-bottom:18px;
`;
