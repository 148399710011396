import React, { useState } from 'react';
import { SingleTextInput, checkValidation, MultiLineTextInput } from '../atoms/TextInput';
import { InfoAlert } from '../atoms/Alert';
import { BasicButton } from '../atoms/Button';
import functionsProviders from '../../firebase/functions/functionsProviders';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import { useHistory } from 'react-router-dom';
import { SelectInput } from '../atoms/SelectInput';
import { deliveryType } from '../../static/data';
import { SEND_MAIL_MESSAGE } from '../../static/messages';

const MailMagazine = () => {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [delivery, setDelivery] = useState('mail');
  const [snackState, setSnackState] = useState({
    open: false,
    message: '',
    success: true,
  });
  const onSendMail = async () => {
    if(delivery === 'mail'){
    if(!(title && message)){
      setSnackState({
        open: true,
        message: '必須項目を入力してください。',
        success: false
      });
      return;
    }
   } else if(delivery === 'line'){
    if(!(message)){
      setSnackState({
        open: true,
        message: '必須項目を入力してください。',
        success: false
      });
      return;
    }
   }
    setDisabled(true);
    const response = await functionsProviders.createMailmagazine({
      title: title,
      message: message,
      delivery: delivery
    }).catch((err) => {
      setSnackState({
        open: true,
        message: SEND_MAIL_MESSAGE.FAILED,
        success: false
      });
      setDisabled(false);
    });
    if (!response) {
      setSnackState({
        open: true,
        message: SEND_MAIL_MESSAGE.FAILED,
        success: false
      });
      setDisabled(false);
    } else if (response.data) {
      setSnackState({
        open: true,
        message: SEND_MAIL_MESSAGE.SUCCESS,
        success: true
      });
      setDisabled(false);
      setTimeout(() => {
        history.push('/my');
      }, 2000);
    }
  }
  return (
    <>
      <InfoAlert>{'現在の月額会員となっているお客様に対して、メールが一斉配信されます。'}</InfoAlert>
      <SelectInput
       title={'配信方法'}
       label={'配信方法を設定してください'}
       items={deliveryType}
       value={delivery}
       required={true}
       disabled={true}
       onChange={(e)=>setDelivery(e.target.value)}
       />
      {delivery === 'mail' &&
      <SingleTextInput
        title={"タイトル"}
        label={'タイトルを入力してください'}
        value={title}
        validation={true}
        multiline={false}
        setFunction={setTitle}
        required={true}
        placeholder={'メールタイトル'}
      />}
      <MultiLineTextInput
        title={"メッセージ"}
        label={'メッセージを入力してください'}
        value={message}
        validation={true}
        rows={10}
        setFunction={setMessage}
        required={true}
        placeholder={'メッセージ'}
      />
      <BasicButton disabled={disabled} onClick={()=>onSendMail()}>{disabled ? '送信中...' : '送信する'}</BasicButton>
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
    </>
  )
}

export default MailMagazine;