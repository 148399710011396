import React from 'react';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { commentStatus } from '../../static/data';

export default function SimplePopover(props) {
  const { anchorEl, inEdit, tmpReplyMessage, handleCloseEliipsis, isMyPost, messageType, sendFromReply, replyPublished} = props;
  const onDelete =  props.onDelete ? props.onDelete : ()=>{};
  const onEdit = props.onEdit ? props.onEdit : () =>{};
  const onDisclose = props.onDisclose ? props.onDisclose : () =>{};
  const handleClose = () => {
    handleCloseEliipsis();
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isMyPost && <StyledMenuItem onClick={onEdit}>編集</StyledMenuItem>}
        {(!isMyPost && messageType === 'reply') && replyPublished &&
          <StyledMenuItem
            onClick={() =>
              onDisclose({
                isConsumerReply: true,
                replyPublished: false,
                replyId: inEdit.replyId,
                consumerReplyMsg: tmpReplyMessage,
              })
            }>非公開</StyledMenuItem>
        }
        {(sendFromReply === 'consumers' && !replyPublished) &&
          <StyledMenuItem
            onClick={() =>
              onDisclose({
                isConsumerReply: true,
                replyPublished: true,
                replyId: inEdit.replyId,
                consumerReplyMsg: tmpReplyMessage,
              })
            }>公開</StyledMenuItem>
        }
        <StyledMenuItem onClick={onDelete}>削除</StyledMenuItem>
      </Popover>
    </div>
  );
}

export  const  CommentStatusPopover= (props) => {
  const { anchorEl, setAnchorEl, target, published} = props;
  const onEdit = props.onEdit ? props.onEdit : () =>{};
  const handleClose = () => {
    setAnchorEl(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const onChangeStatus =(value) =>{
    if(!value){
      onEdit({
        target: value,
        published: false,
      });
    } else {
      onEdit({
        target: value,
        published: true,
      });
    }
    setAnchorEl(false);
  }

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
      {commentStatus.map((status) =>{
        if(!published){
          if(status.value !== null){
            return  <StyledMenuItem key={status.id} onClick={()=>onChangeStatus(status.value)}>{status.label}</StyledMenuItem>
          }
        } else {
          if(status.value !== target){
            return  <StyledMenuItem key={status.id} onClick={()=>onChangeStatus(status.value)}>{status.label}</StyledMenuItem>
          }
        }
      })}
      </Popover>
    </div>
  );
}

const StyledMenuItem = styled(MenuItem)`
padding:${props=>props.theme.spacing(1)}px ${props=>props.theme.spacing(2)}px;
background: white
`;