import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { SingleTextInput, checkValidation } from "../atoms/TextInput";
import { BasicButton } from "../atoms/Button";
import { SelectInput } from "../atoms/SelectInput";
import { couponPeriodList } from "../../static/data";
import { periodTargets } from "../../static/checkData";
import { SimpleCheckInput } from "../atoms/CheckInput";
import functionsProviders from "../../firebase/functions/functionsProviders";
import CustomizedSnackbar from "../atoms/CustomizedSnackbar";
import { useHistory } from "react-router-dom";
import {
  CREATE_COUPON_MESSAGE,
  COUPON_VALIDATION_MESSAGE,
} from "../../static/messages";

const EditCoupon = () => {
  const [couponName, setCouponName] = useState("");
  const [discountRate, setDiscountRate] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [couponPeriod, setCouponPeriod] = useState();
  const [discountTarget, setDiscountTarget] = useState(periodTargets);
  const [snackState, setSnackState] = useState({
    open: false,
    message: "message",
    sunccess: true,
  });
  const history = useHistory();
  const handleCheck = (e) => {
    let newValue = discountTarget.map((doc) =>
      doc.name === e.target.name
        ? {
            ...doc,
            checked: !doc.checked,
          }
        : doc
    );
    setDiscountTarget(newValue);
  };

  const checkValidations = () => {
    if (!couponName) {
      return COUPON_VALIDATION_MESSAGE.NO_COUPON_NAME;
    }
    if (!discountRate) {
      return COUPON_VALIDATION_MESSAGE.NO_DISCOUNT_RATE;
    } else {
      if (checkValidation("percent", discountRate)) {
        return COUPON_VALIDATION_MESSAGE.NOT_APPROPRIATE_DISCOUNT_RATE_VALUE;
      }
    }
    if (!couponPeriod) {
      return COUPON_VALIDATION_MESSAGE.NO_PERIOD;
    }
    if (!discountTarget.find((doc) => doc.checked === true)) {
      return COUPON_VALIDATION_MESSAGE.NO_TARGET;
    }
    return null;
  };
  const onRegisterCoupon = async () => {
    const validationRes = checkValidations();
    if (validationRes) {
      setSnackState({
        open: true,
        message: validationRes,
        success: false,
      });
      return;
    }
    setDisabled(true);
    const response = await functionsProviders
      .updateCoupon({
        couponName: couponName,
        period: couponPeriod,
        discountRate: discountRate,
        subscription: discountTarget.find((doc) => doc.name === "subscription")
          .checked,
        singleItem: discountTarget.find((doc) => doc.name === "singleItem")
          .checked,
        eventItem: discountTarget.find((doc) => doc.name === "eventItem")
          .checked,
      })
      .catch((err) => {
        setSnackState({
          open: true,
          message: CREATE_COUPON_MESSAGE.FAILED,
          success: false,
        });
        setDisabled(false);
      });
    if (!response) {
      setSnackState({
        open: true,
        message: CREATE_COUPON_MESSAGE.FAILED,
        success: false,
      });
      setDisabled(false);
    } else if (response.data) {
      setSnackState({
        open: true,
        message: CREATE_COUPON_MESSAGE.SUCCESS,
        success: true,
      });
      setDisabled(false);
      setTimeout(() => {
        history.push(`/coupon-detail/${response.data}`);
      }, 2000);
    }
  };
  const onChangeCouponName = (data) => {
    if (String(data).length < 21) {
      setCouponName(data);
    } else {
      setSnackState({
        open: true,
        message: `${20}文字以内にしてください`,
        success: false,
      });
    }
  };
  return (
    <>
      <SingleTextInput
        title={"クーポン名(20文字以内)"}
        value={couponName}
        name={"nickName"}
        validation={true}
        multiline={false}
        setFunction={onChangeCouponName}
        required={true}
        placeholder={"オフライン生徒向けクーポン"}
        propsHelperMessage={"使用できない特殊文字を含んでいます"}
      />
      <SingleTextInput
        title={"割引率（%)"}
        value={discountRate}
        name={"percent"}
        validation={true}
        multiline={false}
        setFunction={setDiscountRate}
        required={true}
        placeholder={"30"}
        propsHelperMessage={"1から100の値を入力してください"}
      />
      <SelectInput
        label="クーポンの期間を設定してください"
        title="クーポン適用期間"
        required={true}
        items={couponPeriodList}
        value={couponPeriod}
        onChange={(e) => {
          setCouponPeriod(e.target.value);
        }}
        helperText={""}
      />
      <SimpleCheckInput
        title="クーポン適用範囲"
        required={true}
        items={discountTarget}
        onChange={(index) => handleCheck(index)}
        helperText={
          "「月額会員」は会費へ割引を適用します。「単品購入」はバックナンバーへ割引を適用します。「イベント」はイベントへ割引を適用します"
        }
      />
      <BasicButton disabled={disabled} onClick={() => onRegisterCoupon()}>
        {disabled ? "登録中..." : "登録する"}
      </BasicButton>
      <CustomizedSnackbar
        snackState={snackState}
        setSnackState={setSnackState}
      />
    </>
  );
};

export default EditCoupon;
