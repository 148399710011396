import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SimpleContentCard } from './ContentCards';
import { contentStausToLabel } from '../../static/data';

const ContentCard = (props) => {
  const history = useHistory();
  const { type } = props;
  const { index, contentData } = props
  const data = contentData ? contentData.data : null;
  const id = contentData ? contentData.id : null;
  const forFreeDescription = useMemo(() => data ? data.description ? data.description.forFreeDescription : null : null, [data]);
  const status =  useMemo(() => data ? data.status : null, [data]);
  const published =  useMemo(() => data ? data.published : false, [data]);
  const title = useMemo(() =>  data ? data.title : null,[data]);
  const thumbUrl = useMemo(() =>  data ? data.thumbUrl : null,[data]);

  const moveToDetail = () => {
    if (id) {
      history.push(type === "video" ? `/edit-content/${id}` : `/edit-singleItem/${id}`)
    }
  }
  /**
   *  動画の公開ステータス
   *  {status ? status !== 'completed' ? <DefaultLabel label={contentStausToLabel.filter((doc) =>doc.value === status)[0] ? contentStausToLabel.filter((doc) =>doc.value === status)[0].label: ''}/>: <DefaultLabel label={published ? '公開中' : '非公開'}/> :<Skeleton className={classes.skeletonChip}/>}
   */
  return (
    <SimpleContentCard
      onClick={() => moveToDetail()}
      type={type}
      id={id}
      thumbUrl={thumbUrl}
      title={title}
      description={forFreeDescription}
      status={status ? status !== 'completed' ? contentStausToLabel.filter((doc) => doc.value === status)[0] ? contentStausToLabel.filter((doc) => doc.value === status)[0].label : '' : published ? '公開中' : '非公開' : null}
    />
  )
}
export default React.memo(ContentCard);