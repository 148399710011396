import React ,{ useEffect } from 'react'
import queryString from 'query-string';
import { useHistory} from 'react-router-dom';
import { customeTokenLogin } from '../../firebase/auth/customTokenLogin';
import {Progress} from '../atoms/Progress';
import { StyledFullPaper } from '../atoms/Paper';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const AuthPage = (code,page) => {
  const history = useHistory();
  const logined = useSelector(state => state.logined)
  const firebaseLogin = async () => {
    const params = queryString.parse(history.location.search);
    const code = params.code;
    const page = params.state;
    if(!code) return;
    await customeTokenLogin(code).catch(err => {
      console.log(err);
      history.push('/error'); //失敗したらトップへ
    });
    history.push(page);
  }
  useEffect(() =>{
    if(!logined)
    firebaseLogin();
  },[]);

  return(
    <div>
      <CustomizeFullPaper>
      <Progress message={'ログイン処理中...'}/>
      </CustomizeFullPaper>
    </div>
  )
}

export default AuthPage;

const CustomizeFullPaper = styled(StyledFullPaper)`
box-shadow: none;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 100vw;
  margin-left: -240px;
  text-align: center;
  height: 100vh;
  margin-top: -16px;
  background:white;
  }
`
