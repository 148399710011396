import { combineReducers } from 'redux';
import logined from './action_reducers/logined';
import providerData from './action_reducers/providerData';
import contents from './action_reducers/contents';
import customerId from './action_reducers/customerId';
import singleItems from './action_reducers/singleItems';
import contract from './action_reducers/contract';
import planList from './action_reducers/planList';
import history from './action_reducers/history';
import stripeInfo from './action_reducers/stripeInfo';
import followers from './action_reducers/followers';
import liffUrlQueryParam from './action_reducers/liffUrlQueryParam';
import coupons from './action_reducers/coupons';
import comments from './action_reducers/comments';

export default combineReducers({
  logined,
  providerData,
  contents,
  customerId,
  singleItems,
  contract,
  planList,
  history,
  stripeInfo,
  followers,
  liffUrlQueryParam,
  coupons,
  comments
});