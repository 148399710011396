import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { CircledAvatar } from '../atoms/web_kit/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { HalfPaper, QuarterPaper } from '../atoms/web_kit/Paper';
import {makeStyles} from '@material-ui/core';
import { firebaseAuthLogout } from '../../firebase/auth/GoogleAuth';

const useStyles = makeStyles((theme) => ({
  MenuListIcon: {
    minWidth: 42,
    color: theme.palette.action.active,
    flexShrink: 0,
    display: 'inline-flex',
    marginTop: '6px'
  },
}));

export const MenuList = (props) => {
  const logined = props.logined;
  const menus = props.menus;
  const organizationsData = useSelector((state) => state.organizationsData);
  const [menusList, setMenusList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const history = useHistory();
  const moveToPage = (item, index) => {
    if (item.text === 'ログアウト') {
      firebaseAuthLogout();
    } else if (item.path.match(/http/)) {
      window.alert('外部のサイトが起動します');
      window.open(item.path, '_blank');
    } else {
      history.push(item.path);
      setSelectedIndex(index)
    }
  };
  const getMenusData = async (organizationsData) => {
    setMenusList(menus(organizationsData ? organizationsData.length > 0 ? organizationsData[0].organizationId : null : null, logined))
  }
  useEffect(() => {
    getMenusData(organizationsData);
  }, [organizationsData]);
  return (
    <List component="nav" aria-label="main mailbox folders">
      {menusList ?
        menusList.length > 0 ?
        menusList.map((item, index) => (
            <StyledItem onClick={() => moveToPage(item, index)} key={index} button selected={index === selectedIndex}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <BoldText primary={item.text} />
            </StyledItem>
          )) : null
        : null}
    </List>
  );
};

export const FloatingMenu = (props) => {
  const menus = props.menus ? props.menus : [];
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <Card>
      <List component="nav" aria-label="main mailbox folders">
        {menus.map((item, index) => (
          <StyledItem onClick={() => onClick(item.viewMode)} key={index} button>
            <Icon>{item.icon}</Icon>
            <Text primary={item.text} />
          </StyledItem>
        ))}
      </List>
    </Card>
  );
};

export const IconMenu = (props) => {
  const logined = props.logined;
  const myMenus = props.myMenus;
  const history = useHistory();
  const [providerPhotoURL, setProviderPhotoURL] = useState(null);
  const [myMenusList, setMyMenusList] = useState([]);
  // 全体の再レンダリング(index.jsx)を防ぐため、providerData、organizationsDataはここで取得
  const providerData = useSelector((state) => state.providerData);
  const organizationsData = useSelector((state) => state.organizationsData);
  const onClick = props.onClick ? props.onClick : () => { };
  const [open, setOpen] = React.useState(false);
  const handleMenu = () => {
    setOpen(!open);
  };
  const moveToPage = (item) => {
    console.log(item.path)
    if (item.path === '/logOut') {
     // googleLogOut();
    } else {
      history.push(item.path);
    }
    setOpen(false);
  };
  const getProviderData = async (providerData) => {
    if (providerData?.uid) {
      // 初回ログイン時、なぜか本コンポが再レンダリングされ、
      // providerDataのdataがnullになるため、一旦直接dbから取得
    //  const local_providerData = await firestoreProviders.getData(providerData?.uid)
    //  setProviderPhotoURL(local_providerData?.photoURL);
    }
  }
  const getMyMenusData = async (organizationsData) => {
    setMyMenusList(myMenus(organizationsData ? organizationsData.length > 0 ? organizationsData[0].organizationId : null : null, logined))
  }
  useEffect(() => {
    getProviderData(providerData);
  }, [providerData]);
  useEffect(() => {
    getMyMenusData(organizationsData);
  }, [organizationsData]);
  return (
    <>
      <CircledAvatar
        src={providerPhotoURL}
        onClick={() => {
          handleMenu();
        }}
      />
      {open ? (
        <MyCard>
          <List component="nav" aria-label="main mailbox folders">
            {myMenusList ?
              myMenusList.length > 0 ?
                myMenusList.map((item, index) => (
                  <StyledItem onClick={() => moveToPage(item)} key={index} button>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <BoldText primary={item.text} />
                  </StyledItem>
                )) : null
              : null}
          </List>
        </MyCard>
      ) : null}
    </>
  );
};


const MyCard = styled(HalfPaper)`
  position:absolute;
  top:${props => props.theme.gutter.sp};
  width:${props => props.theme.drawerWidth};
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    top:${props => props.theme.gutter.pc};
  }
`

const BoldText = styled(ListItemText)`
  > * {
    font-size:14px;
    font-weight:bold;
  }
`

const Card = styled(HalfPaper)`
    position:fixed;
    background:${props => props.theme.palette.primary.dark};
    z-index:${props => props.theme.zIndex.drawer};
    bottom:0;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        width:${props => props.theme.drawerWidth};
    }
`;

const StyledItem = styled(ListItem)({
  width: '100%',
  '& .MuiListItem-root.Mui-selected': {
    backgroundColor: 'gba(0, 0, 0, 0.08);',
  },
})


const Text = styled(ListItemText)`
  color:white;
  > * {
    font-size:14px;
    font-weight:bold;
  }
`

const Icon = styled(ListItemIcon)`
  color:white;
  background: white
`
