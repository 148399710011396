/** action-creator */
export const setContract = (value) => {
  return { type: 'SET_CONTRACT', value: value };
};
export const resetContract = (value) => {
  return { type: 'RESET_CONTRACT', value: value };
};

/** reducer */
const contract = (state = null, action) => {
    switch (action.type) {
      case 'SET_CONTRACT':
        return action.value;
      case 'RESET_CONTRACT':
        return null;
      default:
        return state;
    }
  }

  export default contract;