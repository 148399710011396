import React, { useEffect, useState } from 'react';
import { createMuiTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import ChipInput from 'material-ui-chip-input';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReplySmallInput } from './SelectInput';
import { commentStatus } from '../../static/data';

const useStyles = makeStyles((theme) => ({
  photo: {
    cursor: 'pointer',
    color: '#BABAB9',
  },
  doneIcon: {
    color: '#27AE60'
  },
  errorIcon: {
    color: '#DC3545'
  },
  cancelIcon: {
    cursor: 'pointer',
    color: '#DADADA'
  },
}));

const formLabelsTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#c4c4c4"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});


export const checkValidation = (name, data) => {
  if (data !== '') {
    switch (name) {
      case 'name':
      case 'title':
      case 'nickName':
      case 'lastNameKanji':
      case 'firstNameKanji': {
        const specialString = /[-\/\\^$*+?.()|\[\]{}]/g;
        if (specialString.test(data)) {
          return true;
        } else {
          return false
        }
      }
      case 'katakana':
      case 'lastNameKana':
      case 'firstNameKana':
      case 'bankAccountHolder': {
        if (!/^([\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}])+$/mu.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'webSite': {
        const urlRegex = /https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
        if (!urlRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'email': {
        if (!/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/i.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'phoneNumber': {
        if (!/^[0-9]{1,11}$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'regularPrice':
      case 'memberPrice':
      case 'membershipPrice':
      case 'backNumberPrice':
      case 'forFreeUserPrice':
      case 'forSubscriberPrice':
      case 'defaultStock': {
        if (!/^([1-9]\d*|0)$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'bankAccountNumber': {
        if (!/^\d{7}$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'branchCode': {
        if (!/^\d{3}$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'postalCode': {
        if (!/^(\d{7}|\d{8})$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'address_2': {
        if (!/^[0-9\-]+$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'onChangeCatchCopy': {
        if (!/^.{0,30}$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'symbol': {
        var reg = new RegExp(/[!"#$%&'()☆＊※@☆\*\+\-\.,\/:;<=>?@＠\[\\\]^_`{|}~【】￥「」]/g);
        if (reg.test(data)) {
          return true;
        }
        return false;
      }
      case 'percent': {
        if (!/^([1-9][0-9]?|100)$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      default: {
        break;
      }
    }
  } else {
    return false;
  }
  return false;
};

export const SingleTextInput = (props) => {
  const classes = useStyles();
  const {
    setFunction,
    title,
    value,
    multiline,
    required,
    name,
    propsHelperMessage,
    validation,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxNumber,
    label,
    disabled,
    shrink
  } = props;
  const [helperMessage, setHelperMessage] = useState(null);
  const [error, setError] = useState(false);
  const [valueLength, setValueLength] = useState(value ? value.length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const onChange = (e) => {
    let data = e.target.value;
    if (validation) {
      const validationResult = checkValidation(name, data);
      if (validationResult) {
        setHelperMessage(propsHelperMessage);
        setError(true);
      } else {
        setHelperMessage(null);
        setError(false);
      }
    }
    setFunction(data);
    setValueLength(data?.length);
  };
  const clearText = () => {
    // 文字列クリアの処理
    setFunction("");
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    setValueLength(value ? value.length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <MuiThemeProvider theme={formLabelsTheme}>
        <StyledForm>
          <Field
            onFocus={onFocus}
            onBlur={onBlur}
            error={error}
            classes={{ root: classes.root }}
            placeholder={placeholder}
            onChange={onChange}
            label={label}
            variant="outlined"
            value={value}
            required={required}
            helperText={helperMessage}
            disabled={disabled}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            InputLabelProps={{
              shrink: shrink,
              style: {
                color: error ? '#db3131' : null,
              },
            }}
            InputProps={{
              readOnly: readOnly,
              endAdornment:
                <InputAdornment position="end" disablePointerEvents={false}>
                  {valueLength === 0 ?
                    // 空の時は何も表示しない
                    null :
                    error ?
                      // エラーの時
                      <ErrorIcon
                        className={classes.errorIcon}
                      /> :
                      // 正常な文字列の時
                      inputEvent ?
                        // 入力中
                        <HighlightOffIcon
                          className={classes.cancelIcon}
                          onClick={() => clearText()}
                        />
                        :
                        // 入力中でない時
                        <DoneIcon
                          className={classes.doneIcon}
                        />}
                </InputAdornment>,
            }}
            inputProps={{ maxLength: maxNumber }}
          />
          <HelperWrap>
            {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
            {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
          </HelperWrap>
        </StyledForm>
      </MuiThemeProvider>
    </Div>
  );
}

export const SingleTextRegistration = (props) => {
  const title = props.title;
  const value = props.value
  const helperText = props.helperText;
  const placeholder = props.placeholder;
  const required = props.required ? props.required : false;
  const onChange = props.onChange ? props.onChange : () => { };
  const classes = useStyles();
  const history = useHistory();
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <Field
        helperText={helperText}
        variant="outlined"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{
          readOnly: true,
          endAdornment:
            <InputAdornment position="end">
              <AddIcon
                className={classes.photo}
                onClick={() => history.push('/myMenu')}
              />
            </InputAdornment>,
        }}
      />

    </Div>
  );
}

export const MultiLineTextInput = (props) => {
  const classes = useStyles();
  const rows = props.rows ? props.rows : 5;
  const {
    setFunction,
    title,
    value,
    multiline,
    required,
    name,
    propsHelperMessage,
    validation,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxNumber,
    label
  } = props;
  const [helperMessage, setHelperMessage] = useState(null);
  const [error, setError] = useState(false);
  const [valueLength, setValueLength] = useState(value ? value.length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const onChange = (e) => {
    let data = e.target.value;
    if (validation) {
      const validationResult = checkValidation(name, data);
      if (validationResult) {
        setHelperMessage(propsHelperMessage);
        setError(true);
      } else {
        setHelperMessage(null);
        setError(false);
      }
    }
    setFunction(data);
    setValueLength(data?.length);
  };
  const clearText = () => {
    // 文字列クリアの処理
    setFunction("");
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    setValueLength(value ? value.length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <MuiThemeProvider theme={formLabelsTheme}>
        <StyledForm>
          <Field
            onFocus={onFocus}
            onBlur={onBlur}
            error={error}
            classes={{ root: classes.root }}
            placeholder={placeholder}
            onChange={onChange}
            label={label}
            multiline
            rows={rows}
            variant="outlined"
            value={value}
            required={required}
            helperText={helperMessage}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            InputLabelProps={{
              style: {
                color: error ? '#db3131' : null,
              },
            }}
            InputProps={{
              readOnly: readOnly,
              endAdornment:
                <InputAdornment position="end" disablePointerEvents={false}>
                  {valueLength === 0 ?
                    // 空の時は何も表示しない
                    null :
                    error ?
                      // エラーの時
                      <StyledErrorIcon
                        className={classes.errorIcon}
                      /> :
                      // 正常な文字列の時
                      inputEvent ?
                        // 入力中
                        <StyledHighlightOffIcon
                          className={classes.cancelIcon}
                          onClick={() => clearText()}
                        />
                        :
                        // 入力中でない時
                        <StyledDoneIcon
                          className={classes.doneIcon}
                        />}
                </InputAdornment>,
            }}
            inputProps={{ maxLength: maxNumber }}
          />
          <HelperWrap>
            {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
            {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
          </HelperWrap>
        </StyledForm>
      </MuiThemeProvider>
    </Div>
  );
}

export const ChipTextInput = (props) => {
  const title = props.title;
  const value = props.value
  const helperText = props.helperText;
  const placeholder = props.placeholder;
  const label = props.label;
  const required = props.required ? props.required : false;
  const onChange = props.onChange ? props.onChange : () => { };
  const onDelete = props.onDelete ? props.onDelete : () => { };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <StyledChipInput
        helperText={helperText}
        label={label}
        variant="outlined"
        value={value}
        defaultValue={[]}
        onChange={onChange}
        onDelete={onDelete}
        placeholder={placeholder}
      />
    </Div>
  );
}

export const UrlLink = (props) => {
  const classes = useStyles();
  const rows = props.rows ? props.rows : 5;
  const {
    setFunction,
    title,
    value,
    multiline,
    required,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxNumber,
    label
  } = props;
  const onClick = props.onClick ? props.onClick : () => { };
  const [helperMessage, setHelperMessage] = useState(null);
  const [error, setError] = useState(false);
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <MuiThemeProvider theme={formLabelsTheme}>
        <StyledForm>
          <Field
            classes={{ root: classes.root }}
            placeholder={placeholder}
            variant="outlined"
            value={value}
            helperText={helperMessage}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            InputLabelProps={{
              style: {
                color: error ? '#db3131' : null,
              },
            }}
            InputProps={{
              readOnly: readOnly,
              endAdornment:
                <InputAdornment position="end" disablePointerEvents={false}>
                  {<Tooltip title="リンクのコピー" aria-label="add">
                    <IconButton onClick={onClick}>
                      <StyledFileCopyOutlinedIcon
                        className={classes.doneIcon}
                      />
                    </IconButton>
                  </Tooltip>}
                </InputAdornment>,
            }}
            inputProps={{ maxLength: maxNumber }}
          />
          <HelperWrap>
            {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
            {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
          </HelperWrap>
        </StyledForm>
      </MuiThemeProvider>
    </Div>)
}

export const AvatarText = (props) => {
  const { focused, replyId, replyDisabled, editReplyDisabled, isDeleteReply, numberOfReply, repliedFlag } = props;
  const [onfocusing, setOnFocusing] = useState(false);
  const value = props.value
  const helperText = props.helperText;
  const placeholder = props.placeholder;
  const onChange = props.onChange ? props.onChange : () => { };
  const onClick = props.onClick ? props.onClick : () => { };
  const onCancel = props.onCancel ? props.onCancel : () => { };
  const onPost = props.onPost ? props.onPost : () => { };
  const filterdCommentStatus = commentStatus.filter((doc) => doc.value !== null);
  const setReplyTarget = props?.setReplyTarget ? props?.setReplyTarget : () => { };
  const replyTarget = props?.replyTarget;
  const published = props?.published;
  const disabled = (replyDisabled || editReplyDisabled) ? true : false;
  useEffect(() => {
    setOnFocusing(focused)
  }, [focused])
  const onFocus = () => {
    setOnFocusing(true);
  };
  const onBlur = () => {
    setTimeout(() => {
      setOnFocusing(false);
    }, 500);
  }
  return (
    <Wrap>
      <StyledDiv>
        <div>
          <CircledAvatar src={props.avatar} />
        </div>{
          !disabled ?
            <div>
              <StyledField
                onFocus={onFocus}
                onBlur={onBlur}
                focused={onfocusing}
                helperText={helperText}
                multiline
                variant="outlined"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
              /><AvatarActionArea>
                {(!repliedFlag && !published) ?
                  <ReplySmallInput
                    items={filterdCommentStatus}
                    value={replyTarget}
                    onChange={setReplyTarget}
                  /> :
                  null}
                <AvatarAction onClick={onCancel}>キャンセル</AvatarAction>
                <AvatarAction
                  onClick={() => {
                    if (replyId) {
                      // リプライ実行
                      onPost({replyId: replyId, replyTarget: replyTarget, editReplyMsg: value});
                    } else {
                      onPost({replyTarget: replyTarget});
                    }
                  }}
                >返信</AvatarAction>
              </AvatarActionArea>
            </div> :
            <LoaderWrap>{isDeleteReply ? '削除中' : '投稿中'}<CircularProgress style={{ marginLeft: 8 }} color={'defautl'} size={15} /></LoaderWrap>}
      </StyledDiv>
    </Wrap>
  );
}

const StyledDoneIcon = styled(DoneIcon)`
position: relative;
top: 85px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  position: relative;
  top: 85px;
}`;


const StyledFileCopyOutlinedIcon = styled(FileCopyOutlinedIcon)`
position: relative;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  position: relative;
}
`;

const StyledErrorIcon = styled(ErrorIcon)`
position: relative;
top: 85px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  position: relative;
  top: 85px;
}`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
position: relative;
top: 85px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  position: relative;
  top: 85px;
}`;

const StyledForm = styled.div`
width:100%;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    width:375px;
}
`

const Field = styled(TextField)`
    margin:${props => props.theme.spacing(2)}px;
    width:calc(100% - ${props => props.theme.spacing(2)}px * 2);
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        width:375px;
    }
`
const StyledChipInput = styled(ChipInput)`
margin:${props => props.theme.spacing(2)}px;
width:calc(100% - ${props => props.theme.spacing(2)}px * 2);
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    width:375px;
}
`

const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    }
`

const Title = styled(Typography)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px 0;
font-weight:700;
width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
 &[data-required="true"] {
   &::after{
      content:'*';
      margin-left: ${props => props.theme.spacing(1)}px;
      font-size: 14px;
      color: red;
    }
   @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
    width:calc(100% - ${props => props.theme.spacing(2)}px * 2);
  }
}
//font-family: Noto Sans JP;
font-size: 14px;
font-style: normal;
font-weight: bold;
line-height: 20px;
color: #2B2B2B;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
  font-weight:700;
  width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
  &[data-required="true"] {
      &::after{
        content:'*';
        margin-left:${props => props.theme.spacing(1)}px;
        font-size:14px;
        color:red;
      }
  }
  //font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #2B2B2B;
}
`;

const Wrap = styled.div`
width: auto
`;

const HelperWrap = styled.div`
display: flex;
flex-wrap: wrap;
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
}
`;

const StyledFormHelper = styled(FormHelperText)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
`;

export const CircledAvatar = styled(Avatar)`
width: 24px;
height: 24px;
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px  ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 32px;
  height: 32px
}
`;

const LoaderWrap = styled.div`
margin:18px ${props => props.theme.spacing(2)}px;
padding: 6px ${props => props.theme.spacing(2)}px;
background: ${props => props.theme.palette.gray.gray03};
border-radius:32px;
color: white;
font-weight: bold;
font-size: 12px;
display:flex;
align-items:center
`;

const StyledDiv = styled(Div)`
align-items: flex-start;
width: 100%;
position: relative;
`;

const AvatarActionArea = styled.div`
margin: 0 ${props => props.theme.spacing(2)}px;
text-align: right;
font-size: 12px;
color: #8F8F8F;
display: flex;
justify-content: flex-end;
margin-top: -${props => props.theme.spacing(1)}px;
`;

const AvatarAction = styled(Typography)`
margin-left: ${props => props.theme.spacing(2)}px;
font-size: 12px;
color: #8F8F8F;
cursor: pointer;
&:hover{
  color:#2E7DF6

}
`;

const StyledField = styled(Field)`
.MuiOutlinedInput-multiline {
  padding: 12px 14px;
}
`;
