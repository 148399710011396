import React ,{ useEffect } from 'react'
import styled from 'styled-components';

const Page = (props) => {
  const title = props.title ? props.title : '';
  useEffect(() =>{
      document.title = title;
      window.scrollTo(0, 0);
  },[]);

  return(
    <Div>
      {props.children}
    </Div>
  )
}

const Div = styled.div`

@media (max-width: 1200px) {
  margin:5vw;
  width:auto;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: center;
}
`
export default Page;
