import React from 'react';
import StripeElementsCardForm from '../../stripe/StripeElementsCardForm'

const CreditCardForm = (props) => {
  return(
    <>
      <StripeElementsCardForm/>
    </>
  );
}

export default CreditCardForm;

