import React from 'react';
import MailMagazine from '../organisms/MailMagazine';
import {PageTitle} from '../atoms/Title';
import {CustomizedTabs as Tabs} from '../molecules/Tabs';
import { StyledFullPaper } from '../atoms/Paper';
import styled from 'styled-components';
import MailMagazineHistory from '../organisms/MailMagazineHistory';

const MailMagazinePage = () => {
  const tabList = [
    { label: "メールマガジン配信", content:  <MailMagazine/> },
    { label: "配信履歴", content: <MailMagazineHistory/> },
  ]
  return(
    <div>
        <PageTitle>メールマガジン</PageTitle>
        <CustomizeStyledFullPaper>
        <Tabs contents={tabList} />
        </CustomizeStyledFullPaper>
    </div>
  )
}

export default MailMagazinePage;
const CustomizeStyledFullPaper = styled(StyledFullPaper)`
padding-top: ${props => props.theme.spacing(4)}px;
`;