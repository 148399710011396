import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components"
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MovieIcon from '@material-ui/icons/Movie';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { GoogleIcon, LineIcon } from '../../icons/icon';
import AddIcon from '@material-ui/icons/Add';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  default: {
    boxShadow: 'none',
    borderRadius: '1vw',
    padding: '4vw 9.8vw',
    lineHeight: 1,
    fontWeight: "bold",
    fontSize: '3.2vw',
    width: '70.1vw',
    height: '10.6vw,',
    backgroundColor: '#2699FB',
    color: 'white',

  },
  basic: {
    boxShadow: 'none',
    borderRadius: '1vw',
    padding: '2vw 4vw',
    textAlign: 'center',
    lineHeight: 1,
    fontWeight: "bold",
    fontSize: '1.4rem',
    width: '80%',
    height: '10.6vw,',
    [theme.breakpoints.down('sm')]: {
      width: '70.1vw',
      fontSize: '3.2vw',
      padding: '4vw 9.8vw',
    },
  },
  small: {
    boxShadow: 'none',
    borderRadius: '5.3vw',
    padding: '1.8vw 6.2vw',
    lineHeight: 1,
    fontSize: '2.6vw',
    fontWeight: "bold",
  },
  smallWhite: {
    boxShadow: 'none',
    borderRadius: '5.3vw',
    padding: '1.8vw 6.2vw',
    lineHeight: 1,
    fontSize: '2.6vw',
    fontWeight: "bold",
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.secondary
  },
  floating: {
    borderRadius: '2.7vw',
    width: '87vw',
    position: 'fixed',
    bottom: '6.5vw',
    left: '6.5vw',
    zIndex: 999,
  },
  upload: {
    borderRadius: '2vw',
    width: '39.4vw',
    height: '12.9vw',
    boxShadow: '0 3px 6px #00000029',
    padding: 0,
  },
  label: {
    alignItems: 'flex-end',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.2vw',
    }
  },
  addIcon: {
    cursor: 'pointer',
    color: '#c4c4c4',
  },
  pendingIcon: {
    cursor: 'pointer',
    color: '#FCB853',
  },
  doneIcon: {
    color: '#27AE60'
  },
}));


export const DefaultButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick ? props.onClick : () => { }}
      classes={{ root: classes.default }}
      disableElevation
      disabled={props.disabled ? props.disabled : false}
      variant="contained">{props.children}</Button>
  );
}
export const SecondaryButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick ? props.onClick : () => { }}
      classes={{ root: classes.basic }}
      color="secondary"
      disableElevation
      disabled={props.disabled ? props.disabled : false}
      variant="contained">{props.children}</Button>
  );
}
export const SmallGrayButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick ? props.onClick : () => { }}
      classes={{ root: classes.small }}
      color="secondary"
      disableElevation
      variant="contained">{props.children}
    </Button>
  );
}
export const SmallWhiteButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick ? props.onClick : () => { }}
      classes={{ root: classes.smallWhite }}
      disableElevation
      variant="contained">{props.children}
    </Button>
  );
}

export const FloatingButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      disabled={props.disabled}
      classes={{ root: classes.floating }}
      onClick={props.onClick ? props.onClick : () => { }}
      color="primary"
      disableElevation
      variant="contained">{props.children}
    </Button>
  );
}

export const FloatingSubmitButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      disabled={props.disabled}
      classes={{ root: classes.floating }}
      onClick={props.onClick ? props.onClick : () => { }}
      color="primary"
      type="submit"
      disableElevation
      variant="contained">{props.children}
    </Button>
  );
}



/**
 * 以下Web kitからコピー
 */


export const BasicButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledButton
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={onClick}
      disableElevation
      startIcon={props.disabled ? <CircularProgress size={20} /> : null}>
      {props.children}
    </StyledButton>
  )
}
export const SubmitButton = (props) => {
  return (
    <StyledButton
      disabled={props.disabled}
      onClick={props.onClick ? props.onClick : () => { }}
      disableElevation
      type="submit"
      disabled={props.disabled ? props.disabled : false}
      color="primary"
      variant="contained"
      startIcon={props.disabled ? <CircularProgress size={20} /> : null}>
      {props.children}
    </StyledButton>
  );
}


export const SmallButton = styled(Button).attrs({
  variant: "outlined",
  color: "primary"
})`
    margin:${props => props.theme.spacing(0.5)}px;
`;

export const UploadButton = (props) => {
  const title = props.title;
  const required = props.required ? props.required : false;
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <UpButton startIcon={<CloudUploadIcon />} variant="outlined" color="primary" disabled={disabled} onClick={onClick}>
        {props.children}
      </UpButton>
    </Div>
  )
}

export const UploadRoundButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <RoundButton startIcon={<MovieIcon />} variant="outlined" color="primary" disabled={disabled} onClick={onClick} >
      {props.children}
    </RoundButton>
  )
}

export const IconButton = (props) => {
  const title = props.title;
  const icon = props.icon;
  const required = props.required ? props.required : false;
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <UpButton startIcon={icon} variant="outlined" color="primary" disabled={disabled} onClick={onClick}>
        {props.children}
      </UpButton>
    </Div>
  )
}

export const UploadDottedButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const title = props.title;
  const required = props.required ? props.required : false;
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <DottedButton endIcon={<PictureAsPdfIcon />} variant="outlined" color="primary" disabled={disabled} onClick={onClick} >
        {props.children}
      </DottedButton>
    </Div>
  )
}

export const DownloadButton = (props) => {
  const title = props.title;
  const required = props.required ? props.required : false;
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <UpButton startIcon={<CloudDownloadIcon />} variant="contained" color="primary" disabled={disabled} onClick={onClick}>
        {props.children}
      </UpButton>
    </Div>
  )
}

export const UploadRegistrationButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const title = props.title;
  const status = props.status;
  const required = props.required ? props.required : false;
  const classes = useStyles();
  return (
    <Div>
      <Title
        data-required={required}
        variant="body1"
        gutterBottom
      >
        {title}
      </Title>
      {status === 'verified' ?
        <SimpleVerifiedButton
          endIcon={<DoneIcon
            className={classes.doneIcon}
          />}
          variant="outlined" color="primary"
          disabled={disabled}
          onClick={onClick}
        >
          {props.children}
        </SimpleVerifiedButton> :
        status === 'pending' ?
          <SimplePendingButton
            endIcon={<AddIcon
              className={classes.pendingIcon}
              onClick={onClick}
            />}
            variant="outlined" color="primary"
            disabled={disabled}
            onClick={onClick}
          >
            {props.children}
          </SimplePendingButton> :
          <SimpleButton
            endIcon={<AddIcon
              className={classes.addIcon}
              onClick={onClick}
            />}
            variant="outlined" color="primary"
            disabled={disabled}
            onClick={onClick}
          >
            {props.children}
          </SimpleButton>
      }
    </Div>
  )
}

export const ContractRegistrationButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const title = props.title;
  const status = props.status;
  const required = props.required ? props.required : false;
  const classes = useStyles();
  return (
    <Div>
      <Title
        data-required={required}
        variant="body1"
        gutterBottom
      >
        {title}
      </Title>
      {status === 'verified' ?
        <ContractVerifiedButton
          endIcon={<DoneIcon
            className={classes.doneIcon}
          />}
          variant="outlined" color="primary"
          disabled={disabled}
          onClick={onClick}
        >
          {props.children}
        </ContractVerifiedButton> :
        <SimpleButton
          endIcon={<AddIcon
            className={classes.addIcon}
            onClick={onClick}
          />}
          variant="outlined" color="primary"
          disabled={disabled}
          onClick={onClick}
        >
          {props.children}
        </SimpleButton>
      }
    </Div>
  )
}

export const DeleteContentButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const title = props.title;
  const required = props.required ? props.required : false;
  const fileName = props.fileName
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <FileNameWrap>
        <FileName noWrap={true} component='div'>{fileName}</FileName>
        <DeleteButton onClick={onClick} disabled={disabled}>削除</DeleteButton>
      </FileNameWrap>
    </Div>
  )
}

export const WhiteButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledButton
      color="primary"
      disabled={disabled}
      disableElevation
      onClick={onClick}>
      {props.children}
    </StyledButton>
  )
}
export const WithLineIconButton = (props) => {
  const disabled = props.disabled ? props.disabled : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const classes = useStyles();

  return (
    <LineButton
      classes={{ label: classes.loginLabel }}
      startIcon={<StyledLineIcon><LineIcon style={{position:'absolute'}}/></StyledLineIcon>}
      endIcon={<span />}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      disableElevation
    >
      {props.children}
    </LineButton>
  )
}

export const LikeButton = (props) => {
  const value = props.value ? props.value : false;
  const onClick = props.onClick ? props.onClick : () => { };
  const likeNumber = props ? props.likeNumber ? props.likeNumber : null : null;
  return (
    <StyledRoundIcon
      onClick={onClick}
      variant={'outlined'}
      color={value ? 'secondary' : 'default'}
      value={value}
    >{likeNumber ? <><ThumbUpAltOutlinedIcon />{likeNumber}</> : <ThumbUpAltOutlinedIcon />}
    </StyledRoundIcon>
  )
}

export const FollowButton = (props) => {
  const value = props.value ? props.value : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledFollowedButton
      onClick={onClick}
      variant={value ? 'outlined' : 'contained'}
      color={'secondary'}
    >{!value ? 'フォロー' : 'フォロー中'}
    </StyledFollowedButton>
  )
}


export const MinButton = (props) => {
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <StyledMinButton
      onClick={onClick}
      variant={'contained'}
    >{props.children}
    </StyledMinButton>
  )
}

const StyledButton = styled(Button)`
    width:calc(100% - ${props => props.theme.spacing(4)}px * 2);
    height: 50px;
    font-weight: 700;
    box-shadow: none;
    border-radius: 8px;
    font-size: 16px;
    line-height: 23px;
    margin:${props => props.theme.spacing(4)}px;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        width:327px;

    }
`;
const UpButton = styled(StyledButton)`
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px;
    }
`;

const DeleteButton = styled(Button)`
width: 59px;
height: 40px;
background: #F4F4F4;
border: 1px solid #CED4D4;
box-sizing: border-box;
border-radius: 4px;
`;

const RoundButton = styled(Button)`
width: 208px;
height:49.2px;
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.2);
border-radius: 38px;
border:none;
font-weight: bold;
margin-top: 105.4px;
margin-bottom:${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin-top: 188.59px;
    margin-bottom:${props => props.theme.spacing(2.5)}px;
}
`;

const DottedButton = styled(Button)`
margin:${props => props.theme.spacing(2)}px  ${props => props.theme.spacing(2)}px ;
.MuiButton-endIcon {
  position:absolute;
  right: 12px;
}
.MuiButton-label {
  justify-content: flex-start;
}
width: 100%;
height: 45px;
background: #FFFFFF;
border: 1px dashed #CED4D4;
box-sizing: border-box;
border-radius: 4px;
margin-bottom:${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 375px;
  height: 45px;
}
`;

const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    width: 100%
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      width: auto;
    }
`

const Title = styled(Typography)`
    margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left: ${props => props.theme.spacing(1)}px;
          font-size: 14px;
          color:red;
        }
    }
    //font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #2B2B2B;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      font-family: Noto Sans JP;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 45px;
      color: #2B2B2B;
    }
`;

const FileNameWrap = styled.div`
display: flex;
justify-content: space-between;
width:calc(100% - ${props => props.theme.spacing(2)}px * 2);
margin:${props => props.theme.spacing(4)}px  ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px ;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    width:375px;
}
`

const FileName = styled(Typography)`
    font-weight:500;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
`;

const LineButton = styled(StyledButton)`
height: 54px;
width: 350px;
margin-top:0;
color:#FFFFFF;
    background-color: #00B900;
    font-weight:bold;
    font-family: Arial;
    margin-bottom:15.3vw;
    &:hover {
      background-color: #00B900;
      opacity: 0.7;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
    width: 310px;
    margin-bottom:0;
  }
  `;

const StyledLineIcon = styled.div`
width: 30px;
height: 30px;
position: absolute;
top: 13px;
left: 18px;
`;

const SimpleVerifiedButton = styled(Button)`
margin:${props => props.theme.spacing(2)}px  ${props => props.theme.spacing(2)}px ;
.MuiButton-endIcon {
  position:absolute;
  right: 12px;
}
.MuiButton-label {
  justify-content: flex-start;
}
width: 100%;
height: 45px;
background: #FFFFFF;
color: #27AE60;
border: 1px solid #27AE60;
'&:hover': {
  backgroundColor: #27AE60,
},
box-sizing: border-box;
border-radius: 4px;
margin-bottom:${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 241px;
  height: 45px;
}
`;

const ContractVerifiedButton = styled(Button)`
margin:${props => props.theme.spacing(2)}px  ${props => props.theme.spacing(2)}px ;
.MuiButton-endIcon {
  position:absolute;
  right: 12px;
}
.MuiButton-label {
  justify-content: flex-start;
}
width: 100%;
height: 45px;
background: #FFFFFF;
color: #27AE60;
border: 1px solid #27AE60;
'&:hover': {
  backgroundColor: #27AE60,
},
box-sizing: border-box;
border-radius: 4px;
margin-bottom:${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 300px;
  height: 45px;
}
`;

const SimplePendingButton = styled(Button)`
margin:${props => props.theme.spacing(2)}px  ${props => props.theme.spacing(2)}px ;
.MuiButton-endIcon {
  position:absolute;
  right: 12px;
}
.MuiButton-label {
  justify-content: flex-start;
}
width: 100%;
height: 45px;
background: #FFFFFF;
color: #FCB853;
border: 1px solid #FCB853;
'&:hover': {
  backgroundColor: #FCB853,
},
box-sizing: border-box;
border-radius: 4px;
margin-bottom:${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 241px;
  height: 45px;
}
`;

const SimpleButton = styled(Button)`
margin:${props => props.theme.spacing(2)}px  ${props => props.theme.spacing(2)}px ;
.MuiButton-endIcon {
  position:absolute;
  right: 12px;
}
.MuiButton-label {
  justify-content: flex-start;
}
width: 100%;
height: 45px;
background: #FFFFFF;
color: #c4c4c4;
border: 1px solid #c4c4c4;
box-sizing: border-box;
border-radius: 4px;
margin-bottom: ${props => props.theme.spacing(2)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 241px;
  height: 45px;
}
`;
const StyledRoundIcon = styled(Button)`
height: 34px;
border-radius: 38px;
`;

const MiddleButton = styled(Button)`
width: 161px;
height: 45px;
background: #FFFFFF;
border: 1px solid ${props => props.theme.palette.gray.gray02};
border-radius: 8px;
font-weight: 700;
color : ${props => props.theme.palette.gray.gray05};
margin: ${props => props.theme.spacing(1)}px;
`;

const StyledFollowedButton = styled(Button)`
width: 103px;
height: 30px;
box-shadow: none;
box-sizing: border-box;
border-radius: 40px;
z-index:998;
&:hover {
  box-shadow: none;
}
}
`;
const StyledMinButton = styled(Button)`
width: 54px;
height: 24px;
font-size: 13px;
box-shadow: none;
box-sizing: border-box;
border-radius: 40px;
padiding: 0;
z-index:998;
&:hover {
  box-shadow: none;
}
}
`;