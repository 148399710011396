import React from 'react';
import Page from '../templates/Page';
import Contract from '../organisms/Contract';
import {PageTitle} from '../atoms/Title';

const ContractPage = () => {
  return (
      <div>
        <PageTitle>契約内容</PageTitle>
        <Contract/>
      </div>
  )
}
export default ContractPage;