import React, { useEffect, useState } from 'react';
import { TextField, createMuiTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { SimpleGrid } from '../atoms/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import ChipInput from 'material-ui-chip-input';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config';
import FormHelperText from '@material-ui/core/FormHelperText';

const formLabelsTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#c4c4c4"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderRadius: '5px'
  },
  half: {
    width: '45vw',
    borderRadius: '5px'
  },
  stripeText: {
    width: '70vw',
    padding: '5vw',
    backgroundColor: 'white',
    willChange: 'opacity,transform',
    boxShadow: '0 6px 9px rgba(50,50,93,0.06), 0 2px 5px rgba(0,0,0,0.08), inset 0 1px 0 rgba(0,0,0,0.08)',
    borderRadius: '4px',
    fontSize: '14px'
  },
  searchIcon: {
    position: 'absolute',
    left: '4vw',
    top: '11.5vw',
    color: theme.palette.primary.main
  },
  searchText: {
    width: '100vw',
    background: 'white',
    borderRadius: 0
  },
  input: {
    paddingLeft: '12vw',
    borderWidth: "1px",
    borderRadius: 0,
    fontSize: '8vw',
  }
}));

export const checkValidation = (name, data) => {
  if (data !== '') {
    switch (name) {
      case 'name':
      case 'title':
      case 'nickName':
      case 'lastNameKanji':
      case 'firstNameKanji': {
        const specialString = /[-\/\\^$*+?.()|\[\]{}]/g;
        if (specialString.test(data)) {
          return true;
        } else {
          return false
        }
      }
      case 'katakana':
      case 'lastNameKana':
      case 'firstNameKana':
      case 'bankAccountHolder': {
        if (!/^([\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}])+$/mu.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'webSite': {
        const urlRegex = /https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
        if (!urlRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'email': {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'phoneNumber': {
        if (!/^[0-9]{1,11}$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'regularPrice':
      case 'memberPrice':
      case 'membershipPrice':
      case 'backNumberPrice':
      case 'forSubscriberBackNumberPrice':
      case 'forFreeUserPrice':
      case 'forSubscriberPrice':
      case 'defaultStock': {
        if (!/^([1-9]\d*|0)$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'bankAccountNumber': {
        if (!/^\d{7}$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'branchCode': {
        if (!/^\d{3}$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'postalCode': {
        if (!/^(\d{7}|\d{8})$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'address_2': {
        if (!/^[0-9\-]+$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'onChangeCatchCopy': {
        if (!/^.{0,30}$/.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'symbol': {
        var reg = new RegExp(/[!"#$%&'()☆＊※@☆\*\+\-\.,\/:;<=>?@＠\[\\\]^_`{|}~【】￥「」]/g);
        if (reg.test(data)) {
          return true;
        }
        return false;
      }
      default: {
        break;
      }
    }
  } else {
    return false;
  }
  return false;
};

export const CTextField = (props) => {
  const classes = useStyles();
  const {
    setFunction,
    title,
    value,
    multiline,
    required,
    name,
    propsHelperMessage,
    validation,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxNumber
  } = props;
  const [helperMessage, setHelperMessage] = useState(null);
  const [error, setError] = useState(false);
  const onChange = (e) => {
    let data = e.target.value;
    if (validation) {
      const validationResult = checkValidation(name, data);
      if (validationResult) {
        setHelperMessage(propsHelperMessage);
        setError(true);
      } else {
        setHelperMessage(null);
        setError(false);
      }
    }
    setFunction(data);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
  }, [helperMessage]);
  return (
    <>
      {multiline ? (
        <Grid item xs={12}>
          <MuiThemeProvider theme={formLabelsTheme}>
            <form noValidate autoComplete="off">
              <TextField
                error={error}
                classes={{ root: classes.root }}
                placeholder={placeholder}
                onChange={onChange}
                label={title}
                multiline
                rows={6}
                variant="outlined"
                value={value}
                required={required}
                helperText={helperMessage}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: error ? '#db3131' : '#000',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                }}
                InputProps={{
                  readOnly: readOnly
                }}
                inputProps={{ maxLength: maxNumber }}
              />
              <HelperWrap>
                {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
                {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
              </HelperWrap>
            </form>
          </MuiThemeProvider>
        </Grid>
      ) : (
          <Grid item xs={12}>
            <MuiThemeProvider theme={formLabelsTheme}>
              <form noValidate autoComplete="off">
                <TextField
                  error={error}
                  classes={{ root: classes.root }}
                  placeholder={placeholder}
                  onChange={(e) => onChange(e)}
                  label={title}
                  variant="outlined"
                  value={value}
                  required={required}
                  helperText={helperMessage}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: error ? '#db3131' : '#000',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    },
                  }}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  inputProps={{ maxLength: maxNumber }}
                />
                <HelperWrap>
                  {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
                  {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
                </HelperWrap>
              </form>
            </MuiThemeProvider>
          </Grid>
        )
      }
    </>
  )
}

export const CTextEmailField = (props) => {
  const classes = useStyles();
  const {
    setFunction,
    title,
    value,
    multiline,
    required,
    name,
    placeholder,
    propsHelperMessage,
    validation,
  } = props;
  const [helperMessage, setHelperMessage] = useState(null);
  const [error, setError] = useState(false);
  const onChange = (e) => {
    let data = e.target.value;
    if (validation) {
      const validationResult = checkValidation(name, data);
      if (validationResult) {
        setHelperMessage(propsHelperMessage);
        setError(true);
      } else {
        setHelperMessage(null);
        setError(false);
      }
    }
    setFunction(data);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
  }, [helperMessage]);
  return (
    <>
      {multiline ? (
        <SimpleGrid item xs={props.size}>
          <MuiThemeProvider theme={formLabelsTheme}>
            <form noValidate autoComplete="off">
              <TextField
                error={error}
                placeholder={placeholder}
                classes={{ root: classes.root }}
                onChange={onChange}
                label={title}
                multiline
                rows={6}
                inputProps={{
                  type: "email"
                }}
                variant="outlined"
                value={value}
                required={required}
                helperText={helperMessage}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: error ? '#db3131' : '#000',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                }}
              />
            </form>
          </MuiThemeProvider>
        </SimpleGrid>
      ) : (
          <SimpleGrid item xs={props.size}>
            <MuiThemeProvider theme={formLabelsTheme}>
              <form noValidate autoComplete="off">
                <TextField
                  error={error}
                  classes={{ root: classes.root }}
                  onChange={onChange}
                  label={title}
                  placeholder={placeholder}
                  inputProps={{
                    type: "email"
                  }}
                  variant="outlined"
                  value={value}
                  required={required}
                  helperText={helperMessage}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: error ? '#db3131' : '#000',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    },
                  }}
                />
              </form>
            </MuiThemeProvider>
          </SimpleGrid>
        )
      }
    </>
  )
}

export const CTextStripeEmailField = (props) => {
  const classes = useStyles();
  const {
    setFunction,
    title,
    value,
    multiline
  } = props;
  const onChange = async (e) => {
    var data = e.target.value;
    setFunction(data);
  };
  return (
    <>
      {multiline ? (
        <TextField
          onChange={onChange}
          label={title}
          multiline
          rows={6}
          inputProps={{
            className: classes.stripeText,
            type: "email",
            borderStyle: 'none'
          }}
          variant="outlined"
          value={value}
        />
      ) : (
          <TextField
            onChange={onChange}
            label={title}
            inputProps={{
              className: classes.stripeText,
              type: "email",
              borderStyle: 'none'
            }}
            variant="outlined"
            value={value}
          />
        )
      }
    </>
  )
}

export const CSearchField = (props) => {
  const classes = useStyles();
  const {
    setFunction,
    title,
    value,
    multiline
  } = props;

  const onChange = (e) => {
    var data = e.target.value
    setFunction(data);
  };
  return (
    <Wrap>
      <TextField
        classes={{ root: classes.searchText }}
        onChange={(e) => onChange(e)}
        label={title}
        variant="outlined"
        inputProps={{
          className: classes.input
        }}
        value={value}
      />
      <SearchIcon className={classes.searchIcon} />
    </Wrap>
  )
}

export const CTextNumberField = (props) => {
  const classes = useStyles();
  const {
    setFunction,
    title,
    value,
    multiline,
    placeholder,
    propsHelperMessage,
    validation,
    required,
    name,
    maxNumber
  } = props;
  const [helperMessage, setHelperMessage] = useState(null);
  const [error, setError] = useState(false);
  const onChange = (e) => {
    let data = e.target.value;
    if (validation) {
      const validationResult = checkValidation(name, data);
      if (validationResult) {
        setHelperMessage(propsHelperMessage);
        setError(true);
      } else {
        setHelperMessage(null);
        setError(false);
      }
    }
    setFunction(data);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
  }, [helperMessage]);
  return (
    <>
      {multiline ? (
        <SimpleGrid item xs={props.size}>
          <MuiThemeProvider theme={formLabelsTheme}>
            <form noValidate autoComplete="off">
              <TextField
                error={error}
                placeholder={placeholder}
                classes={{ root: classes.root }}
                onChange={onChange}
                label={title}
                multiline
                rows={6}
                /*type="number" 不具合が多いため一旦コメントアウト */
                variant="outlined"
                value={value}
                required={required}
                helperText={helperMessage}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: error ? '#db3131' : '#000',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                }}
                inputProps={{
                  maxLength: maxNumber,
                  type: 'number'
                }}
              />
            </form>
          </MuiThemeProvider>
        </SimpleGrid>
      ) : (
          <SimpleGrid item xs={props.size}>
            <MuiThemeProvider theme={formLabelsTheme}>
              <form noValidate autoComplete="off">
                <TextField
                  error={error}
                  placeholder={placeholder}
                  classes={{ root: classes.root }}
                  onChange={onChange}
                  label={title}
                  /*type="number" 不具合が多いため一旦コメントアウト */
                  variant="outlined"
                  value={value}
                  required={required}
                  helperText={helperMessage}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: error ? '#db3131' : '#000',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    },
                  }}
                  inputProps={{
                    maxLength: maxNumber,
                    type: 'number'
                   }}
                />
              </form>
            </MuiThemeProvider>
          </SimpleGrid>
        )
      }
    </>
  )
}

export const CTextNumberHalfField = (props) => {
  const classes = useStyles();
  const {
    setFunction,
    title,
    value,
    multiline,
    placeholder,
    name,
    propsHelperMessage,
    validation,
    required,
    maxNumber
  } = props;
  const [helperMessage, setHelperMessage] = useState(null);
  const [error, setError] = useState(false);
  const onChange = (e) => {
    let data = e.target.value;
    if (validation) {
      const validationResult = checkValidation(name, data);
      if (validationResult) {
        setHelperMessage(propsHelperMessage);
        setError(true);
      } else {
        setHelperMessage(null);
        setError(false);
      }
    }
    setFunction(data);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
  }, [helperMessage]);
  return (
    <>
      {multiline ? (
        <SimpleGrid item xs={props.size}>
          <MuiThemeProvider theme={formLabelsTheme}>
            <form noValidate autoComplete="off">
              <TextField
                error={error}
                classes={{ root: classes.half }}
                placeholder={placeholder}
                onChange={onChange}
                label={title}
                multiline
                rows={6}
                /*type="number" 不具合が多いため一旦コメントアウト */
                variant="outlined"
                value={value}
                required={required}
                helperText={helperMessage}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: error ? '#db3131' : '#000',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                }}
                inputProps={{ maxLength: maxNumber }}
              />
            </form>
          </MuiThemeProvider>
        </SimpleGrid>
      ) : (
          <SimpleGrid item xs={props.size}>
            <MuiThemeProvider theme={formLabelsTheme}>
              <form noValidate autoComplete="off">
                <TextField
                  error={error}
                  classes={{ root: classes.half }}
                  placeholder={placeholder}
                  onChange={onChange}
                  label={title}
                  /*type="number" 不具合が多いため一旦コメントアウト */
                  variant="outlined"
                  value={value}
                  required={required}
                  helperText={helperMessage}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: error ? '#db3131' : '#000',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    },
                  }}
                  inputProps={{ maxLength: maxNumber }}
                />
              </form>
            </MuiThemeProvider>
          </SimpleGrid>
        )
      }
    </>
  )
}

export const TagTextField = (props) => {
  const classes = useStyles();
  const {
    setFunction,
    title,
    value,
  } = props;
  const onChange = async (e) => {
    var data = e.target.value
    setFunction(data);
  };
  return (
    <>
      <SimpleGrid item xs={props.size}>
        <ChipInput
          classes={{ root: classes.half }}
          inputProps={{
            maxLength: 7,
          }}
          onChange={onChange}
          label={title}
          multiline
          rows={6}
          type="number"
          variant="outlined"
          value={value}
        />
      </SimpleGrid>
    </>
  )
}


const Wrap = styled.div`
width: auto
`;

const HelperWrap = styled.div`
display: flex;
flex-wrap: wrap;
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
}
`;

const StyledFormHelper = styled(FormHelperText)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
`;
