
import React from 'react'
import styled from 'styled-components';
import ProviderItem from '../molecules/ProviderItem';
import { PreviewDialog } from '../atoms/Dialog';
import { DownloadButton } from '../atoms/Button';
import { nl2br } from '../../utils/nl2br';
import 'video-react/dist/video-react.css';
import VideoComponent from '../molecules/VideoComponent';
import {QuarterStyledePaper, StyledHalfFlexWrap} from '../atoms/Paper';
import {BodyTitle,BodyText,Caption} from '../atoms/Text';
import { Skeleton } from '@material-ui/lab';
import { LikeButton } from '../atoms/Button';
import { BasicChip } from '../atoms/Chip';

const PreviewModal = (props) => {
  const { providerId, fileUrl, contentData } = props
  return (
    <>
      <PreviewDialog open={props.open} setOpen={props.setOpen} >
        <QuarterStyledePaper>
           <VideoComponent
              contentData={contentData}/>
            <QuarterStyledHalfFlexWrap>
            <StyledBodyTitle component='div' noWrap={true}>{contentData ? contentData.title : <Skeleton/>}</StyledBodyTitle>
            <Div>
            <Caption color="textSecondary"  noWrap={true} >{'80,640,700 views'}</Caption>
            <LikeWrap>
            <LikeButton
              value={true}
              likeNumber={10}
              onClick={() => {}}
              />
          </LikeWrap>
            </Div>
              <StyledBodyText component="div">
                {contentData ? nl2br(contentData.description.forFreeDescription) : ''}
              </StyledBodyText>
                <StyledBodyText component="div">
                  {contentData ? contentData.description.forSubscriptionDescription ? nl2br(contentData.description.forSubscriptionDescription) : '' : ''}
                </StyledBodyText>
                <DownloadButton
                  title={"購入者限定資料"}
                  disabled={!fileUrl ? true : false}
                  onClick={() => {}}>
                  {'資料をダウンロードする'}
                 </DownloadButton>
                <StyledBodyText component="div">
                  {contentData ? contentData.fileDescription ? nl2br(contentData.fileDescription) : '' : ''}
                </StyledBodyText>
            {contentData ? contentData.tag ? contentData.tag.map((tag) => <BasicChip label={tag}/>) : null : null}
            <ProviderItem providerId={providerId} />
          </QuarterStyledHalfFlexWrap>
        </QuarterStyledePaper>
      </PreviewDialog>
    </>
  )
}

export default PreviewModal;

const ButtonText = styled.div`
font-size: 3.2vw;
font-weight: bold;
`;

const Div = styled.div`
display: flex;
width: 100%;
position: relative;
margin:  ${props => props.theme.spacing(0.5)}px 0;
`;

const LikeWrap = styled.div`
position:absolute;
right: ${props => props.theme.spacing(2)}px;
bottom: 0;
`;

const  QuarterStyledHalfFlexWrap = styled(StyledHalfFlexWrap)`
width : 100%;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  padding: 0;
  width : 550px;
  margin:0;
  box-shadow: none;
  margin-bottom:${props => props.theme.spacing(8)}px;
}
`;

const StyledBodyText = styled(BodyText)`
width: calc(100% - ${props => props.theme.spacing(2) * 2}px);
`;

const StyledBodyTitle = styled(BodyTitle)`
width: calc(100% - ${props => props.theme.spacing(2) * 2}px);
`;