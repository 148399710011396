import React from 'react'
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { MyMenuAvatar } from '../atoms/Avatar';
import { useHistory } from 'react-router-dom';


const MyPageAvatar = (props) => {
  const { providerData, followers } = props;
  const history = useHistory();
  const shopInfo = providerData ? providerData.shopInfo : null;
  const nickName = shopInfo ? shopInfo.nickName ? shopInfo.nickName  : '' : null;
  const avatar = shopInfo ? shopInfo.avatar : null;
  const contract = useSelector(state => state.contract);
  const planList = useSelector(state => state.planList);
  const currentUsage = shopInfo ? shopInfo.currentUsage ? shopInfo.currentUsage : null : null;
  const plan = contract ? contract.plan : '';
  const nextPlan = contract ? contract.nextPlan : null;
  const usage = currentUsage ? Math.floor( currentUsage/1000000000 * Math.pow( 10, 2 ) ) / Math.pow( 10, 2 ) : 0;
  const currentPlanInfo = planList ? planList[plan] : null;
  const currentStorageCapacity = currentPlanInfo ? currentPlanInfo.capacity : '';
  const followersNumber = followers ? followers.length : null;

  return (
    <>
      <MyMenuAvatar
          avatar={avatar ? avatar : ""}
          title={nickName}
          subtitle1={plan  ? <StyledSecondaryColorText>{plan + (nextPlan ? `  (来月より${nextPlan}プラン)` : '')}</StyledSecondaryColorText> : null}
          subtitle2={(usage || currentStorageCapacity) ?<StyledSecondaryColorText>{`${usage}GB/ ${currentStorageCapacity}GB`}</StyledSecondaryColorText>:null}
          followerNumber={followersNumber ?  `${followersNumber}人` : followersNumber === 0 ? '0人' : null}
          onClickFollower={()=>history.push('/follower-list')}
        />
    </>
  )
}
export default MyPageAvatar;

const StyledSecondaryColorText = styled(Typography)`
color: ${props => props.theme.palette.secondary.main};
font-weight: 700;
font-size: 16px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  font-size: 14px;
}
`;



