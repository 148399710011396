import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Caption } from '../atoms/Text'
import PhotoIcon from '@material-ui/icons/Photo';
import IconButton from '@material-ui/core/IconButton';
import { Skeleton } from '@material-ui/lab';
import { CustomizedSmallChip } from '../atoms/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '21.3vw',
      height: '21.3vw',
    }
  },
  small: {
    width: '10.6vw',
    height:'10.6vw',
    borderRadius: '50%',
  },

}));

export const SimpleAvatar = (props) => {
  const classes = useStyles();
  return (
    <Avatar
      classes={{ root: classes.root }}
      className={props.className ? props.className : null}
      variant="square"
      src={props.avatar} />
  );
}
export const SimpleAvatarSmall = (props) => {
  const classes = useStyles();
  return (
    <Avatar
      classes={{ root: classes.small }}
      variant="square"
      src={props.avatar} />
  );
}

export const NamedAvatar = (props) => {
  const name = props.name;
  const caption = props.caption;
  const avatar = props.avatar;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <RowWrap>
      <CircledAvatar src={avatar} onClick={onClick} />
      <NameWrap>
        <Name>{name}</Name>
        <Caption>{caption}</Caption>
      </NameWrap>
    </RowWrap>);
}

export const EditableAvatar = (props) => {
  const title = props.title;
  const avatar = props.avatar;
  const required = props.required ? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      <EditableAvatarTitle data-required={required} variant="body1" gutterBottom>
        {title}
      </EditableAvatarTitle>
      {!avatar ?
        <RoundedAvatarWrap
          style={{ position: 'relative', width: 'auto', height: 'auto' }}
        >
          <RoundedAvatar
            variant="rounded"
            src={avatar}
          >
            {loading ? <CircularProgress /> : <PhotoIcon />}
          </RoundedAvatar>
          <StyledAvatarIconButton onClick={onClick} >
            <StyledAddAPhotoIcon color='primary' />
          </StyledAvatarIconButton>
        </RoundedAvatarWrap> :
        !loading ?
          <RoundedAvatarWrap
            style={{ position: 'relative', width: 'auto', height: 'auto' }}
          >
            <RoundedAvatar
              variant="rounded"
              src={avatar}
            />
            <StyledAvatarIconButton onClick={onClick}>
              <StyledAddAPhotoIcon color='primary' />
            </StyledAvatarIconButton>
          </RoundedAvatarWrap> :
          <RoundedAvatarWrap
            style={{ position: 'relative', width: 'auto', height: 'auto' }}
          >
            <RoundedAvatar variant="rounded" src={null} >
              {loading ? <CircularProgress /> : <PhotoIcon />}
            </RoundedAvatar>
          </RoundedAvatarWrap>}
    </Div>
  );
}
export const EditableThumbnail = (props) => {
  const title = props.title;
  const avatar = props.avatar;
  const required = props.required ? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : () => {};
  const helperText = props.helperText

  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      {!avatar ?
        <RoundedThumnailWrap style={{ position: 'relative', width: 'auto', height: 'auto' }}>
          <RoundedThumnail variant="rounded" src={avatar} >
            {loading ? <CircularProgress /> : <PhotoIcon />}
          </RoundedThumnail>
          <StyledIconButton onClick={onClick}><StyledAddAPhotoIcon color='primary' /></StyledIconButton>
          <HelperWrap>
            {helperText ? <StyledFormHelper>{helperText}</StyledFormHelper> : null}
          </HelperWrap>
        </RoundedThumnailWrap> :
        !loading ?
          <RoundedThumnailWrap style={{ position: 'relative', width: 'auto', height: 'auto' }}>
            <RoundedThumnail variant="rounded" src={avatar} />
            <StyledIconButton onClick={onClick}><StyledAddAPhotoIcon color='primary' /></StyledIconButton>
            <HelperWrap>
            {helperText ? <StyledFormHelper>{helperText}</StyledFormHelper> : null}
            </HelperWrap>
          </RoundedThumnailWrap> :
          <RoundedThumnailWrap style={{ position: 'relative', width: 'auto', height: 'auto' }}>
            <RoundedThumnail variant="rounded" src={null} >
              {loading ? <CircularProgress /> : <PhotoIcon />}
            </RoundedThumnail>
            <HelperWrap>
            {helperText ? <StyledFormHelper>{helperText}</StyledFormHelper> : null}
          </HelperWrap>
          </RoundedThumnailWrap>}
    </Div>
  );
}

export const MyMenuAvatar = (props) => {
  const title = props.title;
  const subtitle1 = props.subtitle1;
  const subtitle2 = props.subtitle2;
  const avatar = props.avatar;
  const required = props.required ? props.required : false;
  const loading = props.loading ? props.loading : false;
  const followerNumber = props.followerNumber;
  const onClick = props.onClick ? props.onClick : () => {};
  const onClickFollower = props.onClickFollower ? props.onClickFollower : ()=>{};
  return (
    <MyMenuDiv>
      <StyledRoundedAvatar variant="rounded" src={avatar} onClick={onClick}>
        {loading ? <CircularProgress /> : <PhotoIcon />}
      </StyledRoundedAvatar>
      <div style={{flex:1}}>
        <Maintitle data-required={required} variant="body1" gutterBottom component='div'>{title !== null ? title : <Skeleton style={{ flex: 1 }} />}</Maintitle>
        <Subtitle data-required={required} variant="body2" gutterBottom component='div'>{'契約プラン：'}{subtitle1 ? subtitle1 : <Skeleton style={{ flex: 1 }} />}</Subtitle>
        <Subtitle data-required={required} variant="body2" gutterBottom component='div'>{'格納容量：'}{subtitle2 ? subtitle2 : <Skeleton style={{ flex: 1 }} />}</Subtitle>
        <FollowerTitle data-required={required} variant="body2" gutterBottom component='div' onClick={onClickFollower}>{}{followerNumber ?  <CustomizedSmallChip label={`フォロワー：${followerNumber}`}/> : <Skeleton style={{ flex: 1 }} />}</FollowerTitle>
      </div>
    </MyMenuDiv>
  );
}
export const ConsumerAvatar= (props) => {
  const title = props.title;
  const subTitle1 = props.subTitle1;
  const subTitle2 = props.subTitle2;
  const avatar = props.avatar;
  const required = props.required? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : ()=>{};
  return(
      <MyMenuDiv>
          <ProviderStyledRoundedAvatar variant="rounded" src={avatar} onClick={onClick}>
              {loading ? <CircularProgress/> :<PhotoIcon/>}
          </ProviderStyledRoundedAvatar>
          <div style={{flex:'1', overflow:'hidden', marginRight:16}}>
          <CustomizeDefaultProviderMainTitle data-required={required} variant="body1" gutterBottom component='div' noWrap={true}>{title !== null ? title : <Skeleton style={{ flex: 1 }} />}</CustomizeDefaultProviderMainTitle>
          <ProviderSubTitle data-required={required} variant="body2" gutterBottom component='div' noWrap={true}>{subTitle1 !== null ? subTitle1 : <Skeleton style={{ flex: 1 }} />}</ProviderSubTitle>
          <ProviderSubTitle data-required={required} variant="body2" gutterBottom component='div' noWrap={true}>{subTitle2 !== null ? subTitle2 : <Skeleton style={{ flex: 1 }} />}</ProviderSubTitle>
          </div>
      </MyMenuDiv>
  );
}


export const ProviderAvatar= (props) => {
  const title = props.title;
  const subTitle1 = props.subTitle1;
  const subTitle2 = props.subTitle2;
  const avatar = props.avatar;
  const required = props.required? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : ()=>{};
  return(
      <MyMenuDiv>
          <ProviderStyledRoundedAvatar variant="rounded" src={avatar} onClick={onClick}>
              {loading ? <CircularProgress/> :<AddAPhotoIcon/>}
          </ProviderStyledRoundedAvatar>
          <div>
          <DefaultProviderMainTitle data-required={required} variant="body1" gutterBottom>{title}</DefaultProviderMainTitle>
          <ProviderSubTitle data-required={required} variant="body2" gutterBottom component='div'>{subTitle1}</ProviderSubTitle>
          <ProviderSubTitle data-required={required} variant="body2" gutterBottom component='div'>{subTitle2}</ProviderSubTitle>
          </div>
      </MyMenuDiv>
  );
}
export const SmallProviderAvatar= (props) => {
  const title = props.title;
  const subTitle1 = props.subTitle1;
  const subTitle2 = props.subTitle2;
  const avatar = props.avatar;
  const required = props.required? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : ()=>{};
  return(
      <MyMenuDiv>
          <SmallProviderStyledRoundedAvatar variant="rounded" src={avatar} onClick={onClick}>
              {loading ? <CircularProgress/> :<AddAPhotoIcon/>}
          </SmallProviderStyledRoundedAvatar>
          <div>
          <ProviderMainTitle data-required={required} variant="body1" gutterBottom>{title}</ProviderMainTitle>
          <ProviderSubTitle data-required={required} variant="body2" gutterBottom component='div'>{subTitle1}</ProviderSubTitle>
          <ProviderSubTitle data-required={required} variant="body2" gutterBottom component='div'>{subTitle2}</ProviderSubTitle>
          </div>
      </MyMenuDiv>
  );
}



export const CircledAvatar = styled(Avatar)`
    margin:${props => props.theme.spacing(1)}px;
`;

export const SmallAvatar = styled(Avatar)`
    width: ${props => props.theme.spacing(3)}px;
    height: ${props => props.theme.spacing(3)}px;
`;

const RowWrap = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    align-items: center;
`;

const NameWrap = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:column;
`;

const Name = styled(Typography).attrs({
  variant: 'body1',
})`
    margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
`;

const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:column;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        flex-direction:row;
    }
`;

const Title = styled(Typography)`
    margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    font-size:14px;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left:${props => props.theme.spacing(2)}px;
          font-size:14px;
          color:red;
        }
    }
`;

const EditableAvatarTitle = styled(Typography)`
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
    margin: ${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
    font-weight: 700;
    width: calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
      &::after{
      content:'*';
      margin-left: ${props => props.theme.spacing(1)}px;
      font-size: 14px;
      color: red;
      }
    }
    padding: 50px 0;
    font-size: 14px;
    color: #000;
    text-align: left;
  }
  margin: ${props => props.theme.spacing(0)}px auto;
  width: 143px;
  height: 19px;
  //font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 0;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  color: #2E7DF6;
  &[data-required="true"] {
    &::after{
    content:'*';
    margin-left: ${props => props.theme.spacing(1)}px;
    font-size: 14px;
    color: red;
    }
  }
`;

const MyMenuDiv = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    width: 100%;
`;

const Maintitle = styled(Typography)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px 0;
font-weight:700;
display: flex;
font-size:17px;
min-height: 27px;
margin-left: 0;
&[data-required="true"] {
    &::after{
      content:'*';
      margin-left:${props => props.theme.spacing(2)}px;
      font-size:15px;
      color:red;
    }
}
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 375px;
  font-size:18px;
  min-height: 25px;
  margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px 0;
}
`;

const Subtitle = styled(Typography)`
    display: flex;
    font-size:14px;
    margin:0 ${props => props.theme.spacing(1)}px 0;
    font-weight:500;
    margin-left: 0;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(2)}px 0;
      width: 375px;
      font-size:16px;
  }
`;

const FollowerTitle = styled(Subtitle)`
display: flex;
font-size:12px;
cursor: pointer;
margin-top:${props => props.theme.spacing(1)}px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  font-size:14px;
  margin-top:${props => props.theme.spacing(1)}px;
}
`;


const RoundedAvatar = styled(Avatar)`
margin:${props => props.theme.spacing(2)}px auto;
height: 120px;
width: 120px;
border-radius: 16px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    margin: ${props => props.theme.spacing(2)}px 0;
    height: 120px;
    width: 120px;
    border-radius: 16px;
}
`;

const StyledRoundedAvatar = styled(RoundedAvatar)`
margin:${props => props.theme.spacing(2)}px;
background: #E1E2E5;
height: 80px;
width: 80px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin: ${props => props.theme.spacing(2)}px;
  height: 120px;
  width: 120px;
}
`;

const RoundedAvatarWrap = styled.div`
position:relative;
margin:${props => props.theme.spacing(2)}px;
height: 120px;
width: 120px;
`;

const RoundedThumnailWrap = styled.div`
position:relative;
margin:${props => props.theme.spacing(2)}px;
height: 126px;
width: 160px;
`;

const RoundedThumnail = styled(Avatar)`
height: 126px;
width: 160px;
border-radius: 16px;
background: ${props => props.theme.palette.gray.gray01};
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
}
`;

const StyledAvatarIconButton = styled(IconButton)`
background: #ffffff;
position: absolute;
width: 30px;
height: 30px;
bottom: 20px;
left: 50vw;
box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
&:action{
  background: rgba(255,255,255, 0.8);
}
&:hover{
  background: rgba(255,255,255, 0.8);
}
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  background: #ffffff;
  position: absolute;
  width: 38px;
  height: 38px;
  bottom: 20px;
  left: 77px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
  }
`;

const StyledIconButton = styled(IconButton)`
  background: #ffffff;
  position: absolute;
  width: 38px;
  height: 38px;
  bottom: 32px;
  left: 112px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
  &:action{
    background: rgba(255,255,255, 0.8);
  }
  &:hover{
    background: rgba(255,255,255, 0.8);
  }
`;

const StyledAddAPhotoIcon = styled(AddAPhotoIcon)`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
width: 23.11px;
height: 23.11px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 21.11px;
  height: 21.11px;
}
`;




const ProviderMainTitle = styled(Maintitle)`
margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(0.5)}px 0;
font-size: 13px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(0.5)}px 0;
}
`;
const DefaultProviderMainTitle = styled(ProviderMainTitle)`
`;

const ProviderSubTitle= styled(Subtitle)`
margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(0.5)}px;
font-size: 12px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:${props => props.theme.spacing(0.5)}px ${props => props.theme.spacing(0.5)}px 0;
}

`;
const ProviderStyledRoundedAvatar = styled(RoundedAvatar)`
margin:${props => props.theme.spacing(2)}px;
background: #E1E2E5;
height: 72px;
width: 72px;
border-radius:50%;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin: ${props => props.theme.spacing(2)}px;
  border-radius:50%;
  height: 72px;
  width: 72px;
}
`;

const SmallProviderStyledRoundedAvatar = styled(RoundedAvatar)`
margin:${props => props.theme.spacing(2)}px;
background: #E1E2E5;
height: 60px;
width: 60px;
border-radius:50%;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  margin: ${props => props.theme.spacing(2)}px;
  height: 60px;
  width: 60px;
  border-radius:50%;
}
`;

const CustomizeDefaultProviderMainTitle = styled(DefaultProviderMainTitle)`
  margin-top:${props => props.theme.spacing(2)}px;
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
      min-height:16px;
}

`;

const HelperWrap = styled.div`
display: flex;
flex-wrap: wrap;
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
}
`;
const StyledFormHelper = styled(FormHelperText)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  margin-top: ${(props) => props.theme.spacing(0.5)}px;
`;