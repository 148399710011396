import React from 'react';
import Page from '../templates/Page';
import { PageTitle } from '../atoms/Title';
import Config from '../organisms/Config';
import { StyledFullPaper } from '../atoms/Paper';
import  EditCoupon from '../organisms/EditCoupon';

const EditCouponPage = () => {
  return (
    <>
      <PageTitle>クーポンの編集</PageTitle>
      <StyledFullPaper>
        <EditCoupon/>
      </StyledFullPaper>
    </>
  )
}
export default EditCouponPage;