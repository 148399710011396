import { firestore } from './firestore';

const consumer = 'consumers';
const hidden = 'hidden';
const stripeInfo = 'stripeInfo';
const subscribe = 'subscribe';

const ref = {
  collection: () => firestore.collection(consumer),
  doc: (guestId) => ref.collection().doc(guestId),
  hiddenRef: (guestId) => ref.collection().doc(guestId).collection(hidden).doc(stripeInfo),
  subscribeRef: (userId) => ref.doc(userId).collection(subscribe),
  subscribeDoc:(userId, providerId) => ref.doc(userId).collection(subscribe).doc(providerId),
}

export const firestoreConsumers = {
  getCollectionRef: () => ref.collection(),
  getNewDocRef: () => ref.newDoc(),
  getRef: (guestId) => ref.doc(guestId),
  getAllData: async () => {
   const userDatas = await firestore.getAllDatas(ref.collection()).catch((err) => {
     console.log('FirestoreOrders getAllData err');
     throw err
   })
   return userDatas;
  },
  getData: async (orderId) => {
   const data = await firestore.get(ref.doc(orderId)).catch((err) => {
     console.log('FirestoreOrders getRef err');
     throw err
   })
    return data;
  },
  getSubscribeData : async (userId,  providerId) => {
    const data = await firestore.get(ref.subscribeDoc(userId, providerId)).catch((err) => {
      console.log('firestoreProviders  getRef err');
      throw err;
    });
    return data;
  },
  getRefStripeInfo:(guestId) => ref.hiddenRef(guestId),
}
