import React, { useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomizedSnackbar from '../atoms/CustomizedSnackbar';
import { SimpleAddContentCard } from './ContentCards';

const AddContentCard = (props) => {
  const providerData = useSelector(state => state.providerData);
  const shopInfo = providerData ? providerData.shopInfo : null;
  const providerStatus = shopInfo ? shopInfo.status : null;
  const profile = providerData ? providerData.profile : null
  const { type, tab } = props;
  const history = useHistory();
  const [snackState, setSnackState] = useState({
    open: false,
    message: '',
    success:false
  })
  const moveToDetail = () =>{
    if(!profile){
      setSnackState({
        open:true,
        message: 'コンテンツ投稿前に設定が必要です。設定ページに遷移します',
        success: false,
      });
      setTimeout(() => {
        history.push('/config');
      }, 2000);
      return;
    }
    if((tab === '会員限定' || tab === '期間限定') && providerStatus !== 'active'){
      setSnackState({
        open:true,
        message: '有料での販売には、口座登録・本人確認の実施が必要になります。',
        success: false,
      });
      return;
    }
    history.push({
      pathname: type === "video" ? '/edit-content' : '/edit-singleItem',
      state: {tab: tab}
  });
  }
  return(
    <>
    <SimpleAddContentCard
       onClick={()=>moveToDetail()}
       type={type}
    />
    <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState}/>
    </>
  )
}
export default AddContentCard;
