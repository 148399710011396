import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { theme } from '../../config';
import { CSelectBox } from '../molecules/CSelectBox';
import { checkYear, getMonth } from '../../utils/momentUtils';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer
} from 'recharts';
import Skeleton from '@material-ui/lab/Skeleton';
import { CONTENTS_CATEGORIES } from '../../static/constants';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  title: {
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4vw',
    }
  },
  gridRoot: {
    width: '100%',
    height: '400px',
    marginTop: '0vw',
  }
}));

const Chart = (props) => {
  const classes = useStyles();
  const { selectedYear, orderList, loading } = props;
  const [localLoading, setLocalLoading] = useState(loading);
  const [amount, setAmount] = useState([
    {
      name: '1月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '2月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '3月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '4月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '5月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '6月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '7月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '8月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '9月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '10月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '11月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '12月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
  ])
  const data = [
    {
      name: '1月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '2月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '3月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '4月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '5月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '6月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '7月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '8月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '9月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '10月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '11月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
    {
      name: '12月', イベント: 0, 個別販売: 0, 月会費: 0, 合計: 0,
    },
  ];
  useEffect(() => {
    if (orderList.length > 0) {
      for(let i = 0; i < orderList.length; i++) {
        if (checkYear(orderList[i].data.createAt.seconds, selectedYear)) {
          const month = getMonth(orderList[i].data.createAt.seconds);
          if (orderList[i].data.orderType === 'subscription') {
            data[month]['月会費'] = data[month]['月会費'] + Math.floor(Number(orderList[i].data.amount));
            data[month]['合計'] = data[month]['合計'] + Math.floor(Number(orderList[i].data.amount));
          } else if (orderList[i].data.orderType === 'normal') {
            data[month]['イベント'] = data[month]['イベント'] + Math.floor(Number(orderList[i].data.amount));
            data[month]['合計'] = data[month]['合計'] + Math.floor(Number(orderList[i].data.amount));
          } else if (orderList[i].data.orderType === 'backNumber') {
            data[month]['個別販売'] = data[month]['個別販売'] + Math.floor(Number(orderList[i].data.amount));
            data[month]['合計'] = data[month]['合計'] + Math.floor(Number(orderList[i].data.amount));
          }
        }
      }
      setAmount(data);
    }
    setLocalLoading(false)
  }, [selectedYear, orderList]);
  return (
    <>
      {loading ?
        <CustomSkeleton variant="rect" /> :
        orderList.length === 0 ?
          <Style>
            売上がありません
        </Style> :
          <Wrap>
            <Grid container classes={{ root: classes.gridRoot }}>
              {/*<Typography style={{ marginBottom: '1.7vw' }}>売上遷移(年別）</Typography>*/}
              {/*<CSelectBox array={yearArray} value={selectedYear} setFunction={setSelectedYear} />*/}
              <ResponsiveContainer>
                <BarChart
                  height={400}
                  data={amount}
                  label={{ position: 'top' }}
                  margin={{
                    top: 20, right: 10, left: 10, bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="bottom" height={1} iconType={'circle'} />
                  <Bar dataKey="月会費" fill="#5891D5" className={classes.title} />
                  <Bar dataKey="イベント" fill="#F6C06F" className={classes.title} />
                  <Bar dataKey="個別販売" fill="#ED6D7B" className={classes.title} />
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Wrap>}
    </>
  )
}
export default Chart;

const Wrap = styled.div`
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
    width: 100%;
    height: 400px;
    margin-top: 0px;
    margin-bottom: 20px;
    position: relative;
    text-align: left;
  }
  width: 100%;
  height: 400px;
  margin-top: 37px;
  margin-bottom: 10vw;
  position: relative;
  text-align: left;
`;

const CustomSkeleton = styled(Skeleton)`
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
  width: 100%;
  height: 400px;
  margin-top: 0px;
  margin-bottom: 20px;
  position: relative;
  text-align: left;
}
width: 100%;
height: 400px;
margin-top: 37px;
margin-bottom: 10vw;
position: relative;
text-align: left;
`;

const Style = styled.div`
margin-top: 5vw;
margin-bottom: 5vw;
display: flex;
flex-direction: column;
align-items: center;
min-height: 0;
`;