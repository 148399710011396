import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import Avatar from '@material-ui/core/Avatar';
import { Eliipsis } from '../../icons/icon';
import { nl2br } from '../../utils/nl2br';
import PhotoIcon from '@material-ui/icons/Photo';
import { CommentStatusChip } from './Chip';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
  smallColor: {
    fontSize: '1.4rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.6vw',
      lineHeight: '3.2vw',
    }
  },
  small: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.6vw',
      lineHeight: '3.2vw',
    }
  },
  default: {
    fontSize: '2.0rem',
    lineHeight: '4.2vw',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.7vw',

    }
  }
}));

export const DefaultText = (props) => {
  const classes = useStyles();
  return (
    <Typography gutterBottom classes={{ root: classes.default }}>
      {props.children}
    </Typography>
  );
}

export const ColorText = (props) => {
  const classes = useStyles();
  return (
    <Typography color="textSecondary" gutterBottom classes={{ root: classes.default }}>
      {props.children}
    </Typography>
  );
}

export const SmallText = (props) => {
  const classes = useStyles();
  return (
    <Typography color="textPrimary" gutterBottom classes={{ root: classes.small }}>
      {props.children}
    </Typography>
  );
}

export const SmallColorText = (props) => {
  const classes = useStyles();
  return (
    <Typography color="textSecondary" gutterBottom classes={{ root: classes.smallColor }}>
      {props.children}
    </Typography>
  );
}

/**
 * ここらしたがwebkit
 *
 */

export const Description = (props) => {
  const title = props.title;
  const text = props.text;
  return (
    <Div>
      <Title variant="body1" gutterBottom>{title}</Title>
      <BoldText>{text}</BoldText>
    </Div>
  );
}

export const Comment = (props) => {
  const avatar = props?.avatar;
  const nickName = props?.nickName;
  const message = props?.message;
  const createAt = props?.createAt;
  const activeEclipse = props.activeEclipse;
  const isOpenReplyForm = props.isOpenReplyForm ? props.isOpenReplyForm : false;
  const setIsOpenReplyForm = props.setIsOpenReplyForm ? props.setIsOpenReplyForm : () => { };
  const onClick = props.onClick ? props.onClick : () => { };
  const isOpenReply = props.isOpenReply ? props.isOpenReply : false;
  const setIsOpenReply = props.setIsOpenReply ? props.setIsOpenReply : () => { };
  const numberOfReply = props.numberOfReply ? props.numberOfReply : 0;
  const mediaSrc = props?.mediaSrc;
  const title = props?.title
  const target = props?.target;
  const published = props?.published;
  const openStatusPopover = props?.openStatusPopover ? props?.openStatusPopover : () => { };
  const disabled = props?.disabled;

  return (
    <>
      <StyledDiv>
        <div>
          <CommentAvatar src={avatar} />
        </div>
        <ComentWrap>
          <UserName>{nickName}</UserName>
          <CommentArea>{nl2br(message)}</CommentArea>
          <ThumbnailWrap>
            <StyledListCardMedia src={mediaSrc}>
              <PhotoIcon />
            </StyledListCardMedia>
            <ContentTitle>{title}</ContentTitle>
          </ThumbnailWrap>
          <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }} >
            <GrayText>{createAt}</GrayText>
            <GrayText onClick={setIsOpenReplyForm} style={{ cursor: 'pointer' }}>返信する</GrayText>
            <CommentStatusChip
              label={disabled ? <CircularProgress size={8} color={'white'} /> : !published ? '非公開' : target === 'all' ? '全体公開' : '購入者限定'}
              style={{
                background: (published && target === 'all') ? '#27AE60' : (published && target === 'purchaser') ? '#FCB853' : null,
                color: (published && (target === 'all' || target === 'purchaser')) && 'white'
              }}
              onClick={(e) => openStatusPopover(e)}
            />
            {activeEclipse &&
              <StyledEliipsis onClick={onClick}>
                <Eliipsis />
              </StyledEliipsis>}
          </div>
          {(numberOfReply !== 0 && numberOfReply !== '') &&
            <GrayText onClick={setIsOpenReply} style={{ cursor: 'pointer' }}>
              {isOpenReply ? `${numberOfReply}件の返信を非表示` : `${numberOfReply}件の返信を表示`}
            </GrayText>}
          <div>
            {props.children}
          </div>
        </ComentWrap>
      </StyledDiv>
      <Line />
    </>
  )
}

export const Reply = (props) => {
  const avatar = props.avatar;
  const nickName = props?.nickName;
  const message = props?.message;
  const updateAt = props?.updateAt;
  const activeEclipse = props.activeEclipse;
  const uid = props?.uid;
  const inEdit = props?.inEdit;
  const replyId = props?.replyId;
  const sendFrom = props?.sendFrom;
  const published = props?.published;
  const editReplyDisabled = props?.editReplyDisabled;
  const isDeleteReply = props?.isDeleteReply;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <>
      <StyledDiv>
        <div>
          <CircledAvatar src={avatar} />
        </div>
        {(editReplyDisabled && inEdit.replyId === replyId) ?
          <LoaderWrap>
            {isDeleteReply ? '削除中' : '編集中'}
            <CircularProgress style={{ marginLeft: 8 }} color={'default'} size={15} />
          </LoaderWrap> :
          <ReplyWrap>
            <ReplyUserName>{nickName}</ReplyUserName>
            <CommentArea>{nl2br(message)}
            </CommentArea>
            <div style={{ display: 'flex' }}><GrayText>{updateAt}</GrayText>
              {!published && sendFrom.parent.path === 'consumers' ?
                <CommentStatusChip
                  label={'非公開'}
                  style={{
                    background: null,
                    color: 'black'
                  }}
                /> : null
              }
              {activeEclipse &&
                <StyledReplyElipsis onClick={onClick}>
                  <Eliipsis />
                </StyledReplyElipsis>}
            </div>
          </ReplyWrap>}
      </StyledDiv>
    </>
  )
}

export const BodyTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px 0;
  font-weight:700;
  width: 100%;
  word-wrap: break-word;
`

export const BodyText = styled(Typography).attrs({
  variant: 'body1',
})`
  margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
  font-weight:400;
  width: 100%;
  word-wrap: break-word;
`

export const Caption = styled(Typography).attrs({
  variant: 'body2',
})`
  margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
  font-weight:300;
  word-wrap: break-word;
`;

export const TermText = styled(Typography)`
margin-top: ${props => props.theme.spacing(5)}px;
font-size:12px;
color:BABAB9;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin-top: ${props => props.theme.spacing(3)}px;
}
`;

const BoldText = styled(Typography)`
font-weight: bold;
font-size: 10px;
margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(0)}px 0;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  font-size: 12px;

}
`;

const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    }
`;


const Title = styled(Typography)`
margin:${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1.5)}px 0 ${props => props.theme.spacing(3)}px;
width:100px;
//font-family: Noto Sans JP;
font-style: normal;s
line-height: 20px;
color: #2B2B2B;
  //font-family: Noto Sans JP;
  font-style: normal;
  font-size: 10px;
  line-height: 20px;
  color: #2B2B2B;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    font-size: 12px
  }
}
` ;


const StyledDiv = styled(Div)`
align-items: flex-start;
width: 100%;

`;

export const CircledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin:${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px  ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(1)}px;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    width: 32px;
    height: 32px
  }
  `;

export const CommentAvatar = styled(CircledAvatar)`
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 40px;
  height: 40px
}
`;

const Line = styled.div`
width: 100vw;
height: 1px;
background: #DDDDDD;
margin-left: -8px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  marign:0;
  width: calc(100% + 16px);
}
`;

const ComentWrap = styled.div`
 margin-top:${props => props.theme.spacing(2)}px;
 margin-bottom:${props => props.theme.spacing(2)}px;
 position:relative;
 flex: 1;
 @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
}
`;

const ThumbnailWrap = styled.div`
position: absolute;
right: 0;
top: 0;
margin: 0px  ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
display: flex;
max-width: 400px;
width: 35%;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  display:none
}
`;

const ReplyWrap = styled(ComentWrap)`
position: relative;
`;

const StyledEliipsis = styled.div`
cursor: pointer;
margin-top: 2px;
`;

const StyledReplyElipsis = styled(StyledEliipsis)`
bottom: ${props => props.theme.spacing(0.5)}px;
`;


const UserName = styled(Typography)`
  font-size: 12px;
  height: 17px;
  color: #8F8F8F;
  margin-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  white-space: nowrap;
`;

const ReplyUserName = styled(UserName)`
width: 200px;
`;

const CommentArea = styled(Typography)`
font-size: 13px;
margin-top: ${props => props.theme.spacing(0.5)}px;
margin-right: ${props => props.theme.spacing(2)}px;
line-height: 152.9%;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  width: 60%
}
`;


const GrayText = styled(Typography)`
font-size: 12px;
color: #8F8F8F;
margin-top: 2px;
margin-right: ${props => props.theme.spacing(2)}px;
`;

const StyledListCardMedia = styled(Avatar)`
width: 98px;
height: 58px;
background: #F2F4F8;
border-radius: 4px;
`;

const ContentTitle = styled(Typography)`
font-size: 13px;
width: 150px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
margin-left: ${props => props.theme.spacing(2)}px;
`;

const LoaderWrap = styled.div`
margin:18px ${props => props.theme.spacing(2)}px;
padding: 6px ${props => props.theme.spacing(2)}px;
background: ${props => props.theme.palette.gray.gray03};
border-radius:32px;
color: white;
font-weight: bold;
font-size: 12px;
display:flex;
align-items:center
`;